import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// icon
import closeIcon from '@assets/images/common/close_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
// lib
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setCartAmount, setCartCount, setCartItems } from '@stores/cart';
import CartCardSquare from '@components/CartCardSquare';
import { koKRFormat } from '@utils/lib';
import { message } from 'antd';
import * as api from '@api/index';
import * as lib from '@utils/lib';
import * as constants from '@utils/constants';
import * as PATH from '@routes/pathName';
import ReceiptModal from '@components/payment/ReceiptModal';

const innopayMid = process.env.REACT_APP_INNOPAY_MID;
const innopayLicenseKey = process.env.REACT_APP_INNOPAY_LICENSE_KEY;

const CartSimpleList = ({ isMobile = false, cartToggle, paymentRef }) => {
  const auth = useSelector((s) => s.auth, shallowEqual);
  const cartState = useSelector((s) => s.cart, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const memKey = lib.getLocalStorage('prptbk-token', 'mem_key') || '';
  const memTel = lib.getLocalStorage('prptbk-token', 'mem_tel') || '';
  const memNick = lib.getLocalStorage('prptbk-token', 'mem_nick') || '';
  const memEmail = lib.getLocalStorage('prptbk-token', 'mem_email') || '';

  const payInfo = useRef({});

    const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
    const [receiptAmount, setReceiptAmount] = useState(null);
    const [paymentMembership, setPaymentMembership] = useState(null);

  const [selectCart, setSelectCart] = useState({
    items: [],
    count: 0,
    amount: 0,
  }); // 선택된 cart

  // 장바구니 조회
  const getCartList = async () => {
    const params = { mem_key: auth?.mem_key };
    const { data } = await api.getCartList(params);
    if (data) {
      const totAmount = data.reduce((acc, item) => {
        return acc + item.gds_amt;
      }, 0);

      dispatch(setCartItems(data));
      dispatch(setCartCount(data.length));
      dispatch(setCartAmount(totAmount));

      setSelectCart({
        items: data,
        count: data.length,
        amount: totAmount,
      });
    }
  };

  // 장바구니 삭제
  const deleteCart = async (e, seq) => {
    e.stopPropagation(); // 상위 이벤트 전파 방지

    try {
      const params = { mem_key: auth?.mem_key, gds_seq: seq };
      const { data } = await api.deleteCart(params);

      if (data.returnStatus == 'success') message.success(data.returnMessage);
      else message.error(data.returnMessage);
    } catch (e) {
      console.log(e);
      message.error('삭제에 실패했습니다.');
    } finally {
      getCartList(); //장바구니 재조회
    }
  };

  // 장바구니 선택
  const handleSelect = (cart) => {
    setSelectCart((prev) => {
      const isSelect = prev.items.some((item) => item.gds_seq === cart.gds_seq);
      const updatedItems = isSelect ? prev.items.filter((item) => item.gds_seq !== cart.gds_seq) : [...prev.items, cart];
      const updatedAmt = updatedItems.reduce((acc, item) => {
        return acc + item.gds_amt;
      }, 0);

      return {
        items: updatedItems,
        count: updatedItems.length,
        amount: updatedAmt,
      };
    });
  };

  const closeReceiptModal = () => {
    setIsReceiptModalVisible(false);
  }

  const openReceiptModal = () => {
    if(selectCart.amount === 0){
      const updatedGdsNm = selectCart.count > 1 ? selectCart.items[0].gds_nm + ' 외 ' + (selectCart.count - 1) + '건' : selectCart.items[0].gds_nm;

      payInfo.current = {
        mem_key: memKey,
        gds_nm: updatedGdsNm,
        inout_tp: 'OUT',
        inout_div: '01',
        pay_amt: selectCart.amount,
        gdsList: selectCart.items,
        pay_no: lib.makePaymentUUID(memKey),
      };
      
      sendPaymentCompleteAlert(payInfo.current);
    }else{
      setReceiptAmount(selectCart.amount)
      setIsReceiptModalVisible(true);
    }
  }

  const sendPaymentCompleteAlert = (payInfo) => {
    message.success('결제가 완료되었습니다.');
    api
      .insertPaymentMstForPaySeq(payInfo)
      .then((response) => {
        if (response.status === 200) {
          api.insertSaleInfo(response.data);
          api
            .deleteSelectedCartItems(payInfo.gdsList)
            .then((response) => {
              if (response.status === 200) {
                cartToggle();
                api.sendPaymentCompleteAlert({ mem_key: memKey, pay_no: payInfo.pay_no, pay_type: 'CART' });
              }
            })
            .catch((error) => {
              console.log('카트 아이템 삭제하는 도중 에러가 발생했습니다.');
              return;
            });
        } else {
          message.error('결제정보 업데이트 도중 에러가 발생했습니다.');
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**이노페이 결제 */
  const handleOpenPayment = async (paymentType) => {
    if (await !auth?.mem_key) {
      message.error('로그인 후 이용해주세요.');
      return;
    }
    if ((await selectCart.count) < 1) {
      message.error('결제할 항목이 없습니다.');
      return;
    }
    if ((await selectCart.amount) < 100) {
      message.error('구매 금액은 100원 이상이어야 합니다.');
      return;
    }
    const updatedGdsNm = selectCart.count > 1 ? selectCart.items[0].gds_nm + ' 외 ' + (selectCart.count - 1) + '건' : selectCart.items[0].gds_nm;

    payInfo.current = {
      mem_key: memKey,
      gds_nm: updatedGdsNm,
      inout_tp: 'OUT',
      inout_div: '01',
      pay_amt: selectCart.amount,
      pay_meth: paymentType,
      gdsList: selectCart.items,
      pay_no: lib.makePaymentUUID(memKey),
    };

    if (memTel) {

      window.innopay_result = (data) => {
        if (data.ResultCode === '3001') {
          sendPaymentCompleteAlert(payInfo.current);
        } else {
          const resultMessage = constants.innopayResultCode.find((item) => item.key === data.ResultCode);
          message.error('에러가 발생했습니다.\n에러사유 : ' + resultMessage);
          return;
        }
      };

      if (window.innopay && window.innopay.goPay) {
        window.innopay.goPay({
          PayMethod: paymentType, // 결제수단
          MID: innopayMid, // 가맹점 MID
          MerchantKey: innopayLicenseKey,
          Amt: payInfo.current.pay_amt + '', // 결제금액
          GoodsName: payInfo.current.gds_nm, // 상품명
          BuyerName: memNick, // 고객닉네임
          BuyerTel: memTel, // 고객 전화번호
          BuyerEmail: memEmail, // 고객 이메일
          ResultYN: 'N', // 결제 결과창 출력 여부
          Moid: '' + payInfo.current.pay_no, // 주문번호
        });
      } else {
        api.deleteMemberPayment(payInfo.current);
        console.error('이노페이 SDK가 로드되지 않았습니다.');
      }
    } else {
      if (await window.confirm(`내 프로필에서 전화번호 등록 후 결제가 가능합니다.\n전화번호를 등록하시겠습니까?`)) {
        moveToPage(PATH.PROFILE_PAGE);
      }
    }
  };

  useEffect(() => {
    if (auth?.mem_key) {
      getCartList();
    }

    // 이노페이 SDK 스크립트 로드
    const script = document.createElement('script');
    script.src = 'https://pg.innopay.co.kr/ipay/js/innopay-2.0.js';
    script.charset = 'utf-8';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // 컴포넌트 언마운트 시 스크립트 제거
      document.body.removeChild(script);
    };
  }, []);

  const moveToPage = (path) => {
    navigate(`${path}`, { state: { mem_email: auth.mem_email, mem_key: auth.mem_key } });
  };

  return (
    <>
      <div className={`cartWrapper ${isMobile ? 'flexColCenter' : ''}`}>
        <div className="flexRowBetween">
          <div className="titleBox flexRowCenter">
            <img src={cartIcon} alt="cart_icon" />
            <h1>장바구니</h1>
            <div>{cartState.count}</div>
          </div>

          <img src={closeIcon} alt="close_icon" onClick={cartToggle} />
        </div>

        <div className="cartBox flexRowBetween">
          {cartState.items.map((cart) => {
            const isSelect = selectCart.items.some((item) => item.gds_seq === cart.gds_seq);
            return <CartCardSquare key={cart.gds_seq} cart={cart} selected={isSelect} deleteCart={deleteCart} onClick={handleSelect} />;
          })}
        </div>

        <div className="priceBox flexColCenter">
          <div className="price flexRowBetween">
            <h1>선택 {koKRFormat(selectCart.count)}개</h1>

            <p>주문 합계 {koKRFormat(selectCart.amount)}원</p>
          </div>
          <button id="pinkBtn" className={(selectCart.count > 0)?"" : "btnDisable"} disabled={(selectCart.count > 0)? false : true} onClick={() => openReceiptModal()}><span>구매하기</span></button>
        </div>
      </div>
      <ReceiptModal
        visible={isReceiptModalVisible}
        amount={receiptAmount}
        onClose={closeReceiptModal}
        handleOpenPayment={handleOpenPayment}
        isCoupon={false}
        paymentRef={paymentRef}
        paymentAmount={receiptAmount}
      />
    </>
  );
};

export default CartSimpleList;
