import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Input, Layout, List, Row, Tabs } from 'antd';
import { Header } from 'antd/es/layout/layout';
import * as api from '@api/index';
import { shallowEqual, useSelector } from 'react-redux';
import { marketplaceSrot } from '@utils/constants';
import { formatNumber, getDefaultImg, koKRFormat, useDebounce } from '@utils/lib';
import CardItem from '@components/cardMarketPlace/CardItem';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '@components/cardMarketPlace/Filter';
import { CloseOutlined } from '@ant-design/icons';
import searchIcon from '@assets/images/common/search_icon.png';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';
import * as PATH from '@routes/pathName';
import heartIcon from '@assets/images/common/heart_icon.png';
import userThum from '@assets/images/common/user_thumb.png';
import { PROMPT_DETAIL, SALES_PROMPT_HOME } from '@routes/pathName';

const MarketPromptList = () => {
  const { pathname } = useLocation();
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const [loading, setLoading] = useState({});
  const navigate = useNavigate();

  const [activeTabKey, setActiveTabKey] = useState(''); // 활성화된 tab
  const [promptList, setPromptList] = useState({
    query: {
      mem_key: auth?.mem_key,
      keyword_tab: '', // 조회 tab (판매/구매/좋아요 목록)
      filter_text: '', // 프롬프트 제목/tag 검색
      filter_cate: '', // 프롬프트 카테고리
      filter_model: '', // 프롬프트 모델
      filter_stat: '', // 프롬프트 상태
      filter_genre: '', // 프롬프트 장르
      filter_sort: '', // 정렬
      offset: 0,
      limit: 20,
    },
    items: [],
  });

  // 공통코드 (filter 용)
  const [commonCodeList, setCommonCodeList] = useState([]);

  /** 이벤트 핸들러 */
  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 200),
    [],
  );

  // filter 변경 시
  const handleChangeFilter = (selectedItem, filterText) => {
    const newQuery = {
      ...promptList.query,
      filter_text: filterText,
      filter_cate: selectedItem.CATE == 'all' ? '' : selectedItem.CATE || '',       /* 필터 존재 하지 않는 경우 'undifined' 방지 ''로 리턴 */
      filter_model: selectedItem.MODEL == 'all' ? '' : selectedItem.MODEL || '',
      filter_stat: selectedItem.PRPT_STAT == 'all' ? '' : selectedItem.PRPT_STAT || '',
      filter_genre: selectedItem.GENRE == 'all' ? '' : selectedItem.GENRE || '',
      filter_sort: selectedItem.sort,
    };

    setPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getMyPromptList(newQuery)); // 조회
    console.log(newQuery);
  };

  // 공통코드 조회
  const getCommonCodes = useCallback(async () => {
    const pathSegments = pathname.split('/');
    const subTab = pathSegments[3];
    const params = (subTab == 'purchaseList')? [{ grp_cd: 'CATE' }, { grp_cd: 'MODEL' }] : [{ grp_cd: 'CATE' }, { grp_cd: 'MODEL' }, { grp_cd: 'PRPT_STAT' }]; /* , { grp_cd: 'GENRE' } */
    //const params = [{ grp_cd: 'CATE' }, { grp_cd: 'MODEL' }, { grp_cd: 'PRPT_STAT' }]; /*  */

    const { data } = await api.fetchCodesByGroupCodes(params);

    const transFormat = data.map((grpCode) => ({
      label: grpCode.grp_nm,
      value: grpCode.grp_cd,
      children: grpCode.detailData.map((item) => ({
        label: item?.cd_nm,
        value: item?.cd,
        parent: item?.parent_cd,
      })),
    }));

    transFormat.push(marketplaceSrot);
    setCommonCodeList(transFormat);
  }, []);

  // 프롬프트 리스트 조회
  const getMyPromptList = useCallback(async (query) => {
    try {
      const { data } = await api.getMyPromptList(query);
      setPromptList((prev) => ({ ...prev, items: data.items }));
    } catch (e) {
      // 에러 처리
    } finally {
    }
  }, []);

  // 상세로 이동
  const moveToDetail = async (item) => {
    if (item.prpt_stat == '00' || item.prpt_stat == '01') {
      // 임시저장, 작성완료일 경우 등록 페이지로 이동
      navigate(SALES_PROMPT_HOME, {
        state: { prpt_id: item.prpt_id },
      });
    } else {
      // 상태가 승인요청 이상일 경우 상세로 이동
      navigate(PROMPT_DETAIL, {
        state: { prpt_id: item.prpt_id },
      });
      updatePromptView(item.prpt_id, 'V'); // 조회 카운트 update
    }
  };

  const updatePromptView = (promptId, viewType) => {
    //  조회 타입 'D'/'P'/'V' (다운로드/플레이/조회)
    api.updatePromptView({ prpt_id: promptId, view_type: viewType });
  };

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    const pathSegments = pathname.split('/');
    const subTab = pathSegments[3];
    setActiveTabKey(subTab);

    const newQuery = { ...promptList.query, keyword_tab: subTab };
    setPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getMyPromptList(newQuery)); // 조회
  }, [pathname]);

  return (
    <>
      <article id="otherWrapper" className="flexColCenter promptList">
        <Filter filterItem={commonCodeList} handleChangeFilter={handleChangeFilter} key={pathname} />

        <div className="rsWrapper flexColCenter">
          <div className="flexRowBetween bottom">
            <div className={`content flexColCenter  ${promptList.query.keyword_tab == 'salesList' ? 'sales' : ''}`}>
              <div className="listBox flexRowStart">
                {promptList.items?.map((item) => (
                  <div className="list flexColCenter" key={item.prpt_id} onClick={() => moveToDetail(item)}>
                    <div className={'flexColBetween absoluteBox'}>
                      <div className="flexRowStart user">
                        <img src={item?.icon_path ?? userThum} />
                        <p>{item?.ai_model_nm}</p>
                      </div>

                      <div className="flexColEnd">
                        <div className={`title ${promptList.query.keyword_tab == 'salesList' ? '' : 'flexRowBetween'}`}>
                          <div className="flexRowBetween">
                            <h1>{item?.prpt_title}</h1>
                            <p>{`₩${formatNumber(item?.sale_amt)}`}</p>
                          </div>
                          {promptList.query.keyword_tab == 'salesList' && (
                            <div class="flexRowBetween info">
                              <div className="flexRowStart">
                                <img src={heartIcon} />
                                <p>{formatNumber(item?.sales_cnt)}</p>
                              </div>
                              <div className="flexRowStart">
                                <img src={heartIcon} />
                                <p>{formatNumber(item?.sales_cnt)}</p>
                              </div>
                              <div className="flexRowEnd">
                                <div id="pinkBtn2" className="active">
                                  {item.prpt_stat_nm}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <img src={item.thum_path} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default MarketPromptList;
