import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Skeleton, Dropdown, Tooltip } from 'antd';
// icon
import mainIcon from '@assets/images/common/main_icon.png';
import followIcon from '@assets/images/common/user_icon2.png';
import { RightOutlined } from '@ant-design/icons';
import boostIcon from '@assets/images/common/boost_icon.png';
import useIcon from '@assets/images/common/use_icon.png';
import downArrow from '@assets/images/profile/down_arrow_icon_white.png';
import musicIcon from '@assets/images/common/music_icon.png';
import imageIcon from '@assets/images/common/image_icon.png';
import videoIcon from '@assets/images/common/video_icon.png';
import textIcon from '@assets/images/common/text_icon.png';
import whiteMusicIcon from '@assets/images/common/white_music_icon.png';
import whiteImageIcon from '@assets/images/common/white_image_icon.png';
import whiteVideoIcon from '@assets/images/common/white_video_icon.png';
import whiteTextIcon from '@assets/images/common/white_text_icon.png';
// component
import CardSquareMusic1 from '@pages/main/CardSquareMusic1';
import Rate from '@components/Rate';
import IconRank from '@components/IconRank';
// lib
import { koKRFormat, validateSession, getLocalStorage, getDefaultImg } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// path
import * as PATH from '@routes/pathName';
// api
import * as api from '@api/index';

const DetailMusic = ({ selectedMusic }) => {
  const navigate = useNavigate();
  const [musicList, setMusicList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [badgeList, setBadgeList] = useState([]);
  const [aiModelList, setAiModelList] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const [cateRankList, setCateRankList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 팔로우 상태 확인
  const checkFollowing = useCallback(() => {
    const result = followingList?.filter((following) => following?.mem_key === selectedMusic?.mem_key);
    setIsFollowing(result.length > 0);
  }, [followingList, selectedMusic]);

  // 해당 사용자가 만든 음악 리스트 가져오기
  const listMusic = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        mem_key: selectedMusic?.mem_key,
        cate_cd: 'CATE003',
        keyword_tab: 'new',
        filter_stat: '30',
        offset: 0,
        limit: 5,
      });
      if (!data) return;
      setMusicList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedMusic]);

  // 마켓플레이스 뱃지 리스트 조회
  const listBadge = async () => {
    try {
      const { data } = await api.getBadgeInfo();
      setBadgeList(data);
    } catch (error) {
      console.error('마켓플레이스 뱃지 리스트 조회 error', error);
    }
  };

  // 프롬프트 소유자의 마켓플레이스 뱃지 가져오기
  const getMemberBadge = useCallback(
    (bdgCd) => {
      if (!bdgCd || !badgeList.length) return [];
      return badgeList.filter((badge) => bdgCd.includes(badge.bdg_cd));
    },
    [badgeList],
  );

  // AI 모델 리스트 조회
  const listAiModel = async () => {
    try {
      const { data } = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      setAiModelList(data);
    } catch (error) {
      console.error('AI 모델 리스트 조회 error', error);
    }
  };

  // 프롬프트 소유자의 전문 AI 분야 가져오기
  const getMemberAiModel = useCallback(
    (aiFlCd) => {
      if (!aiFlCd || !aiModelList.length) return;
      return aiModelList.filter((aiModel) => aiFlCd.includes(aiModel.cd));
    },
    [aiModelList],
  );

  // 팔로우 요청
  const requestFollow = useCallback(
    async (prevIsFollowing) => {
      try {
        // 로그인 체크
        if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true }) != true) return false;

        await api.addFollower({ mem_key: selectedMusic?.mem_key, flw_mem_key: memKey });
        await getMyFollowingList();
        if (prevIsFollowing) message.success('팔로우가 취소 되었습니다.');
        else message.success('팔로우 요청에 성공하였습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [selectedMusic],
  );

  // 프롬프트 소유자 프로필 조회
  const getUserProfile = useCallback(async () => {
    try {
      const { data } = await api.userProfile({ params: { mem_key: selectedMusic?.mem_key } });
      setUserProfile(data);
      const newCateRankList = data?.agg_cate?.split(',')?.map((cateCd, index) => {
        return {
          agg_cate: cateCd,
          agg_rank: data?.agg_rank?.split(',')[index],
        };
      });
      const sortedCateRankList = newCateRankList?.sort((a, b) => a?.agg_rank - b?.agg_rank);
      setCateRankList(sortedCateRankList || []);
    } catch (error) {
      console.error('프롬프트 소유자 프로필 조회 error', error);
    }
  }, [selectedMusic]);

  // 내 팔로우 리스트 조회
  const getMyFollowingList = useCallback(async () => {
    try {
      const { data } = await api.listFollower({ flw_mem_key: memKey });
      if (!data) return;
      setFollowingList(data.items);
    } catch (error) {
      console.error('내 팔로우 리스트 조회 error', error);
    }
  }, []);

  // 카테고리별 이미지 가져오기
  const getCateImage = (cateCd, active = false) => {
    return {
      CATE001: active ? whiteImageIcon : imageIcon,
      CATE002: active ? whiteVideoIcon : videoIcon,
      CATE003: active ? whiteMusicIcon : musicIcon,
      CATE004: active ? whiteTextIcon : textIcon,
    }[cateCd];
  };

  useEffect(() => {
    if (selectedMusic) {
      getUserProfile();
      checkFollowing();
      listMusic();
    }
  }, [selectedMusic, followingList]);

  useEffect(() => {
    getMyFollowingList();
    listBadge();
    listAiModel();
  }, []);

  return (
    <>
      <img src={mainIcon} alt="main_icon" className="mainIcon" />

      <div id="musicInfo" className="flexColCenter">
        <div className="mobileTop flexRowBetween">
          <div className="flexRowCenter">{selectedMusic?.ai_model_nm && <p className="aiModelNm">{selectedMusic?.ai_model_nm}</p>}</div>

          {/* <img src={selectedMusic?.rank_icon_path} alt="icon" /> */}
          <IconRank rankValue={selectedMusic?.rnk} />
        </div>
        {isLoading ? (
          <Skeleton avatar active paragraph={{ rows: 4 }} />
        ) : (
          <>
            <div className="rsWrapper flexRowBetween">
              {/* Big thumnail : ai/ nickname/ ranking*/}
              <div className="flexColCenter thumbBox" style={{ backgroundImage: `url(${getDefaultImg('music', selectedMusic)})` }}>
                <div className="flexColBetween absolBox">
                  <div className="flexRowBetween force">
                    <div className="flexRowCenter">{selectedMusic?.ai_model_nm && <p>{selectedMusic?.ai_model_nm}</p>}</div>

                    <IconRank rankValue={selectedMusic?.rnk} />
                  </div>

                  <div className="name flexColStart">{userProfile?.mem_nick}</div>
                </div>
              </div>

              <div className="info flexColStart">
                {/* music title */}
                <h1 onClick={() => moveToDetailPage(selectedMusic)}>{selectedMusic?.prpt_title}</h1>
                {/* music info */}
                <div className="flexRowBetween">
                  {/* 프로필*/}
                  <div className="userBox">
                    {/* 프로필*/}
                    <div className="userInfo ">
                      <figure>
                        <img src={userProfile?.mem_img_path} alt="thumb" onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: userProfile?.mem_key })} />
                      </figure>
                      {/* 프로필 이미지/랭킹 등등 */}
                      <div className="nameBox">
                        <div className="userArea">
                          {/* plan : pro/basic/beginner */}
                          <span className="badge pro ">{userProfile?.plan_nm}</span>
                          <p onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: userProfile?.mem_key })}>{userProfile?.mem_nick}</p>
                        </div>

                        <div className="">
                          {/* badgeMusicBronze =>asset/iamge/common/bad_musicBronze.svg */}
                          <div className="major">
                            {getMemberBadge(userProfile?.bdg_cd).map((badge, index) => (
                              <Tooltip title={badge?.bdg_nm} overlayClassName="badge-nm">
                                <img key={index} src={badge?.icon_path} alt="icon" />
                              </Tooltip>
                            ))}
                          </div>
                        </div>

                        <div className="appraisal">
                          <div className="classRank">
                            <Dropdown
                              getPopupContainer={() => document.getElementsByClassName('classRank')[0]}
                              menu={{
                                items: cateRankList?.map((rank, index) => {
                                  if (index > 0)
                                    return {
                                      key: index,
                                      label: (
                                        <div className="flexRowStart">
                                          <img src={getCateImage(rank?.agg_cate)} alt="cate icon" />
                                          <p>#{koKRFormat(rank?.agg_rank) || 'N/A'}</p>
                                        </div>
                                      ),
                                      onClick: () => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: userProfile?.mem_key, ranking: 'true', cateCd: rank?.agg_cate }),
                                    };
                                }),
                              }}
                            >
                              <div
                                className="flexRowStart"
                                onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: userProfile?.mem_key, ranking: 'true', cateCd: cateRankList[0]?.agg_cate })}
                              >
                                <img src={getCateImage(cateRankList[0]?.agg_cate, true) || ''} alt="cate icon" />
                                <p>#{koKRFormat(cateRankList[0]?.agg_rank) || 'N/A'}</p>
                                <img src={downArrow} alt="down arrow" />
                              </div>
                            </Dropdown>
                          </div>
                          <div className="star">
                            <Rate isNotEmpty={true} value={selectedMusic?.score_avg} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="majorModel">
                      <span className="title">전문 AI분야</span>
                      {userProfile?.ai_fl_cd ? (
                        <>
                          {getMemberAiModel(userProfile?.ai_fl_cd).map((aiModel, index) => (
                            <span key={index} className="">
                              {aiModel?.cd_nm}
                            </span>
                          ))}
                        </>
                      ) : (
                        <>
                          {userProfile?.mem_key === memKey ? (
                            <span className="me" onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: memEmail, mem_key: memKey, profileDetail: 'true' })}>
                              전문 AI 분야를 설정하세요
                            </span>
                          ) : (
                            <span>없음</span>
                          )}
                        </>
                      )}
                    </div>
                    {userProfile?.mem_key !== memKey && (
                      <div className="btnBox">
                        {/* <div id="whiteBorderBtn">
                          <img src={boostIcon} />
                          <p>후원하기</p>
                        </div> */}

                        <div id="whiteBorderBtn">
                          <img src={useIcon} />
                          <p>사용의뢰</p>
                        </div>

                        <div id={'whiteBorderBtn'} className={isFollowing ? 'active' : ''} onClick={() => requestFollow(isFollowing)}>
                          <img src={followIcon} />
                          <p>팔로우</p>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* 다른콘텐츠 박스 */}
                  <div className="contentsBox flexColStart">
                    <div className="flexRowStart" onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: selectedMusic?.mem_key })}>
                      <h1>
                        {userProfile?.mem_nick} <span>님의 다른 콘텐츠</span>
                      </h1>
                      <p>
                        <RightOutlined />
                      </p>
                    </div>

                    <div className="musicBox ">
                      {musicList?.map((music) => (
                        <CardSquareMusic1 data={music} moveToDetailPage={() => moveToDetailPage(music)} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobileContentsBox flexRowBewteen">
              <div className="flexRowStart">
                <h1>
                  {userProfile?.mem_nick} <span>님의 다른 콘텐츠</span>
                </h1>
                <p>
                  <RightOutlined />
                </p>
              </div>

              <div className="musicBox flexRowStart">
                {musicList?.slice(0, 3).map((music) => (
                  <CardSquareMusic1 data={music} moveToDetailPage={() => moveToDetailPage(music)} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailMusic;
