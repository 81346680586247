import React, { useEffect, useState } from 'react';
import HeaderUser from './HeaderUser';
import FixedNav from '@components/FixedNav';
import Footer from './Footer';
import { CheckIconsProvider } from '../utils/lib'; // Context import

const LayoutBasic = ({ ContentBody }) => {
  const [sectionId, setSectionId] = useState('');

  useEffect(() => {
    setSectionId(window.location.pathname.split('/')[1] + 'Section');
  }, [ContentBody]);

  return (
    <>
      <CheckIconsProvider>
        <HeaderUser />
          <section style={{ margin: `52px 0 0` }} className={sectionId ? sectionId + ' userPage' : sectionId}>
              {ContentBody}
              <FixedNav />
          </section>
        <Footer />
      </CheckIconsProvider>
    </>
  );
};

export default LayoutBasic;
