import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as api from '@api/index';
import { getLocalStorage, formatBadgeIcon, handleLinkClick, formatDate, handleImageError } from '@utils/lib';
import { useLocation, useNavigate } from 'react-router-dom';
import '@assets/css/profile.css';
import { message, Checkbox, Drawer, Modal, Select, Radio, Rate } from 'antd';
import { profileHomeType } from '@utils/constants';
import PromptSlider from '@components/Slider';
import useWidth from '@hooks/useWidth';

import noteIcon from '@assets/images/profile/note_icon.png';
import downArrow from '@assets/images/profile/down_arrow_icon_white.png';
import imageIcon from '@assets/images/profile/image_icon.png';
import videoIcon from '@assets/images/profile/video_icon.png';
import bookIcon from '@assets/images/profile/book_icon2.png';
import mobileFilterIcon from '@assets/images/profile/mobile_filter_icon.png';
import promptIcon from '@assets/images/profile/prompt_myHomg.png';
import earthIcon from '@assets/images/profile/webW.svg';
import youtubeIcon from '@assets/images/profile/youtubeW.svg';
import XIcon from '@assets/images/profile/xW.svg';
import instaIcon from '@assets/images/profile/instarW.svg';
import searchIcon from '@assets/images/profile/search_icon2.png';
import starIcon from '@assets/images/profile/star_icon.png';
import partyIcon from '@assets/images/profile/party_icon.png';
import newIcon from '@assets/images/profile/new_icon2.png';
import guideIcon from '@assets/images/profile/guide_icon.png';
import FollowModal from '@components/FollowModal';
import * as PATH from "@routes/pathName";
import ComplaintModal from "@components/ComplaintModal";
import {IMAGES} from "@utils/image"

const defaultBannerSVG = `
    data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 200'%3E
        %3Crect width='600' height='200' fill='%23ccc' /%3E
        %3Ctext x='50%' y='50%' text-anchor='middle' font-size='40' fill='%23fff'%3E배너가 없습니다%3C/text%3E
    %3C/svg%3E`;

const ProfileHome = ({ overrideData = {} }) => {
  const width = useWidth();
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const location = useLocation();
  const [userProfile, setUserProfile] = useState({});
  const sendRanking = useRef();
  const [selectedAis, setSelectedAis] = useState([]);
  const [isComplaintModal, setIsComplaintModal] = useState(false);

  const navigate = useNavigate();
  const getProfile = async () => {
    try {
      const params = { mem_key: overrideData?.mem_key ?? memKey };
      const response = await api.userProfile({ params });

      if (response?.data) {
        if (response.data.mem_stat != '02') {
            alert('작성자가 이미 회원이 아닙니다.\n프로필을 조회할 수 없습니다.');
            navigate(-1);
        }
        setUserProfile(response.data);
        sendRanking.current = response.data;
        let followerList = null;
        if (memKey != null && response.data.follower_list) {
          followerList = JSON.parse(response.data.follower_list);
        }

        setIsFollowing(followerList ? checkFollowing(followerList) : false);

        if (response.data?.ai_fl_cd) {
          const ai_nm = response.data.ai_fl_nm.split('|');
          setSelectedAis(ai_nm);
        }
      } else {
        console.error('No data response');
        setUserProfile(null);
        setIsFollowing(false);
      }
    } catch (error) {
      console.error('Error getProfile:', error);
      setUserProfile(null);
      setIsFollowing(false);
    }
  };

  const [followModalVisible, setFollowModalVisible] = useState(false);
  const [modalType, setModalType] = useState('follower');
  const [followData, setFollowData] = useState([]);
  const [selectedType, setSelectedType] = useState(`${overrideData?.cate_cd || profileHomeType[0]?.value}`);
  const [filterText, setFilterText] = useState(''); // 입력값 관리용 state
  const followerRef = useRef();
  const followingRef = useRef();

  const handleFollowerClick = () => {
    const followerList = JSON.parse(userProfile?.follower_list || '[]');
    if (followerList.length === 0) {
      message.warning('팔로워 한 사람이 없습니다.');
      return;
    }
    setModalType('follower');
    setFollowData(followerList);
    setFollowModalVisible(true);
  };

  const handleFollowingClick = () => {
    const followingList = JSON.parse(userProfile?.following_list || '[]');
    if (followingList.length === 0) {
      message.warning('팔로잉 한 사람이 없습니다.');
      return;
    }
    setModalType('following');
    setFollowData(followingList);
    setFollowModalVisible(true);
  };


  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };

  const [popularList, setPopularList] = useState([]);
  const [recentList, setRecentList] = useState([]);

  const handleKeyPress  = async (e) => {
    if (e.key == 'Enter') {
        setFilterText(e.target.value);
        popularPrompt();
        recentPrompt();
    }
  };

  const popularPrompt = useCallback(async () => {
    try {
      const { data } = await api.getProfilePromptList({
        cate_cd: selectedType,
        mem_key: overrideData?.mem_key,
        filter_sort: 'popular',
        offset: 0,
        limit: 12,
        filter_text: filterText || '',
      });
      if (!data) return;
      setPopularList(data.items);
    } catch (error) {
      message.warning(error.message);
    }
  }, [selectedType, filterText]);

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleSearchClick = () => {
    recentPrompt();
    popularPrompt();
  };

  const recentPrompt = useCallback(
    async () => {
      try {
        const { data } = await api.getProfilePromptList({
          cate_cd: selectedType,
          mem_key: overrideData?.mem_key,
          filter_sort: 'new',
          offset: 0,
          limit: 12,
          filter_text: filterText || '',
        });
        if (!data) return;

        // console.log(data.items);
        setRecentList(data.items);
      } catch (error) {
        message.warning(error.message);
      }
    },
    [selectedType, filterText],
  );

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
      recentPrompt();
      popularPrompt();
    }, []);


  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.target.closest('img')) {
        setIsVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isFollowing, setIsFollowing] = useState(false);

  const checkFollowing = (data) => {
    return data.some((item) => item.mem_key === parseInt(memKey, 10));
  };

  const requestFollow = useCallback(async () => {
    try {
      const { data } = await api.addFollower({ mem_key: overrideData?.mem_key, flw_mem_key: memKey });

      if (data === 'success') {
        const messageText = isFollowing ? '팔로우를 취소하였습니다.' : '팔로우 요청에 성공하였습니다.';
        message.success(messageText);
      }
      await getProfile({ mem_key: overrideData?.mem_key });
    } catch (error) {
      message.warning(error.message);
    }
  }, [memKey, isFollowing]);



  const handleProfileClick = () => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_key: memKey,
        profileDetail: "true"
      }
    });
  };

  // 선택한 메뉴로 이동
  const moveToPage = (num) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_email: sendRanking.current.mem_email,
        mem_key: sendRanking.current.mem_key,
        cateCd: (sendRanking.current.agg_cate || '').split(',')[num],
        ranking: "true",
      }
    });
  };

  return (
    <>
      <article className="flexColCenter" id="myHome">
        <div className="top flexColCenter">
          <img src={userProfile?.ch_img_path || defaultBannerSVG} onError={(e) => handleImageError(e, null, defaultBannerSVG)} />
          <div className="rsWrapper flexRowStart">
            {width < 700 && (
              <div className="flexColStart title">
                <h1>{userProfile?.about_me}</h1>
                <h2>{userProfile?.about_chanel}</h2>
              </div>
            )}

            <div className="profile flexColcenter">
              <div className="flexRowBetween topProfile">
                <div className="thumb flexColCenter">
                  <img src={userProfile?.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />
                </div>

                {width < 700 && <h1>{`@${userProfile?.mem_nick}`}</h1>}

                <div className="flexColStart">
                  <div className="flexRowBetween gradeBox">
                    <div className="flexRowCenter">
                      <div className="badge pro">{userProfile?.plan_nm}</div>
                      {formatBadgeIcon(userProfile?.bdg_url)}
                    </div>
                    {width < 700 ? (
                      <p>{userProfile?.fir_dt ? formatDate(userProfile?.fir_dt) : 'N/A'}</p>
                    ) : (
                      <div className="snsArea">
                        {userProfile?.web && <img src={earthIcon} onClick={() => handleLinkClick(userProfile?.web, 'Web')} />}
                        {userProfile?.x_link && <img src={XIcon} onClick={() => handleLinkClick(userProfile?.x_link, 'X')} />}
                        {userProfile?.youtube_link && <img src={youtubeIcon} onClick={() => handleLinkClick(userProfile?.youtube_link, 'Youtube')} />}
                        {userProfile?.insta_link && <img src={instaIcon} onClick={() => handleLinkClick(userProfile?.insta_link, 'Insta')} />}
                      </div>
                    )}
                  </div>

                  {width < 700 ? (
                    <div className="flexColCenter mobile">
                      <div className="flexRowCenter">
                        <img src={starIcon} />
                        <p>{userProfile?.score_avg}</p>
                        <h3>({userProfile?.review_cnt})</h3>
                      </div>

                      <div className="flexRowCenter">
                        <img src={earthIcon} />
                        <img src={instaIcon} />
                      </div>
                    </div>
                  ) : (
                    <>
                      <h1>{`@${userProfile?.mem_nick}`}</h1>
                      <h2>{userProfile?.fir_dt ? formatDate(userProfile?.fir_dt) : 'N/A'}</h2>
                    </>
                  )}
                </div>
              </div>

              {/* //  팔로잉 팔로우 */}
              <ul className="followBox">
                <li className="" onClick={handleFollowingClick}>
                  <p ref={followingRef}>팔로잉 수</p>
                  <span>{userProfile?.following_cnt}</span>
                </li>
                <li className="" onClick={handleFollowerClick}>
                  <p ref={followerRef}>팔로워 수</p>
                  <span>{userProfile?.follower_cnt}</span>
                </li>
              </ul>
              <div className="btnArea" style={{ display: memKey ? 'block' : 'none' }}>
                {memKey !== overrideData?.mem_key && (
                  <div className="followBtn">
                    {/* <button className="btnPink2 donation">후원하기</button> */}
                    <button className="btnPink2 request">사용의뢰</button>
                    <button className="btnPink2" onClick={requestFollow}>
                      {isFollowing ? <> 팔로잉 </> : <> 팔로우 </>}
                    </button>
                  </div>
                )}
              </div>
              <FollowModal
                visible={followModalVisible}
                onClose={() => setFollowModalVisible(false)}
                type={modalType}
                data={followData}
                anchorRef={modalType === 'follower' ? followerRef : followingRef}
              />
              {width < 700 && (
                <div className="flexColCenter mobile">
                  <h2>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[2] : 'N/A'}</h2>
                  <div className="flexRowCenter" >
                    <p>순위</p>
                    <img src={noteIcon} alt="note icon" />
                    <img src={downArrow} alt="down arrow" onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                  </div>
                  {isVisible && (
                    <div className="absoluteBox flexColCenter" ref={wrapperRef}>
                      <div className="flexRowStart">
                        <img src={imageIcon} alt="image icon" />
                        <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[0] : 'N/A'}</p>
                      </div>
                      <div className="flexRowStart">
                        <img src={videoIcon} alt="video icon" />
                        <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[1] : 'N/A'}</p>
                      </div>
                      <div className="flexRowStart">
                        <img src={bookIcon} alt="book icon" />
                        <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[3] : 'N/A'}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* 분야별 배지*/}
              {/* badgeMusicBronze =>asset/iamge/common/bad_musicBronze.svg */}
              <div className="major">
                <img src={'@assets/images/common/bad_musicBronze.svg'} alt="seller icon" />
                <img src={'@assets/images/common/bad_musicBronze.svg'} alt="music icon" />
                <img src={'@assets/images/common/bad_musicBronze.svg'} alt="movie" />
              </div>

              {/* ai 전문분야*/}
              <div className="majorModel">
                <span className="title">전문 AI분야</span>
                {selectedAis.length ? selectedAis.map((ai) => <span className="">{ai}</span>) : (memKey === userProfile?.mem_key ? <span onClick={handleProfileClick} style={{cursor:"pointer"}}>전문 AI 분야를 설정하세요</span> : <span>없음</span>)}
              </div>
              {width > 700 && (
                <div className="flexRowBetween bottom">
                  <div className="flexRowCenter" style={{cursor:"pointer"}}>
                    <img src={noteIcon} alt="note icon" onClick={()=>moveToPage(2)} />
                    <p style={{cursor:"pointer"}} onClick={()=>moveToPage(2)}>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[2] : 'N/A'}</p>
                    <img src={downArrow} alt="down arrow" onClick={toggleVisibility} style={{ cursor: 'pointer' }} />
                    {isVisible && (
                      <div className="absoluteBox flexColCenter" ref={wrapperRef}>
                        <div className="flexRowStart" style={{cursor:"pointer"}} onClick={()=>moveToPage(0)}>
                          <img src={imageIcon} alt="image icon" />
                          <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[0] : 'N/A'}</p>
                        </div>
                        <div className="flexRowStart" style={{cursor:"pointer"}} onClick={()=>moveToPage(1)}>
                          <img src={videoIcon} alt="video icon" />
                          <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[1] : 'N/A'}</p>
                        </div>
                        <div className="flexRowStart" style={{cursor:"pointer"}} onClick={()=>moveToPage(3)}>
                          <img src={bookIcon} alt="book icon" />
                          <p>#{userProfile?.agg_rank ? userProfile?.agg_rank.split(',')[3] : 'N/A'}</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flexRowCenter">
                    <div>{userProfile?.score_avg}</div>
                    <Rate value={userProfile?.score_avg} disabled={true} />
                    <h2>({userProfile?.review_cnt})</h2>
                  </div>
                </div>
              )}
            </div>

            {width > 700 && (
              <div className="flexColStart title">
                <h1>{userProfile?.about_me}</h1>
                <h2>{userProfile?.about_chanel}</h2>
              </div>
            )}
          </div>
        </div>
        <div className="flexColCenter filter">
          <div className="rsWrapper flexRowStart">
            <div className="listBox flexRowStart">
              <div className="flexRowStart title">
                <img src={mobileFilterIcon} />
                <h2>타입</h2>
              </div>
              <Radio.Group value={selectedType} onChange={handleChange}>
                {profileHomeType.map((type) => (
                  <Radio
                    key={type.id}
                    style={{
                      width: width < 700 ? `auto` : `140px`,
                      fontSize: `14px`,
                    }}
                    value={type.value}
                  >
                    {type.label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="flexColCenter searchBox">
          <div className="rsWrapper flexRowCenter">
            {width < 700 && (
              <div className="flexRowCenter search">
                <img src={searchIcon} onClick={handleSearchClick} />
                <input placeholder={`@${userProfile?.mem_nick}님의 컨텐츠를 검색해 보세요.`} value={filterText} onChange={handleInputChange} onKeyDown={(e) => handleKeyPress(e)} />
              </div>
            )}
            <div className="text flexColCenter" onClick={() => navigate('/prompt/create')} style={{ display: location.state?.mem_key === memKey ? '' : 'none', cursor:"pointer" }}>
              <img src={promptIcon} />
              <p>프롬프트 빌더</p>
            </div>

            {width > 700 && (
              <div className="flexRowCenter search">
                <img src={searchIcon} onClick={handleSearchClick} />
                <input placeholder={`@${userProfile?.mem_nick}님의 컨텐츠를 검색해 보세요.`} value={filterText} onChange={handleInputChange} onKeyDown={(e) => handleKeyPress(e)}/>
              </div>
            )}

            <div className="text flexColCenter" style={{ display: location.state?.mem_key === memKey ? '' : 'none', cursor:"pointer" }}>
              <img src={guideIcon} />
              <p>프롬프트 가이드</p>
            </div>
          </div>
        </div>
        <div className="promptThumList">
          <PromptSlider title={'인기 프롬프트'} titleImg={partyIcon} card={6} slide={1} prompts={popularList} loop={true} />
          <PromptSlider title={'최신 프롬프트'} titleImg={newIcon} card={6} slide={1} prompts={recentList} loop={true} />
        </div>

        <ComplaintModal isOpen={isComplaintModal} onClose={() => setIsComplaintModal(false)} />
      </article>
    </>
  );
};

export default ProfileHome;
