import React, { useEffect, useRef, useState } from 'react';
import { message, Layout, Input, Select, Typography, Modal, Spin, Menu, Button } from 'antd';
import * as api from '@api/index';
import StepZero from '@pages/prompt/StepZero';
import Step from './Step';
import DragAndDropList from '@components/DragAndDropList';
import ParameterStep from './ParameterStep';
import LyricsStep from './LyricsStep';
import useWidth from '@hooks/useWidth';
import { CheckOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import promptGuide from '../../assets/images/common/info_builder.svg';
import promptIcon from '../../assets/images/common/prompt_icon2.png';
import resetIcon from '../../assets/images/common/reset_icon2.png';
import updateIcon from '../../assets/images/common/update_icon.png';
import cancelIcon from '../../assets/images/common/i-cacelW.svg';
import copyIcon from '../../assets/images/common/copy_icon.png';
import arrowRight from '../../assets/images/common/pink_arrow_right.png';
import { getLocalStorage } from '@utils/lib';
import { useNavigate } from 'react-router-dom';

import * as PATH from '@routes/pathName';

const { Header } = Layout;

const CreatePrompt = () => {
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';
  const memPlanCd = getLocalStorage('prptbk-token', 'plan_cd') || '';

  const navigate = useNavigate();
  const width = useWidth();

  const [selectStepKey, setSelectStepKey] = useState(0);
  const [menuData, setMenuData] = useState([]);
  const [mobilePromptTab, setMobilePromptTab] = useState(false);
  const [mobileSelectTab, setMobileSelectTab] = useState(true);

  const { TextArea } = Input;
  const { Title } = Typography;

  const [isLoading, setIsLoading] = useState(false);
  const [builderId, setBuilderId] = useState(0);

  const [commonCategoryList, setCommonCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryCode, setSelectedCategoryCode] = useState();
  const [builderUnderCategoryList, setBuilderUnderCategoryList] = useState([]);
  const [selectedCommonCategory, setSelectedCommonCategory] = useState(null);
  const [selectedBuilderCategory, setSelectedBuilderCategory] = useState(null);
  const [selectedBuilder, setSelectedBuilder] = useState(null);
  const [selectedBuilderInfo, setSelectedBuilderInfo] = useState(null);
  const selectedLangType = useRef('EN');
  const [langType, setLangType] = useState('EN');
  const [executeCreatePropmt, setExecuteCreatePrompt] = useState(false);

  const [commonAiModelCodeList, setCommonAiModelCodeList] = useState([]);
  const [aiModelComboData, setAiModelComboData] = useState([]);

  const [stepContents, setStepContents] = useState([]);

  const [aiSelect, setAiSelect] = useState();
  const promptList = useRef([]);

  const [promptLists, setPromptLists] = useState([]); // promptSeq별로 리스트를 관리
  const [promptTexts, setPromptTexts] = useState([]); // promptSeq별로 텍스트를 관리

  const optionList = useRef([]); // 구성 요소 리스트
  const [emphasisOptions, setEmphasisOptions] = useState([]); // 강조 구문 리스트
  const [parameterList, setParameterList] = useState([]); // 파라미터 리스트
  const [selectedParameters, setSelectedParameters] = useState([]); // 프롬프트별 파라미터 값 배열

  const [isEditing, setIsEditing] = useState([]); // 각 프롬프트 수정 상태
  const [editText, setEditText] = useState(''); // 현재 편집 중인 텍스트 백업

  const [aiPrefix, setAiPrefix] = useState('');
  const aiSuffix = useRef('');

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isCongratuModalOpen, setIsCongratuModalOpen] = useState(false);
  const [saveTitle, setSaveTitle] = useState('');

  const [promptFoldList, setPromptFoldList] = useState([]);

  const createBuilderInfo = useRef({});

  const fetchData = async () => {
    const response = await api.fetchInitialData();

    await setCommonAiModelCodeList(response.data.commonAiModelCodes);

    response.data.builderUnderCategorys.forEach((builder) => {
      response.data.categorys.forEach((category) => {
        if (builder.parent_el_id === category.el_id) {
          category.cate_cd = builder.cate_cd;
        }
      });
    });
    setCommonCategoryList(response.data.commonCategorys);
    setCategoryList(response.data.categorys);
    setBuilderUnderCategoryList(response.data.builderUnderCategorys);
  };

  // 공통 카테고리 선택 시 호출되는 핸들러
  const handleCommonCategoryChange = (value) => {
    setSelectedCommonCategory(value);
    setSelectedBuilderCategory(null);
    setSelectedBuilder(null);
  };

  // 빌더 카테고리 선택 시 호출되는 핸들러
  const handleBuilderCategoryChange = (value) => {
    setSelectedBuilderCategory(value);
    setSelectedBuilder(null);
    setAiSelect([{ value: '', label: 'AI 모델 선택' }]);
    setLangType('EN');
  };

  // 빌더 선택 시 호출되는 핸들러
  const handleBuilderChange = async (value) => {
    const builder = builderUnderCategoryList.find((item) => item.el_id === value);
    if (builder.use_lv > memPlanCd) {
      if (await window.confirm('선택한 빌더는 ' + builder.use_lv_nm + ' 이상 멤버십만 \n사용할 수 있습니다.\n멤버십 관리 화면으로 이동하시겠습니까?')) {
        navigate(`${PATH.PROFILE_PAGE}`, { state: { mem_email: memEmail, mem_key: memKey, planInfo: 'true' } });
      }
      return false;
    }

    setSelectedBuilderInfo(builder);
    setSelectedBuilder(value);
    if (builder) {
      setSelectedCategoryCode(builder.cate_cd);
      setBuilderId(builder.el_id);
      clearPrompt();
      makeAiModelComboList(builder.ai_model_cd, builder.cate_cd);
    }
  };

  const fetchBuilderData = async (elId) => {
    await setMenuData([]);
    const response = await api.fetchBuilderData({ el_id: elId });

    aiSuffix.current = commonAiModelCodeList.find((item) => {
      return item.cate_cd === response.data.builderInfo.cate_cd && item.ai_model_cd === aiSelect;
    }).suffix;

    optionList.current = response.data.optionList;

    promptList.current = [];
    response.data.promptList.forEach((prompt, index) => {
      promptList.current[index] = prompt;
    });

    const promptCount = response.data.promptList.length;
    setPromptLists(Array.from({ length: promptCount + 1 }, () => []));
    setPromptTexts(Array.from({ length: promptCount }, () => ''));
    setEditText(Array.from({ length: promptCount + 1 }, () => ''));
    setPromptFoldList(new Array(promptCount + 1).fill('unfold'));

    //step zero 순서 정렬 후 STEP0탭에 추가
    const stepZeroList = response.data.stepZeroList;
    if (stepZeroList.length > 0) {
      setMenuData([{ key: '0', label: response.data.builderInfo.step_zero_nm }]);
      const sortedStepZeroList = [...stepZeroList].sort((a, b) => a.prpt_seq - b.prpt_seq);
      setStepContents([
        {
          key: 0,
          // label: response.data.builderInfo.step_zero_nm,
          children: (
            <StepZero
              builderId={builderId}
              stepZeroList={sortedStepZeroList}
              handleSubmitPromptText={handleSubmitPromptText}
              stepZeroType={response.data.builderInfo.step_zero_type}
              stepZeroImgPath={response.data.builderInfo.step_zero_phy_path}
              groupKey={0}
            />
          ),
        },
      ]);
    }

    //stepContents에 각 Step 추가
    const stepList = response.data.stepList;
    const treeData = JSON.parse(response.data.treeData);
    const stepSeqList = Array.from(new Set(stepList.map((step) => step.step_seq))).sort((a, b) => a - b);
    for (const stepSeq of stepSeqList) {
      const stepData = [];
      let stepName = '';
      let stepUseLv = '';
      let stepUseLvNm = '';
      let stepImgPath = '';
      stepList.forEach((step) => {
        if (step.step_seq === stepSeq) {
          stepName = step.step_nm; // step_nm을 저장
          stepUseLv = step.use_lv;
          stepUseLvNm = step.use_lv_nm;
          stepImgPath = step.help_path;
        }
        treeData[0].children.forEach((child) => {
          if (step.step_seq === stepSeq && step.el_id === child.el_id) {
            stepData.push(child);
          }
        });
      });
      setMenuData((prev) => [...prev, { key: prev.length, label: stepName }]);
      setStepContents((stepContents) => [
        ...stepContents,
        {
          key: stepContents.length,
          // label: stepName,
          children: (
            <Step
              builderId={builderId}
              stepType="group"
              stepData={stepData}
              stepUseLv={stepUseLv}
              stepUseLvNm={stepUseLvNm}
              stepImgPath={stepImgPath}
              handleClickElements={handleClickElements}
              handleSubmitPromptText={handleSubmitPromptText}
              selectedBuilderInfo={selectedBuilderInfo}
              langType={langType}
              groupKey={stepContents.length}
              optionList={optionList.current}
            />
          ),
        },
      ]);
    }
    const libraryTreeData = JSON.parse(response.data.libraryTreeData);
    if (libraryTreeData) {
      setMenuData((prev) => [...prev, { key: prev.length, label: '라이브러리' }]);
      setStepContents((stepContents) => [
        ...stepContents,
        {
          key: 'library',
          label: '라이브러리',
          children: (
            <Step
              builderId={builderId}
              stepType="library"
              stepData={libraryTreeData}
              handleClickElements={handleClickElements}
              handleSubmitPromptText={handleSubmitPromptText}
              promptList={response.data.promptList}
              selectedBuilderInfo={selectedBuilderInfo}
              groupKey={9999}
              libraryOptionList={response.data.libraryOptionList}
            />
          ),
        },
      ]);
    }
    setSelectedParameters(Array.from({ length: response.data.promptList.length }, () => [])); // 프롬프트별 파라미터 배열 초기화

    //파라미터 사용여부 Y일 때만 파라미터 스텝 추가
    if (response.data.builderInfo.param_use_yn === 'Y') {
      makeParameterStep(response.data.builderInfo.ai_model_cd.split(',')[0]);
    }

    if (response.data.builderInfo.lyrics_use_yn === 'Y' && response.data.builderInfo.lyrics_el_id) {
      setMenuData((prev) => [...prev, { key: prev.length, label: '가사' }]);

      const lyricsList = JSON.parse(response.data.lyricsTreeData);
      setStepContents((stepContents) => [
        ...stepContents,
        {
          key: 'lyrics',
          label: '가사',
          children: (
            <LyricsStep
              stepType="lyrics"
              stepData={lyricsList[0].children}
              handleSubmitLyrics={handleSubmitLyrics}
              lyricsId={lyricsList[0].el_id}
              lyricsOptions={response.data.lyricsOptions}
              createBuilderInfo={createBuilderInfo.current}
              groupKey="lyrics"
              lyricsLibraryOptions={response.data.lyricsLibraryOptions}
              lyricsBuilderPrompt={response.data.lyricsBuilderPrompt}
              promptLists={promptLists}
            />
          ),
        },
      ]);
      promptList.current.push({
        ko_nm: '가사 프롬프트', // 가사 프롬프트의 이름 지정
        type: 'lyrics', // 구분을 위한 타입
        prpt_seq: promptList.current.length + 1, // 프롬프트 순서
      });
    }
    setIsLoading(false);
  };

  // 가사 입력 처리 함수
  const handleSubmitLyrics = async (params) => {
    setPromptLists((prev) => {
      const updatedPromptLists = [...prev];
      const isDuplicate = updatedPromptLists[updatedPromptLists.length - 1].some((item) => {
        if (item.groupSeq === 0 && params.type === 'first') {
          message.error('도입부는 중복될 수 없습니다.');
          return true;
        }
        if (item.groupSeq === 9999 && params.type === 'last') {
          message.error('종결부는 중복될 수 없습니다.');
          return true;
        }
        return false;
      });
      if (isDuplicate) {
        return prev; // 기존 상태를 그대로 반환
      }
      const lyricsPrompt = {
        title: params.text,
        groupKey: 'lyrics',
        seq: `lyrics-${Date.now()}`,
        groupSeq: params.type === 'first' ? 0 : params.type === 'last' ? 9999 : params.addCount, // 고유한 key 생성
      };
      updatedPromptLists[updatedPromptLists.length - 1] = [...(updatedPromptLists[updatedPromptLists.length - 1] || []), lyricsPrompt];
      return updatedPromptLists;
    });
    sortingPromptList();
  };

  const handleClickElements = async (parameter) => {
    const { el_id, ko_nm, en_nm, promptSeq, parent_el_id, type, img_phy_path, prefix, suffix, pre_sep, suf_sep, and_yn, loop_yn, groupKey, groupSeq } = parameter;

    setPromptLists((prev) => {
      const updatedPromptLists = [...prev];

      if (!updatedPromptLists[promptSeq - 1]) {
        updatedPromptLists[promptSeq - 1] = [];
      }

      const promptList = updatedPromptLists[promptSeq - 1];
      const existsIndex = promptList.findIndex((item) => item.id === el_id && item.groupKey === groupKey && item.groupSeq === groupSeq);

      const pushPromptList = () => {
        const maxSeq = promptList.reduce((max, item) => Math.max(max, item.seq), 0);
        promptList.push({
          id: el_id,
          title: selectedLangType.current === 'KO' ? ko_nm : en_nm,
          ko_nm,
          en_nm,
          seq: maxSeq + 1,
          parent_el_id,
          type,
          img_phy_path,
          prefix: prefix,
          suffix: suffix,
          pre_sep: pre_sep,
          suf_sep: suf_sep,
          and_yn: and_yn,
          loop_yn: loop_yn,
          groupKey: groupKey,
          groupSeq: groupSeq,
        });
      };

      if (loop_yn === 'Y') {
        // loop_yn이 Y인 경우: 중복 추가 가능
        pushPromptList();
      } else {
        // loop_yn이 Y가 아닌 경우: 동일 요소 제거 후 추가
        if (existsIndex !== -1) {
          promptList.splice(existsIndex, 1); // 기존 요소 제거
        } else {
          pushPromptList();
        }
      }

      if (promptList.length > 0) {
        localStorage.setItem('prptUsed', 'Y');
      } else {
        localStorage.removeItem('prptUsed');
      }

      return updatedPromptLists;
    });

    sortingPromptList();
  };

  const handleSubmitPromptText = (parameter) => {
    const { value, promptSeq, type, parentElId, groupKey, groupSeq, prefix, suffix, pre_sep, suf_sep } = parameter;
    if (!value) {
      window.alert('텍스트를 입력해주세요.');
      return;
    }
    setPromptLists((prev) => {
      const updatedPromptLists = [...prev];
      if (!updatedPromptLists[promptSeq - 1]) {
        updatedPromptLists[promptSeq - 1] = [];
      }
      const maxSeq = updatedPromptLists[promptSeq - 1].reduce((max, item) => Math.max(max, item.seq), 0);
      updatedPromptLists[promptSeq - 1].push({
        title: value,
        ko_nm: value,
        en_nm: value,
        seq: maxSeq + 1,
        type: type,
        parentElId: parentElId,
        groupKey: groupKey,
        groupSeq: groupSeq,
        prefix: prefix,
        suffix: suffix,
        pre_sep: pre_sep,
        suf_sep: suf_sep,
      });
      return updatedPromptLists;
    });
  };

  const handleDeletePromptList = (seq, promptSeq) => {
    setPromptLists((prev) => {
      const updatedPromptLists = [...prev];
      updatedPromptLists[promptSeq - 1] = updatedPromptLists[promptSeq - 1].filter((item) => item.seq !== seq);
      return updatedPromptLists;
    });
  };

  const makeAiModelComboList = async (aiModelCode, cateCode) => {
    const aiModelCodes = aiModelCode.split(',');
    const comboList = [{ value: '', label: 'AI 모델 선택' }];

    aiModelCodes.map((code) =>
      commonAiModelCodeList.forEach((commonCode) => {
        if (code === commonCode.ai_model_cd && cateCode === commonCode.cate_cd) {
          comboList.push({ value: commonCode.ai_model_cd, label: commonCode.ai_model_nm });
        }
      }),
    );
    await setAiSelect(comboList[0].value);
    setAiModelComboData(comboList);
  };

  const clearPrompt = (promptSeq) => {
    setPromptLists((prev) => {
      const updatedPromptLists = [...prev];
      updatedPromptLists[promptSeq - 1] = []; // 해당 promptSeq 리스트 초기화
      return updatedPromptLists;
    });

    setPromptTexts((prev) => {
      const updatedPromptTexts = [...prev];
      updatedPromptTexts[promptSeq - 1] = ''; // 해당 promptSeq 텍스트박스 초기화
      return updatedPromptTexts;
    });

    setSelectedParameters((prev) => {
      const updatedParameters = [...prev];
      updatedParameters[promptSeq - 1] = []; // 해당 promptSeq 파라미터 초기화
      return updatedParameters;
    });
  };

  const sortingPromptList = () => {
    setPromptLists((prevPromptLists) => {
      return prevPromptLists.map((list) =>
        [...list].sort((a, b) => {
          if (a.groupKey !== b.groupKey) {
            return a.groupKey - b.groupKey;
          }
          return a.groupSeq - b.groupSeq;
        }),
      );
    });
  };

  const makePromptText = () => {
    // promptLists의 각 영역별 텍스트 생성
    const updatedPromptTexts = promptLists.map((list, promptIndex) => {
      let result = aiPrefix;
      let currentGroupKey = null;
      let currentGroupSeq = null;
      let groupItems = [];

      // 파라미터 텍스트 생성
      const paramTexts = parameterList
        .filter((param) => (selectedParameters[promptIndex] || []).some((p) => p.param_id === param.param_id))
        .map((param) => {
          const selectedParam = (selectedParameters[promptIndex] || []).find((p) => p.param_id === param.param_id);
          return selectedParam.value ? `${selectedParam.in_txt} ${selectedParam.value}` : selectedParam.in_txt;
        })
        .filter(Boolean)
        .join(' ');

      // 파라미터를 텍스트 앞/뒤로 추가
      if (aiSuffix.current === 'Y') {
        result += paramTexts ? `${paramTexts} ` : '';
      }

      // 그룹별 텍스트 생성
      const processGroup = (groupItems, groupOption) => {
        if (!groupItems.length || !groupOption) return;

        const { prefix = '', suffix = '', pre_sep = '', suf_sep = '', and_yn = 'N' } = groupOption;

        // 그룹 접두사 추가
        if (prefix) {
          result += ' ' + prefix.replace(/\\n/g, '\n');
        }

        // 그룹 내 아이템 처리
        groupItems.forEach((item, idx) => {
          const isLast = idx === groupItems.length - 1;
          const isSecondLast = idx === groupItems.length - 2;

          // 강조 구문 데이터 추출
          const emphasisData = item.emphasis || {};
          const { emphasisValue = '', in_yn = 'N', inputValue = '' } = emphasisData;
          const [emphasisPre, emphasisSuf] = emphasisValue ? emphasisValue.split('|') : ['', ''];

          // 접두사 적용
          if (pre_sep) {
            result += pre_sep.replace(/\\n/g, '\n');
          }

          // 강조구문 적용
          result += `${(pre_sep || '').replace(/\\n/g, '\n')}${emphasisPre}${groupOption.groupKey === 'lyrics' ? item.title : selectedLangType.current === 'KO' ? item.ko_nm : item.en_nm}${emphasisSuf}`;

          // in_yn이 'Y'인 경우 입력값 추가
          if (in_yn === 'Y' && inputValue) {
            result += inputValue;
          }

          // 반복 접미사 추가
          if (suf_sep) {
            result += suf_sep.replace(/\\n/g, '\n');
          }

          // and 처리
          if (and_yn === 'Y' && isSecondLast) {
            result += ' and ';
          } else if (!isLast) {
            result += ' ';
          }
        });

        // 그룹 접미사 추가
        if (suffix) {
          result += suffix.replace(/\\n/g, '\n') + ' ';
        }
      };

      // 리스트를 그룹 단위로 처리
      list.sort((a, b) => {
        if (a.groupKey !== b.groupKey) return a.groupKey - b.groupKey;
        return a.groupSeq - b.groupSeq;
      });

      list.forEach((item, index) => {
        const { groupKey, groupSeq } = item;
        const nextItem = list[index + 1];

        if (currentGroupKey !== groupKey || currentGroupSeq !== groupSeq) {
          if (currentGroupKey !== null) {
            const groupOption = optionList.current.find((option) => option.groupKey === currentGroupKey && option.groupSeq === currentGroupSeq);
            processGroup(groupItems, groupOption);
          }

          currentGroupKey = groupKey;
          currentGroupSeq = groupSeq;
          groupItems = [];
        }

        groupItems.push(item);

        if (!nextItem || nextItem.groupKey !== groupKey || nextItem.groupSeq !== groupSeq) {
          processGroup(groupItems, item);
          groupItems = [];
        }
      });

      // 파라미터를 텍스트 뒤에 추가
      if (aiSuffix.current !== 'Y' && paramTexts) {
        result += ` ${paramTexts}`;
      }
      result = result.replace(/,([^,]*)$/, '$1');

      return result.trim();
    });

    // 상태 업데이트
    setPromptTexts(updatedPromptTexts);
  };

  const handleChangedAiModel = async (aiCode) => {
    await setAiSelect(aiCode);
  };

  const makeParameterStep = async (aiCode) => {
    // AI 모델의 파라미터 목록을 조회하여 STEP 업데이트
    const parameterData = await fetchParametersForAiModel(aiCode);
    await setParameterList(parameterData);

    setStepContents((prev) => prev.filter((step) => step.key !== 'Parameter'));
    setMenuData((prev) => prev.filter((menu) => menu.label !== '파라미터'));

    if (parameterData.length > 0) {
      // 파라미터가 있는 경우 "Parameter" STEP을 추가
      setMenuData((prev) => [...prev, { key: prev.length, label: '파라미터' }]);
      setStepContents((prev) => [
        ...prev,
        {
          key: 'Parameter',
          children: <ParameterStep parameterList={parameterData} selectedParameters={selectedParameters} setSelectedParameters={setSelectedParameters} promptList={promptList.current} />,
        },
      ]);
    }

    // 강조 구문 초기화
    await setPromptLists((prev) => prev.map((list) => list.map((item) => ({ ...item, emphasis: null }))));
  };

  const fetchParametersForAiModel = async (aiModelCode) => {
    const response = await api.getParamInfo({ ai_model_cd: aiModelCode, use_yn: 'Y' });
    return response.data;
  };

  const fetchEmphasisOptionsForAiModel = async () => {
    if (!aiSelect || !selectedCategoryCode) return; // 상태가 모두 설정될 때만 실행
    const response = await api.getAiModelWeight({ ai_model_cd: aiSelect, cate_cd: selectedCategoryCode });
    const response2 = await api.getModelInfo({ ai_model_cd: aiSelect, cate_cd: selectedCategoryCode, use_yn: 'Y' });
    setAiPrefix(response2.data[0]?.prefix ? response2.data[0].prefix : '');

    // 데이터 중 null 값 제거 및 빈 옵션 추가
    if (response.data.length > 0) {
      const formattedOptions = [
        { value: '', label: '비중 옵션' },
        ...response.data
          .filter(({ pre_sep, suf_sep, in_yn }) => pre_sep !== null || suf_sep !== null) // 둘 중 하나라도 값이 있는 경우에만 필터링 통과
          .map(({ pre_sep, suf_sep, in_yn }) => ({
            value: `${pre_sep || ''}|${suf_sep || ''}`, // null 값을 빈 문자열로 대체
            label: `${pre_sep || ''} ${suf_sep || ''}`, // null 값을 빈 문자열로 대체하여 표시
            in_yn: in_yn,
          })),
      ];

      setEmphasisOptions(formattedOptions);
    } else {
      setEmphasisOptions([]);
    }
  };

  // 강조 구문 선택 시
  const handleEmphasisChange = (promptIndex, itemIndex, emphasisValue) => {
    const emphasisOption = emphasisOptions.find((option) => option.value === emphasisValue);
    setPromptLists((prevLists) => {
      const updatedLists = [...prevLists];
      if (!updatedLists[promptIndex]) {
        updatedLists[promptIndex] = [];
      }
      if (!updatedLists[promptIndex][itemIndex]) {
        updatedLists[promptIndex][itemIndex] = {};
      }
      updatedLists[promptIndex][itemIndex].emphasis = {
        emphasisValue: emphasisValue,
        in_yn: emphasisOption?.in_yn || 'N',
        inputValue: '', // 입력값 초기화
      };
      return updatedLists;
    });
    makePromptText();
  };

  // 강조 구문 입력값 변경 시
  const handleEmphasisInputChange = (promptIndex, itemIndex, inputText) => {
    setPromptLists((prevLists) => {
      const updatedLists = [...prevLists];
      if (updatedLists[promptIndex] && updatedLists[promptIndex][itemIndex] && updatedLists[promptIndex][itemIndex].emphasis) {
        updatedLists[promptIndex][itemIndex].emphasis.inputValue = inputText;
      }
      return updatedLists;
    });
    makePromptText();
  };

  const handleEdit = (index) => {
    setIsEditing((prev) => prev.map((edit, i) => (i === index ? true : edit)));
    // setEditText(promptTexts[index]);
    setEditText((prev) => {
      const updatedEditText = [...prev]; // 기존 배열 복사
      updatedEditText[index] = promptTexts[index]; // promptTexts의 값 설정
      return updatedEditText;
    });
  };

  const handleSaveEdit = (index) => {
    setIsEditing((prev) => prev.map((edit, i) => (i === index ? 'saved' : edit)));
    setPromptTexts((prev) => prev.map((text, i) => (i === index ? editText[index] : text)));
  };

  const handleCancelEdit = (index) => {
    setIsEditing((prev) => prev.map((edit, i) => (i === index ? false : edit)));
    // setEditText(promptTexts[index]);
    setEditText((prev) => {
      const updatedEditText = [...prev]; // 기존 배열 복사
      updatedEditText[index] = promptTexts[index]; // promptTexts의 값 설정
      return updatedEditText;
    });
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('텍스트가 클립보드에 복사되었습니다.');
      })
      .catch(() => {
        message.error('텍스트 복사에 실패했습니다.');
      });
  };

  const handleSave = async () => {
    const hasLyrics = stepContents.some((step) => step.key === 'lyrics');
    if(saveTitle){
      if (await window.confirm('저장하시겠습니까?')) {
        const params = {
          cate_cd: selectedCategoryCode,
          bld_id: builderId,
          ai_model_cd: aiSelect,
          prpt_title: saveTitle,
        };
  
        if (hasLyrics && promptTexts.length > 1) {
          // prpt_area1, prpt_area2는 순서대로, prpt_area3은 마지막 요소로 고정
          promptTexts.forEach((text, index) => {
            if (index < promptTexts.length - 1) {
              // 순차적으로 prpt_area1, prpt_area2에 할당
              params['prpt_area' + (index + 1)] = text;
            } else {
              // 마지막 항목은 prpt_area3에 고정
              params['prpt_area3'] = text;
            }
          });
        } else {
          // 'lyrics'가 없거나 promptTexts가 1개 이하인 경우 기존 로직
          promptTexts.forEach((text, index) => {
            params['prpt_area' + (index + 1)] = text;
          });
        }
  
        const response = await api.savePromptText(params);
        if (response.data.returnStatus === 'new') {
          setIsCongratuModalOpen(true);
        } else if (response.data.returnStatus === 'save') {
          window.alert('저장되었습니다.');
        } else {
          window.alert('에러가 발생했습니다.', 'error');
        }
        setIsSaveModalOpen(false);
      }
    }else{
      message.error('제목을 입력해주세요.');
      return false;
    }
  };

  const handleMoveToPromptManage = () => {
    navigate(`${PATH.PROMPT_MANAGEMENT}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    makePromptText();
  }, [promptLists, selectedParameters]);

  useEffect(() => {
    // promptList가 업데이트되면 isEditing을 promptList 길이에 맞춰 초기화
    setIsEditing(Array(promptList.current.length).fill(false));
  }, [promptList.current.length]);

  const returnSelectTab = () => {
    return (
      <div className="flexRowStart selectWrapper">
        <Select
          placeholder="빌더 카테고리 선택"
          options={categoryList
            .filter((category) => category.cate_cd === selectedCommonCategory)
            .map((category) => ({
              value: category.el_id,
              label: category.ko_nm,
            }))}
          value={selectedBuilderCategory}
          onChange={handleBuilderCategoryChange}
          disabled={!selectedCommonCategory}
        />
        <Select
          placeholder="빌더 선택"
          options={builderUnderCategoryList
            .filter((builder) => builder.parent_el_id === selectedBuilderCategory)
            .map((builder) => ({
              value: builder.el_id,
              label: builder.ko_nm,
            }))}
          value={selectedBuilder}
          onChange={handleBuilderChange}
          disabled={!selectedBuilderCategory}
        />
        <Select
          placeholder="AI 모델 선택"
          options={aiModelComboData}
          value={aiSelect}
          onChange={(value) => {
            handleChangedAiModel(value);
          }}
          disabled={!selectedBuilder}
        ></Select>
        <Select
          options={[
            { value: '', label: '프롬프트 언어 선택' },
            { value: 'EN', label: '영문 프롬프트' },
            { value: 'KO', label: '한글 프롬프트' },
          ]}
          defaultValue={langType}
          value={langType}
          onChange={(e) => setLangType(e)}
          disabled={!aiSelect}
        ></Select>
        <Button onClick={handleCreatePrompt}>생성</Button>
      </div>
    );
  };

  const handleCreatePrompt = async () => {
    if (selectedBuilderCategory && selectedBuilder && aiSelect && langType) {
      function executeBuilderSelection() {
        createBuilderInfo.current = { category: selectedBuilderCategory, builder: selectedBuilder, aiModel: aiSelect, langType: langType };
        setIsLoading(true);
        setMenuData([]);
        setStepContents([]);
        // 선택된 빌더 정보가 있으면 stepContents 등을 업데이트하여 렌더링에 반영
        fetchBuilderData(selectedBuilderInfo.el_id);
        setExecuteCreatePrompt(true);
        fetchEmphasisOptionsForAiModel();
        selectedLangType.current = langType;
        setSelectStepKey(0);
      }

      let countSelectedPropmt = 0;
      promptLists.forEach((item) => {
        if (item.length > 0) {
          countSelectedPropmt++;
        }
      });

      if (selectedBuilderInfo && countSelectedPropmt > 0) {
        if (await window.confirm('기존 프롬프트 생성을 종료하고\n새로운 프롬프트를 생성하시겠습니까?')) {
          executeBuilderSelection();
        }
      } else {
        executeBuilderSelection();
      }
    } else {
      message.info('카테고리를 모두 선택 후 생성해주세요.');
      return;
    }
  };

  const emptyImage = () => {
    return (
      <div className="builderGuide">
        <h1>
          <span>프롬프트 빌더로 체계화된 AI 콘텐츠 제작을 시작하세요.</span>
        </h1>
        <h2>
          하단의 <b>카테고리를 선택</b>하여
          <br />
          체계화된 프롬프트 구조를 만들어 보세요.
        </h2>
        <div class="flexRowCenter tab">
          {!selectedCommonCategory &&
            commonCategoryList.map((category) => (
              <button key={category.cd} id="pinkBtn2" className={selectedCommonCategory === category.cd ? 'active' : ''} onClick={() => handleCommonCategoryChange(category.cd)}>
                {category.cd_nm}
              </button>
            ))}
        </div>
        <p>
          <img src={promptGuide} alt="빌더사용 가이드" />
        </p>
      </div>
    );
  };

  const handleChangePromptFold = (index) => {
    setPromptFoldList((prev) => {
      const newList = [...prev];
      newList[index] = newList[index] === 'unfold' ? 'fold' : 'unfold';
      return newList;
    });
  };

  return (
    <>
      <article className="flexColCenter" id="builder">
        <div className="flexColCenter">
          <div className="flexRowCenter tab">
            {selectedCommonCategory &&
              commonCategoryList.map((category) => (
                <button key={category.cd} id="pinkBtn2" className={selectedCommonCategory === category.cd ? 'active' : ''} onClick={() => handleCommonCategoryChange(category.cd)}>
                  {category.cd_nm}
                </button>
              ))}
          </div>

          {selectedCommonCategory ? returnSelectTab() : ''}
          <div className="content">
            {selectedCommonCategory ? '' : emptyImage()}
            {width < 700 && (
              <>
                <div className="flexRowBetween mobileTab" id={mobilePromptTab ? 'active' : 'nothing'} onClick={() => setMobilePromptTab(!mobilePromptTab)}>
                  <p>프롬프트</p>
                  <p>{mobilePromptTab ? <UpOutlined /> : <DownOutlined />}</p>
                </div>

                {mobilePromptTab && (
                  <div className="flexColStart right">
                    {promptList.current.map((prompt, index) => (
                      <>
                        <div key={index} className="flexRowStart title">
                          <img src={promptIcon} alt="" />
                          <h1>{prompt.ko_nm}</h1>
                        </div>

                        <div className="textBox flexColCenter">
                          <TextArea
                            value={isEditing[index] ? editText[index] : promptTexts[index]}
                            className="textArea"
                            style={{ resize: `none` }}
                            // onChange={(e) => setEditText(e.target.value)}
                            onChange={(e) => {
                              setEditText((prev) => {
                                const updatedEditText = [...prev]; // 기존 배열 복사
                                updatedEditText[index] = e.target.value; // promptTexts의 값 설정
                                return updatedEditText;
                              });
                            }}
                            autoSize={{ minRows: 1 }} // 최소 1줄부터 자동 크기 조절
                            readOnly={isEditing[index] === 'saved' || !isEditing[index]}
                          />

                          <div className="flexRowBetween">
                            {isEditing[index] === true ? (
                              <>
                                <div className="flexRowCenter"></div>
                                <div className="flexRowCenter">
                                  <button id="darkGrey3" onClick={() => handleSaveEdit(index)}>
                                    <div>
                                      <img src={updateIcon} alt="" />
                                      <img src={updateIcon} className="activeImg" alt="" />
                                    </div>
                                    <p>편집완료</p>
                                  </button>
                                  <button id="darkGrey3" onClick={() => handleCancelEdit(index)}>
                                    <div>
                                      <img src={cancelIcon} alt="" className="delete" />
                                      <img src={cancelIcon} className="delete activeImg" alt="" />
                                    </div>
                                    <p>편집취소</p>
                                  </button>
                                </div>
                              </>
                            ) : isEditing[index] === 'saved' ? (
                              <>
                                <div className="flexRowCenter"></div>
                                <div className="flexRowCenter">
                                  <button id="darkGrey3" onClick={() => handleCancelEdit(index)}>
                                    <div>
                                      <img src={cancelIcon} alt="" />
                                      <img src={cancelIcon} className="activeImg" alt="" />
                                    </div>
                                    <p>편집취소</p>
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flexRowCenter">
                                  <button id="whiteBtn" onClick={() => clearPrompt(prompt.prpt_seq)}>
                                    <div>
                                      <img src={resetIcon} alt="" />
                                      <img src={resetIcon} className="activeImg" alt="" />
                                    </div>
                                    <p>초기화</p>
                                  </button>
                                </div>
                                <div className="flexRowCenter">
                                  <button id="darkGrey3" onClick={() => handleEdit(index)}>
                                    <div>
                                      <img src={updateIcon} alt="" />
                                      <img src={updateIcon} className="activeImg" alt="" />
                                    </div>
                                    <p>편집</p>
                                  </button>
                                  <button id="darkGrey3" onClick={() => handleCopyToClipboard(promptTexts[index])}>
                                    <div>
                                      <img src={copyIcon} alt="" />
                                      <img src={copyIcon} className="activeImg" alt="" />
                                    </div>
                                    <p>복사</p>
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {/* btn-fold default ,  btn-fold 삭제 fold 시 내용박스 안보이게*/}
                        <h2>
                          프롬프트 조정창<button className="btn-fold"></button>
                        </h2>

                        <div className="selectBox flexColCenter">
                          <DragAndDropList
                            list={promptLists[index]}
                            setList={(newList) => {
                              const updatedLists = [...promptLists];
                              updatedLists[index] = newList;
                              setPromptLists(updatedLists);
                            }}
                            handleDeleteList={(seq) => handleDeletePromptList(seq, prompt.prpt_seq)}
                            emphasisOptions={emphasisOptions}
                            handleEmphasisChange={(itemIndex, value) => handleEmphasisChange(index, itemIndex, value)}
                            handleEmphasisInputChange={(itemIndex, value) => handleEmphasisInputChange(index, itemIndex, value)}
                            disabled={isEditing[index] === true || isEditing[index] === 'saved'}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                )}
                <div className="flexRowBetween mobileTab" id={mobileSelectTab ? 'active' : 'nothing'} onClick={() => setMobileSelectTab(!mobileSelectTab)}>
                  <p>선택창</p>
                  <p>{mobileSelectTab ? <UpOutlined /> : <DownOutlined />}</p>
                </div>
              </>
            )}

            {mobileSelectTab && (
              <>
                <div className="layout flexColCenter">
                  {executeCreatePropmt && (
                    <Layout>
                      <Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: `space-between`,
                          flexDirection: `row`,
                          background: `#ffffff`,
                          padding: `0 0 2px`,
                        }}
                      >
                        <div className="menuBox flexRowBetween">
                          <Menu
                            className="headerMenu create-prompt-menu"
                            mode="horizontal"
                            selectedKeys={[String(selectStepKey)]}
                            items={menuData}
                            overflowedIndicator={false}
                            disabledOverflow={true}
                            onSelect={(e) => {
                              setSelectStepKey(e.key);
                            }}
                          />
                        </div>
                      </Header>
                      {stepContents[selectStepKey]?.children}
                    </Layout>
                  )}
                </div>
              </>
            )}

            <div className="flexColStart right">
              {width < 700 ? (
                <>
                  {selectedBuilderInfo ? (
                    <div className="flexColCenter save">
                      <div className="flexRowBetween">
                        <p>
                          작성한 프롬프트를 Ai모델에서
                          <br />
                          테스트 해보시고 저장하세요
                        </p>

                        <button id="logoutBtn">
                          <p>바로가기</p>
                          <img src={arrowRight} alt="" />
                        </button>
                      </div>
                      <div className="flexColCenter">
                        <button id="purpleBtn">
                          <CheckOutlined />
                          &nbsp;&nbsp;저장
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {promptList.current.map((prompt, index) => (
                    <>
                      <div key={index} className="flexRowStart title">
                        <img src={promptIcon} alt="" />
                        <h1>{prompt.ko_nm}</h1>
                      </div>

                      <div className="textBox flexColCenter">
                        <TextArea
                          value={isEditing[index] ? editText[index] : promptTexts[index]}
                          className="textArea"
                          style={{ resize: `none` }}
                          // onChange={(e) => setEditText(e.target.value)}
                          onChange={(e) => {
                            setEditText((prev) => {
                              const updatedEditText = [...prev]; // 기존 배열 복사
                              updatedEditText[index] = e.target.value; // promptTexts의 값 설정
                              return updatedEditText;
                            });
                          }}
                          autoSize={{ minRows: 1 }} // 최소 1줄부터 자동 크기 조절
                          readOnly={isEditing[index] === 'saved' || !isEditing[index]}
                        />

                        <div className="flexRowBetween">
                          {isEditing[index] === true ? (
                            <>
                              <div className="flexRowCenter"></div>
                              <div className="flexRowCenter">
                                <button id="darkGrey3" onClick={() => handleSaveEdit(index)}>
                                  <div>
                                    <img src={updateIcon} alt="" />
                                    <img src={updateIcon} alt="" className="activeImg" />
                                  </div>
                                  <p>편집완료</p>
                                </button>
                                <button id="darkGrey3" onClick={() => handleCancelEdit(index)}>
                                  <div>
                                    <img src={updateIcon} alt="" />
                                    <img src={updateIcon} alt="" className="activeImg" />
                                  </div>
                                  <p>편집취소</p>
                                </button>
                              </div>
                            </>
                          ) : isEditing[index] === 'saved' ? (
                            <>
                              <div className="flexRowCenter"></div>
                              <div className="flexRowCenter">
                                <button id="darkGrey3" onClick={() => handleCancelEdit(index)}>
                                  <div>
                                    <img src={updateIcon} alt="" />
                                    <img src={updateIcon} alt="" className="activeImg" />
                                  </div>
                                  <p>편집취소</p>
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flexRowCenter">
                                <button id="whiteBtn" onClick={() => clearPrompt(prompt.prpt_seq)}>
                                  <img src={resetIcon} alt="" className="activeImg" />
                                  <p>초기화</p>
                                </button>
                              </div>
                              <div className="flexRowCenter">
                                <button id="darkGrey3" onClick={() => handleEdit(index)}>
                                  <div>
                                    <img src={updateIcon} alt="" />
                                    <img src={updateIcon} alt="" className="activeImg" />
                                  </div>
                                  <p>편집</p>
                                </button>
                                <button id="darkGrey3" onClick={() => handleCopyToClipboard(promptTexts[index])}>
                                  <div>
                                    <img src={copyIcon} alt="" />
                                    <img src={copyIcon} alt="" className="activeImg" />
                                  </div>
                                  <p>복사</p>
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <h2>
                        프롬프트 선택 조정창<button className={promptFoldList[index] === 'fold' ? 'btn-fold' : ''} onClick={() => handleChangePromptFold(index)}></button>
                      </h2>

                      {promptFoldList[index] === 'unfold' ? (
                        <div className="selectBox flexColCenter">
                          <DragAndDropList
                            list={promptLists[index]}
                            setList={(newList) => {
                              const updatedLists = [...promptLists];
                              updatedLists[index] = newList;
                              setPromptLists(updatedLists);
                            }}
                            handleDeleteList={(seq) => handleDeletePromptList(seq, prompt.prpt_seq)}
                            emphasisOptions={emphasisOptions}
                            handleEmphasisChange={(itemIndex, value) => handleEmphasisChange(index, itemIndex, value)}
                            handleEmphasisInputChange={(itemIndex, value) => handleEmphasisInputChange(index, itemIndex, value)}
                            disabled={isEditing[index] === true || isEditing[index] === 'saved'}
                          />
                        </div>
                      ) : null}
                    </>
                  ))}

                  {executeCreatePropmt ? (
                    <div className="flexRowBetween save">
                      <div className="flexRowCenter">
                        <p>
                          작성한 프롬프트를 Ai모델에서
                          <br />
                          테스트 해보시고 저장하세요
                        </p>

                        <button id="logoutBtn">
                          <p>바로가기</p>
                          <div>
                            <img src={arrowRight} alt="" className="activeImg" />
                          </div>
                        </button>
                      </div>
                      <div className="flexRowCenter">
                        <button id="purpleBtn" onClick={() => setIsSaveModalOpen(true)}>
                          <CheckOutlined />
                          &nbsp;저장
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </article>

      <Modal title="프롬프트 저장" open={isSaveModalOpen} onOk={handleSave} onCancel={() => setIsSaveModalOpen(false)} okText='저장' cancelText='취소'>
        <div className='promptSaveGuide'>
          <h1>혹시 모든 Step을 확인하셨나요?</h1>
          <p>"Step별 가이드"를 따라 최적의</p>
          <p>프롬프트를 만들어보세요.</p>
        </div>
        <Title level={5}>제목</Title>
        <Input placeholder='제목을 입력해주세요.' value={saveTitle} onChange={(e) => setSaveTitle(e.target.value)} />
      </Modal>
      <Modal title="축하드립니다." open={isCongratuModalOpen} onCancel={() => setIsCongratuModalOpen(false)} closable={false} onOk={handleMoveToPromptManage}>
        <Title level={5}>처음으로 프롬프트를 저장하셨군요.</Title>
        <Title level={5}>프롬프트 결과물과 함께 관리해보세요.</Title>
      </Modal>
      <Modal open={isLoading} footer={null} closable={false} maskClosable={false}>
        <Spin />
      </Modal>
    </>
  );
};

export default CreatePrompt;
