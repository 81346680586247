import React, { useState, useRef, useEffect } from 'react'
import { Modal, Form, Input, Checkbox, Button, Row, Col, Flex, message } from 'antd'
import TermsAndConditions from './TermsAndConditions';
import { getLocalStorage } from '@utils/lib';

const terms = [
  { id: 'terms1', title: '전자금융거래 기본약관 (필수)'},
  { id: 'terms2', title: '개인정보 수집 및 이용안내 (필수)'},
  { id: 'terms3', title: '개인정보 제공 및 위탁안내 (필수)'},
  { id: 'terms4', title: '자동결제 약관 (필수)'},
]

const RegisterForAutomaticPayment = (props) => {

  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memTel = getLocalStorage('prptbk-token', 'mem_tel') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';
  const memNick = getLocalStorage('prptbk-token', 'mem_nick') || '';

  const { visible, onClose } = props;

  const [form] = Form.useForm();

  const [isTerms, setIsTerms] = useState(false);
  const [termsId, setTermsId] = useState('');

  const [cardNumber, setCardNumber] = useState(["", "", "", ""]);
  const cardRefs = [useRef(), useRef(), useRef(), useRef()];
  const [expiryNumber, setExpiryNumber] = useState(["", ""]);
  const expiryRefs = [useRef(), useRef()];
  const [password, setPassword] = useState('');
  const passwordRef = useRef();
  const [secretNumber, setSecretNumber] = useState('');
  const secretNumberRef = useRef();

  // 카드번호 입력 핸들러 (4자리 입력 후 다음 필드로 이동)
  const handleCardInput = (value, index) => {
    let numericValue = value.replace(/\D/g, ""); // 숫자만 허용
    if (numericValue.length > 4) numericValue = numericValue.slice(0, 4); // 4자리 제한

    const newCardNumber = [...cardNumber];
    newCardNumber[index] = numericValue;
    setCardNumber(newCardNumber);

    if (numericValue.length === 4 && index < 3) {
      cardRefs[index + 1].current.focus();
    }else if(numericValue.length === 4 && index === 3){
      expiryRefs[0].current.focus();
    }
  };

  // 유효기간 입력 핸들러 (2자리 입력 후 다음 필드로 이동)
  const handleExpiryInput = (value, index) => {
    let numericValue = value.replace(/\D/g, ""); // 숫자만 허용
    if (numericValue.length > 2) numericValue = numericValue.slice(0, 2); // 4자리 제한

    const newExpiryNumber = [...expiryNumber];
    newExpiryNumber[index] = numericValue;
    setExpiryNumber(newExpiryNumber);

    if (numericValue.length === 2 && index < 1) {
      expiryRefs[index + 1].current.focus();
    }else if(numericValue.length === 2 && index === 1){
      passwordRef.current.focus();
    }
  }

  // 카드 비밀번호 입력 핸들러 (2자리 입력 후 다음 필드로 이동)
  const handlePasswordInput = (value) => {
    let numericValue = value.replace(/\D/g, ""); // 숫자만 허용
    if (numericValue.length > 2) numericValue = numericValue.slice(0, 2); // 4자리 제한
    setPassword(numericValue);

    if(numericValue.length === 2){
      secretNumberRef.current.focus();
    }
  }


  // 주민등록번호 입력 핸들러 
  const handleSecretNumberInput = (value) => {
    let numericValue = value.replace(/\D/g, ""); // 숫자만 허용
    if (numericValue.length > 6) numericValue = numericValue.slice(0, 6); // 6자리 제한
    setSecretNumber(numericValue);
  }


  const onCloseTerm = () => {
    setIsTerms(!isTerms)
  }

  const changedTerms = (termsId) => {
    setIsTerms(!isTerms)
    setTermsId(termsId);
  }

  const registerAutoPayment = () => {
    const formData = form.getFieldsValue();
    const cardTotalNumber = cardNumber[0] + cardNumber[1] + cardNumber[2] + cardNumber[3];
    const expiryTotalNumber = expiryNumber[1] + expiryNumber[0];
    if(cardTotalNumber.length !== 16){
      message.error('카드번호를 정확히 입력해주세요.');
      return false;
    }else if(!memTel){
      message.error('내 프로필에서 연락처 등록 후 결제 가능합니다.');
      return false;
    }
    const params = {
      mid: 'mid',//상점 ID
      moid: 'moid', //주문번호
      arsUseYn: 'N',
      arsConnType: '02',
      buyerHp: memTel,
      buyerName: formData.mem_name,
      userId: memKey,
      cardNum: cardTotalNumber,
      cardExpire: expiryTotalNumber,
      cardPwd: formData.password,
      idNum: formData.secretNumber
    }
  }

  const handleSave = async () => {
    try{
      await form.validateFields();
    }catch(errorInfo){
      message.error("필수 입력 항목을 입력해주세요.");
      return false;
    }
    if(await window.confirm('결제하시겠습니까?')){
      registerAutoPayment();
    }
  }

  useEffect(() => {
    setCardNumber(['','','',''])
    setExpiryNumber(['',''])
    setPassword('')
    setSecretNumber('')
    form.resetFields();
  }, [visible, form])

  return (
    <>
      <Modal
        open={visible}
        width={`800px`}
        footer={null}
        onCancel={() => {
          onClose();
        }}
      >
        <Form
          form={form}
          onFinish={handleSave}
        >
          <h3>이용약관 동의</h3>
          {terms.map((term) => (
            <Row align='middle' key={term.id}>
              <Col span={16}>{term.title}</Col>
              <Col span={8}>
              <Flex>
                <button type="button" onClick={() => changedTerms(term.id)}>상세보기</button>
                <Form.Item name={term.id} valuePropName='checked' style={{marginBottom:1}} rules={[{ required: true, message: '필수 동의 항목입니다.' }]}>
                  <Checkbox/>
                </Form.Item>
                </Flex>
              </Col>
            </Row>
          ))}
          <br/>
          <h3>등록정보 입력</h3>

          <Form.Item label='등록자명' name='mem_name' rules={[{ required: true, message: '필수 입력 항목' }]}>
            <Input maxLength={10}/>
          </Form.Item>

          <Form.Item label="신용카드번호" name='cardNumber' rules={[{ required: true, message: '필수 입력 항목' }]}>
            <Row gutter={8}>
              {cardNumber.map((num, index) => (
                <Col span={6} key={index}>
                  {index < 2 ? (
                    <Input
                      ref={cardRefs[index]}
                      maxLength={4}
                      value={num}
                      onChange={(e) => handleCardInput(e.target.value, index)}
                      inputMode="numeric"
                      placeholder={"1234"}
                    />
                  ) : (
                    <Input.Password
                      ref={cardRefs[index]}
                      maxLength={4}
                      value={num}
                      onChange={(e) => handleCardInput(e.target.value, index)}
                      inputMode="numeric"
                      placeholder={"●●●●"}
                    />
                  )}
                </Col>
              ))}
            </Row>
          </Form.Item>

          <Form.Item label="카드유효기간" name='expiryNumber' rules={[{ required: true, message: '필수 입력 항목' }]}>
            <Row gutter={8}>
              {expiryNumber.map((num, index) => (
                <Col span={6} key={index}>
                  <Input
                    ref={expiryRefs[index]}
                    maxLength={2}
                    value={num}
                    onChange={(e) => handleExpiryInput(e.target.value, index)}
                    inputMode="numeric"
                    placeholder={index === 0 ? 'MM' : 'YY'}
                  />
                </Col>
              ))}
            </Row>
          </Form.Item>

          <Form.Item label='카드비밀번호' name='password' rules={[{ required: true, message: '필수 입력 항목' }]}>
            <Row gutter={8}>
              <Col span={6}>
                <Input.Password
                  ref={passwordRef}
                  placeholder='앞2자리'
                  maxLength={2}
                  value={password}
                  onChange={(e) => handlePasswordInput(e.target.value)}
                />
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label='주민등록번호' name='secretNumber' rules={[{ required: true, message: '필수 입력 항목' }]}>
            <Row gutter={8}>
              <Col span={6}>
                <Input
                  ref={secretNumberRef}
                  placeholder='앞6자리'
                  maxLength={6}
                  value={secretNumber}
                  onChange={(e) => handleSecretNumberInput(e.target.value)}
                />
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>
              등록
            </Button>
          </Form.Item>
        </Form>

      </Modal>
      <TermsAndConditions
        visible={isTerms}
        onClose={onCloseTerm}
        termsId={termsId}
      />
    </>
  )
}

export default RegisterForAutomaticPayment