import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProfileHome from '@pages/profile/ProfileHome';
import ProfileDetail from '@pages/profile/ProfileDetail';
import PlanInfo from '@pages/profile/PlanInfo';
import NoticeSetting from '@pages/profile/NoticeSetting';
import Ranking from '@pages/profile/Ranking';
import BlockedUsers from '@pages/profile/BlockedUser';
import Tabs from '@components/Tabs';
import { getLocalStorage, isAdmin } from '@utils/lib';
import { message } from 'antd';

const ProfilePage = () => {
  const location = useLocation();
  const memKey = getLocalStorage('prptbk-token', 'mem_key');
  const memAuth = getLocalStorage('prptbk-token', 'mem_auth');
  const [activeKey, setActiveKey] = useState('home');
  const [isTabChanged, setIsTabChanged] = useState(true); // 탭 변경을 막기 위한 상태

  const tabs = [
    { id: 'home', label: '홈', component: <ProfileHome overrideData={location.state} /> },
    { id: 'profileDetail', label: '내 프로필', component: <ProfileDetail overrideData={location.state} /> },
    { id: 'planInfo', label: '플랜 정보', component: <PlanInfo /> },
    { id: 'noticeSetting', label: '알림 설정', component: <NoticeSetting /> },
    { id: 'ranking', label: '랭킹', component: <Ranking overrideData={location.state} /> },
    { id: 'paymentHistory', label: '결제내역', component: null },
  ];

  const filteredTabs = () => {
    // 내가 내 프로필을 볼때: 탭 전체
    if (location.state?.mem_key === memKey) return tabs;
    // 내가 관리자이고 다른 사람의 프로필을 볼때: '홈', '내 프로필', '랭킹'
    else if (isAdmin(memAuth)) return tabs.filter((tab) => ['home', 'profileDetail', 'ranking'].includes(tab.id));
    // 내 프로필도 아니고, 내가 관리자도 아닐때: '홈', '랭킹'
    else return tabs.filter((tab) => ['home', 'ranking'].includes(tab.id));
  };

  const handleTabClick = (key) => {
    if (key === 'paymentHistory') {
      message.info('개발 중입니다.');
      setIsTabChanged(false);
    } else {
      setIsTabChanged(true);
    }

    if (isTabChanged) {
      setActiveKey(key);
    }
  };

  useEffect(() => {
    if (location.state?.planInfo === 'true') {
      setActiveKey('planInfo');
    } else if (location.state?.profileDetail === 'true') {
      setActiveKey('profileDetail');
    } else if (location.state?.ranking === 'true') {
      setActiveKey('ranking');
    } else {
      setActiveKey('home');
    }
  }, [location.state?.planInfo, location.state?.ranking, location.state?.profileDetail]);

  return <Tabs articleId="myPage" tabs={filteredTabs()} initialTab={activeKey} onTabClick={handleTabClick} />;
};

export default ProfilePage;
