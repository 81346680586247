import React, { useState, useCallback, useEffect } from 'react';
import { message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
import { PlusOutlined } from '@ant-design/icons';
// component
import CardSquareMusic2 from '@pages/main/CardSquareMusic2';
// lib
import { useDebounce, makeSelectBoxList, validateSession, getLocalStorage } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const ListGenreMusic = ({ openMusicPlayer }) => {
  const navigate = useNavigate();
  const [genreList, setGenreList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 공통코드 음악 장르 조회
  const makeGenreList = async () => {
    try {
      const response = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'GENRE', parent_cd: 'CATE003' });
      const madeSelectBoxList = makeSelectBoxList(response?.data, 'cd_nm', 'cd');
      setGenreList(madeSelectBoxList);
      setSelectedGenre(madeSelectBoxList[0]?.value);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 장르 음악 리스트 가져오기
  const listMusic = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE003',
        keyword_tab: '',
        filter_genre: selectedGenre,
        filter_stat: '30',
        offset: 0,
        limit: 14,
      });
      if (!data) return;
      setMusicList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedGenre]);

  // 음악 플레이어 열기
  const onOpenMusicPlayer = async (musics = []) => {
    try {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        // 플레이리스트에 곡 추가
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
            prpt_title: music?.prpt_title,
            prpt_path: music?.music_org_link,
          };
        });
        await api.addPlaylistMusic(requestMusics);
        openMusicPlayer();
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    if (!selectedGenre) handleDebounce(() => makeGenreList());
    if (selectedGenre) handleDebounce(() => listMusic());
  }, [selectedGenre]);

  return (
    <article id="gerneWrapper" className="flexColCenter">
      <div className="rsWrapper flexColCenter">
        <div className="titleBox flexRowBetween">
          <div className="flexRowCenter title">
            <img src={musicIcon3} alt="music_icon" />
            <p>장르</p>
          </div>

          <div className="flexRowCenter">
            <div className="tabBox flexRowCenter">
              {genreList?.map((genre) => (
                <div key={genre?.value} className="tab flexColCenter" id={selectedGenre === genre?.value ? 'active' : ''} onClick={() => setSelectedGenre(genre?.value)}>
                  {genre?.label}
                </div>
              ))}
            </div>
            <div id="whiteBtn" className="mobile" onClick={() => moveToPage(PATH.MUSIC_HOME, { filter_genre: selectedGenre })}>
              <p>
                <PlusOutlined />
              </p>
            </div>
          </div>

          <div id="whiteBtn" className="web" onClick={() => moveToPage(PATH.MUSIC_HOME, { filter_genre: selectedGenre })}>
            더보기
          </div>
        </div>

        {isLoading ? (
          <>
            <Skeleton avatar active />
            <Skeleton avatar active />
          </>
        ) : (
          <div className="flexRowStart listBox">
            {musicList?.map((music) => (
              <CardSquareMusic2
                data={music}
                openMusicPlayer={onOpenMusicPlayer}
                moveToPromptDetailPage={() => moveToDetailPage(music)}
                moveToUserDetailPage={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: music?.mem_email, mem_key: music?.mem_key })}
              />
            ))}
          </div>
        )}
      </div>
    </article>
  );
};

export default ListGenreMusic;
