import React from 'react';
import { Modal, Input } from 'antd';

const LyricsInfo = ({ isOpen, onClose, data }) => {
  return (
    <Modal className="lyricsInfo" title="" open={isOpen} maskClosable={false} mask={true} closable={false} footer={null}>
      <div className="wrapInfo">
        <h1>{data?.prpt_title}</h1>
      </div>
      <div className="wrapInfo">
        <h1>곡 소개</h1>
        <Input.TextArea value={data?.prpt_desc || '없음'} autoSize disabled />
      </div>
      <div className="wrapInfo">
        <h1>가사</h1>
        <Input.TextArea value={data?.prpt_lyrics || '없음'} autoSize disabled />
      </div>
      <div className="flexRowCenter bottomContent">
        <button id="pinkBtn" onClick={onClose}>
          닫기
        </button>
      </div>
    </Modal>
  );
};

export default LyricsInfo;
