import React from 'react';
// icon
import playIcon2 from '@assets/images/common/play_icon2.png';
import * as lib from '@utils/lib';

const CardSquareMusic2 = ({ data, isActive, openMusicPlayer, moveToPromptDetailPage, moveToUserDetailPage }) => {
  return (
    <div className="gerneBox" key={data?.prpt_id} id={isActive ? 'active' : ''}>
      <div className="flexColBetween absolBox" onClick={moveToPromptDetailPage}>
        <div className="flexColBetween">
          <div className="flexRowStart user">
            <img src={data?.icon_path} />
            {data?.ai_model_nm && <p>{data?.ai_model_nm}</p>}
          </div>

          <div className="play flexColCenter">
            <img
              src={playIcon2}
              alt="playIcon"
              onClick={(e) => {
                e.stopPropagation();
                openMusicPlayer([data]);
              }}
            />
          </div>

          <div className="flexColStart title">
            <h1>{data?.prpt_title}</h1>
            <div
              className="flexRowStart"
              onClick={(e) => {
                e.stopPropagation();
                moveToUserDetailPage();
              }}
            >
              <img src={data?.mem_img_path} />
              <p>{data?.mem_nick}</p>
            </div>
          </div>
        </div>
      </div>
      <img
        src={lib.getDefaultImg('music', data)}
        // onError={addDefaultImg}
      />
    </div>
  );
};

export default CardSquareMusic2;
