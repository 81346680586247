// 공통
import logo from "@assets/images/chat/icon_logo.png";
import defaultProfile from '@assets/images/common/defaultProfileSVG.svg';

import activeSirenIcon from '@assets/images/common/active_siren_icon.png';
import sirenIcon from '@assets/images/common/siren_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';

// 채팅
import trashIcon from "@assets/images/chat/trash_black_icon.png";
import imageIcon from "@assets/images/chat/image_icon.png";
import imageIcon2 from "@assets/images/chat/image_icon2.png";
import arrowDown from "@assets/images/chat/arrow_down_icon.png";
import markIcon from "@assets/images/chat/mark_icon.png";
import videoIcon from "@assets/images/chat/video_icon.png";
import textIcon from "@assets/images/chat/text_icon.png";
import manageIcon from "@assets/images/chat/icon_manage.png";

export const IMAGES = {
    // Default
    DEFAULT_PROFILE: defaultProfile,

    // 신고
    ACTIVE_SIREN_ICON: activeSirenIcon,
    SIREN_ICON: sirenIcon,
    RESET_ICON: resetIcon,

    // 채팅
    TRASH_ICON: trashIcon,
    IMAGES_ICON: imageIcon,
    IMAGES_ICON2: imageIcon2,
    ARROWS_DOWN_ICON: arrowDown,
    MARK_ICON: markIcon,
    VIDEO_ICON: videoIcon,
    TEXT_ICON: textIcon,
    MANAGER_ICON: manageIcon,

    // LOGO
    LOGO_ICON: logo,
}