import React from 'react'
import {Modal} from 'antd';

const SalesTerms = (props) => {

  const { visible, onClose } = props;
  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        width={`80%`}
      >
        <h1>마켓플레이스 판매자 이용약관</h1>
        <h1>내용 추가 예정</h1>
      </Modal>
    </>
  )
}

export default SalesTerms