import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { message, Skeleton, Checkbox } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
// icon
import cartIcon from '@assets/images/common/cart_icon.png';
import cartUpdateIcon from '@assets/images/common/cart_update_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
// component
import Banner from '@components/slider/Banner';
import Filter from '@components/FilterSubMain';
import FilterMobile from '@components/FilterMobileSubMain';
import SubFilterSubMain from '@components/SubFilterSubMain';
import SearchSubMain from '@components/SearchSubMain';
import ButtonGroupSubMain from '@components/ButtonGroupSubMain';
// lib
import { koKRFormat, useDebounce, getDefaultImg, validateSession, getLocalStorage } from '@utils/lib';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';
// const
import { subMainSortList, AUTH_LOGIN } from '@utils/constants';
// redux
import { setQuery, setMarketplaceImageList, setLocationKey, setYScrollPosition, INIT_STATE_MARKETPLACE_IMAGELIST, clearMarketplaceImageList } from '@stores/marketplaceImageList';

const ImageHome = (props) => {
  const { key: locationKey } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [isActiveFilterMobile, setIsActiveFilterMobile] = useState(false);
  const [aiModelList, setAiModelList] = useState(); // AI 모델 리스트
  const [genreList, setGenreList] = useState(); // 장르 리스트
  const [subGenreList, setSubGenreList] = useState(); // 서브장르 리스트
  // 체크된 이미지 리스트
  const [checkedImageList, setCheckedImageList] = useState([]);
  // 체크된 이미지 리스트 총 갯수
  const [totalCheckedImageList, setTotalCheckedImageList] = useState(0);
  const dispatch = useDispatch();
  const marketplaceImageList = useSelector((s) => s.marketplaceImageList, shallowEqual);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';

  // PC용 필터 토글
  const filterToggle = useCallback(() => {
    setIsActiveFilter(!isActiveFilter);
  }, [isActiveFilter]);

  // 모바일용 필터 토글
  const filterToggleMobile = useCallback(() => {
    setIsActiveFilterMobile(!isActiveFilterMobile);
  }, [isActiveFilterMobile]);

  // 선택한 필터 변경
  const changeFilterParams = useCallback(
    (key, value) => {
      // key 값 유효성 체크
      if (!['filter_sort', 'filter_model', 'filter_genre', 'filter_sub_genre', 'filter_text', 'staff_pick'].includes(key)) return;

      // 선택한 필터 값 변경
      const newFilterParams = { ...marketplaceImageList.query, offset: 0 };
      newFilterParams[key] = value;
      // filter_genre 값이 바뀌면, filter_sub_genre 초기화
      if (key === 'filter_genre') newFilterParams['filter_sub_genre'] = 'all';
      dispatch(setQuery(newFilterParams));
      handleDebounce(() => listImage(newFilterParams));
    },
    [marketplaceImageList.query],
  );

  // 체크 여부
  const isChecked = (image) => {
    const existed = checkedImageList?.filter((checkedImage) => checkedImage?.prpt_id === image?.prpt_id);
    return existed.length > 0;
  };

  // 체크 박스 한개 토글
  const onCheckOne = (checked, image) => {
    const existed = checkedImageList?.filter((checkedImage) => checkedImage?.prpt_id === image?.prpt_id);
    if (checked && !existed.length) {
      setCheckedImageList([...checkedImageList, image]);
      setTotalCheckedImageList(totalCheckedImageList + 1);
    }
    if (!checked && existed.length) {
      const removed = checkedImageList?.filter((checkedImage) => checkedImage?.prpt_id !== image?.prpt_id);
      setCheckedImageList(removed);
      setTotalCheckedImageList(totalCheckedImageList - 1);
    }
  };

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (image, pageY) => {
    try {
      // 해당 프롬프트의 스크롤 위치 저장
      dispatch(setYScrollPosition(pageY));
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: image?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: image?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // AI 모델 리스트 조회
  const listAiModel = async () => {
    try {
      const { data } = await api.getAiModelByCate({ cate_cd: 'CATE001' });
      setAiModelList([{ ai_model_cd: 'all', ai_model_nm: '전체' }, ...data]);
    } catch (error) {
      console.error('AI 모델 리스트 조회 error', error);
    }
  };

  // 장르 리스트 조회
  const listGenre = async () => {
    try {
      const { data } = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'GENRE', parent_cd: 'CATE001' });
      setGenreList([{ cd: 'all', cd_nm: '전체' }, ...data]);
    } catch (error) {
      console.error('장르 리스트 조회 error', error);
    }
  };

  // 서브 장르 리스트 조회
  const listSubGenre = useCallback(async () => {
    try {
      if (marketplaceImageList.query?.filter_genre === 'all') return setSubGenreList();
      const { data } = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'SUB_GENRE', parent_cd: marketplaceImageList.query?.filter_genre });
      setSubGenreList([{ cd: 'all', cd_nm: '모두보기' }, ...data]);
    } catch (error) {
      console.error('서브 장르 리스트 조회 error', error);
    }
  }, [marketplaceImageList.query?.filter_genre]);

  // 장바구니 곡 추가
  const addCartImage = useCallback(async (images = []) => {
    try {
      if (!images.length) return message.warning('이미지를 선택해주세요.');
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        const requestImages = images?.map((image) => {
          return {
            mem_key: memKey,
            gds_key: image?.prpt_id,
            gds_nm: image?.prpt_title,
            gds_amt: image?.sale_amt,
            gds_url: image?.thum_path,
            gds_div: 'GDS003',
          };
        });
        const { data } = await api.addToCart(requestImages);
        if (data?.returnStatus !== 'success') throw new Error(data.returnMessage);
        setCheckedImageList([]);
        setTotalCheckedImageList(0);
        message.success('장바구니에 저장되었습니다.');
      }
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 이미지 리스트 가져오기
  const listImage = useCallback(
    async (query) => {
      try {
        const mergedQuery = { ...marketplaceImageList.query, ...query, filter_stat: '30' };
        const { data } = await api.getAllPromptList(mergedQuery);
        if (!data) return;
        if (query.offset) {
          dispatch(
            setMarketplaceImageList({
              items: [...marketplaceImageList.items, ...data.items],
              totalCount: data.total,
              currentCount: marketplaceImageList.currentCount + data.items.length,
            }),
          );
        } else {
          dispatch(
            setMarketplaceImageList({
              items: data.items,
              totalCount: data.total,
              currentCount: data.items.length,
            }),
          );
        }
        setCheckedImageList([]);
        setTotalCheckedImageList(0);
        dispatch(setLocationKey(locationKey));
      } catch (error) {
        message.warning(error.message);
      }
    },
    [marketplaceImageList],
  );

  // 스크롤 페이징
  const handleScroll = () => {
    const newQuery = { ...marketplaceImageList.query, offset: marketplaceImageList.query.offset + marketplaceImageList.query.limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listImage(newQuery));
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearMarketplaceImageList());
    navigate(PATH.IMAGE_HOME, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === marketplaceImageList.locationKey) {
      window.scrollTo({ top: marketplaceImageList.yScrollPosition, behavior: 'smooth' });
    } else {
      const newQuery = { ...INIT_STATE_MARKETPLACE_IMAGELIST.query, ...location?.state };
      dispatch(setQuery(newQuery));
      handleDebounce(() => listImage(newQuery));
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, []);

  useEffect(() => {
    listAiModel();
    listGenre();
  }, []);

  useEffect(() => {
    listSubGenre();
  }, [marketplaceImageList.query?.filter_genre]);

  return (
    <article id="otherWrapper" className="flexColCenter">
      <Banner bannerCd="BANNER009" bannerTp="Top" />

      <Filter isActive={isActiveFilter} toggle={filterToggle} filterParams={marketplaceImageList.query} setFilterParams={changeFilterParams} aiModelList={aiModelList} genreList={genreList} />

      <FilterMobile
        headerTitle="어떤 이미지를 찾고 계신가요?"
        isActive={isActiveFilterMobile}
        toggle={filterToggleMobile}
        filterParams={marketplaceImageList.query}
        setFilterParams={changeFilterParams}
        aiModelList={aiModelList}
        genreList={genreList}
      />

      <div className="rsWrapper flexColCenter">
        <div className="flexRowBetween">
          <div className="content flexColCenter">
            <SearchSubMain
              defaultTitle="이미지 리스트"
              placeholderSearch="이미지를 검색해 주세요."
              subMainSortList={subMainSortList}
              aiModelList={aiModelList}
              genreList={genreList}
              subGenreList={subGenreList}
              marketplaceList={marketplaceImageList}
              setFilterParams={changeFilterParams}
              filterToggleMobile={filterToggleMobile}
            />

            <div className="searchBox flexRowBetween">
              <SubFilterSubMain sortList={subMainSortList} subGenreList={subGenreList} marketplaceList={marketplaceImageList} setFilterParams={changeFilterParams} />

              <ButtonGroupSubMain isSaleButton={true} onSale={() => moveToPage(PATH.SALES_PROMPT_HOME)} isCartButton={true} onCart={() => addCartImage(checkedImageList)} />
            </div>

            <InfiniteScroll
              dataLength={marketplaceImageList.currentCount}
              next={handleScroll}
              hasMore={marketplaceImageList.currentCount < marketplaceImageList.totalCount}
              style={{ width: '100%' }}
              loader={
                <>
                  <Skeleton active paragraph={{ rows: 4 }} />
                  <Skeleton active paragraph={{ rows: 4 }} />
                </>
              }
            >
              <div className="listBox flexRowStart">
                {marketplaceImageList.items?.map((image, index) => (
                  <div key={index} className="list flexColCenter">
                    <div className="flexColBetween absoluteBox" onClick={(e) => moveToDetailPage(image, e.clientY)}>
                      <div className="flexRowStart user">
                        <div onClick={(e) => e.stopPropagation()}>
                          <Checkbox className="check" checked={isChecked(image)} onChange={(e) => onCheckOne(e.target.checked, image)} />
                        </div>
                        {image?.ai_model_nm && <p>{image?.ai_model_nm}</p>}
                      </div>

                      <div className="flexColEnd">
                        <div className="flexRowBetween title">
                          <h1>{image.prpt_title}</h1>
                          <p>{`₩${koKRFormat(image?.sale_amt)}`}</p>
                        </div>
                      </div>
                    </div>
                    <img
                      src={getDefaultImg('image', image)}
                      // onError={({ currentTarget }) => {
                      //   currentTarget.onError = null;
                      //   image.thum_path = '';
                      //   currentTarget.src = getDefaultImg('image', image);
                      // }}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>

      <div id="mobileFixedBox" className="flexRowBetween">
        <div className="flexColCenter select">
          <p>
            <span>{totalCheckedImageList}</span>개 선택
          </p>
        </div>
        <div className="flexRowCenter">
          <img src={resetIcon} onClick={() => window.location.reload()} />
          <img src={cartUpdateIcon} onClick={() => moveToPage(PATH.SALES_PROMPT_HOME)} />
          <img src={cartIcon} onClick={() => addCartImage(checkedImageList)} />
        </div>
      </div>

      <Banner bannerCd="BANNER010" bannerTp="Bottom" />
    </article>
  );
};

export default ImageHome;
