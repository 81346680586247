import React from 'react'
import { Modal } from 'antd';

const SignUpAgreeTerms = (props) => {

  const { visible, onClose, agreeCode} = props;
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={`80%`}
    >
      <h1>회원가입 약관</h1>
      <h1>{agreeCode}</h1>
    </Modal>
  )
}

export default SignUpAgreeTerms