import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
// icon
import cartIcon from '@assets/images/common/cart_icon.png';
import messageIcon from '@assets/images/common/message_icon.png';
import ringIcon from '@assets/images/common/ring_icon.png';
import closeIcon from '@assets/images/common/close_icon.png';
import noteIcon from '@assets/images/profile/note_icon.png';
import membershipIcon from '@assets/images/common/membership_icon.png';
import markIcon from '@assets/images/common/mark_icon.png';
// active
import logoutIcon from '@assets/images/common/logout_icon.png';
// path
import * as PATH from '@routes/pathName';
// lib
import {formatDate, validateSession, getLocalStorage, koKRFormat, handleImageError, useCheckIcons, isAdmin} from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// redux
import { clearAuth } from '@stores/auth';
import * as api from '@api/index';
import {triggerIconRefresh} from "@stores/icon";

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const defaultProfileSVG = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='40' fill='%23ccc'/%3E%3Ccircle cx='50' cy='40' r='15' fill='%23fff'/%3E%3Cpath d='M30 75c5-15 35-15 40 0' stroke='%23fff' stroke-width='4' fill='none'/%3E%3C/svg%3E";

const MySimpleMenu = ({ userFlag, userToggle, cartOpen }) => {
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState();

  // 선택한 메뉴로 이동
  const moveToPage = async (path, tabId = null) => {
    function nextMove() {
      userToggle();

      if (tabId) {
        localStorage.setItem('selectedChatTab', tabId);
      }

      if (PATH.PROMPT_HOME == path) {
        if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: false }) != true) return false;
      }

      if (PATH.PROFILE_PAGE == path || PATH.CHAT_HOME == path || PATH.INQUIRY_HOME == path) {
        if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true }) != true) return false;
      }

      navigate(path, { state: { mem_email: memEmail, mem_key: memKey, selectedTab: tabId } });
    }

    if (pathname === '/prompt/create') {
      let useYn = localStorage.getItem('prptUsed');
      if (useYn == 'Y') {
        if (await window.confirm('현재 작업중인 빌더가 있을 수 있습니다.\n이동하시겠습니까?')) {
            localStorage.removeItem('prptUsed');
            nextMove();
        }
      } else {
        nextMove();
      }
    } else {
      nextMove();
    }
  };

  const moveToPageWithActiveKey = async (path) => {
    function nextMove() {
      userToggle();
      navigate(path, { state: { mem_email: memEmail, mem_key: memKey, planInfo: 'true' } });
    }

    if (pathname === '/prompt/create') {
      let useYn = localStorage.getItem('prptUsed');
      if (useYn == 'Y') {
            if (await window.confirm('현재 작업중인 빌더가 있을 수 있습니다.\n이동하시겠습니까?')) {
                localStorage.removeItem('prptUsed');
                nextMove();
            }else {
                nextMove();
            }
      }


    } else {
      nextMove();
    }
  };

  const logout = () => {
    window.confirm('로그아웃 하시겠습니까?').then(async (result) => {
      if (result) {
        userToggle();
        try {
          const { data } = await api.logout();
          console.log(data);
        } catch (error) {
          console.log(error.message);
        }

        await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, '{}'), dispatch(clearAuth())]);
        navigate(PATH.LOGIN);
      } else return;
    });
  };

  const getProfile = async () => {
    try {
      const params = { mem_key: memKey };
      const response = await api.userProfile({ params });

      if (response?.data) setUserProfile(response?.data);
      else setUserProfile(null);
    } catch (error) {
      console.error('Error getProfile:', error);
      setUserProfile(null);
    }
  };

  useEffect(() => {
    if (userFlag) getProfile();
  }, [userFlag]);

  const { checkMarket, checkMsg, checkAlert, iconCount } = useCheckIcons();
  const shouldRefresh = useSelector((s) => s.shouldRefresh, shallowEqual);

  useEffect(() => {
    iconCount(memKey);
    dispatch(triggerIconRefresh(false));
  }, [shouldRefresh]);

  return (
    <div className="userWrapper">
      {validateSession({ authType: AUTH_LOGIN }) ? (
        <>
          <div className="mobileMyMenuHeader flexRowBetween">
            <div className="mobileIconBox flexRowCenter">
              {validateSession({ authType: AUTH_LOGIN }) && (
                <>
                  <div onClick={cartOpen}>
                    {checkMarket ? (<div className="round"></div>) : null}
                    <img src={cartIcon} alt="cart_icon" />
                  </div>
                  <div onClick={() => moveToPage(PATH.CHAT_HOME, 'userChat')}>
                    {checkMsg ? (<div className="round"></div>) : null}
                    <img src={messageIcon} alt="message_icon" />
                  </div>
                  <div onClick={() => moveToPage(PATH.CHAT_HOME, 'systemChat')}>
                    {checkAlert ? (<div className="round"></div>) : null}
                    <img src={ringIcon} alt="ring_icon" />
                  </div>
                </>
              )}
            </div>
            <img src={closeIcon} alt="close_icon" onClick={userToggle} />
          </div>

          <div className="userInfo flexRowStart">
            <div className="userThumb flexColCenter">
              <img src={userProfile?.mem_img_path || defaultProfileSVG} alt="userProfile"
                   onError={(e) => handleImageError(e, null, defaultProfileSVG)}
              />
            </div>

            <div className="flexColStart">
              <h1>{userProfile?.mem_nick}</h1>
              <p>{`${formatDate(userProfile?.fir_dt)}`}</p>
            </div>
          </div>

          {/* <div className="pointBox flexRowBetween">
            <div className="flexRowStart">
              <img src={noteIcon} />
              <p>#{auth.agg_rank ? auth.agg_rank.split(',')[2] : 'N/A'}</p>
            </div>

            <div className="flexRowCenter">
              <img src={membershipIcon} alt="membetship_icon" />
              <img src={markIcon} alt="mark_icon" />
            </div>
          </div> */}

          <div className="tabBox flexRowBetween">
            <div className="tab flexColCenter">{userProfile?.plan_nm}</div>
            <div className="tab active flexColCenter" onClick={() => moveToPageWithActiveKey(PATH.PROFILE_PAGE)}>
              업그레이드
            </div>
          </div>

          <div className="followingBox flexRowBetween">
            <div className="flexColCenter">
              <h1>{koKRFormat(userProfile?.following_cnt)}</h1>
              <p>팔로잉 수</p>
            </div>
            <div className="flexColCenter">
              <h1>{koKRFormat(userProfile?.follower_cnt)}</h1>
              <p>팔로워 수</p>
            </div>
          </div>

          <div className="flexColCenter menuBox">
            <div className="menu flexRowStart" onClick={() => moveToPage(PATH.PROFILE_PAGE)}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5 5.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zm-1 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0zM4.75 10A1.75 1.75 0 0 0 3 11.75V12c0 1.197.761 2.209 1.843 2.896C5.93 15.588 7.4 16 9 16c1.599 0 3.07-.412 4.158-1.104C14.238 14.208 15 13.197 15 12v-.25A1.75 1.75 0 0 0 13.25 10h-8.5zM4 11.75a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 .75.75V12c0 .736-.47 1.475-1.38 2.053-.903.575-2.181.947-3.62.947s-2.717-.372-3.62-.947C4.47 13.475 4 12.736 4 12v-.25z"
                  fill="#6D6C75"
                  stroke="#6D6C75"
                  stroke-width=".5"
                />
              </svg>
              <p>마이페이지</p>
            </div>
            <div className="menu flexRowStart fill" onClick={() => moveToPage(PATH.PROMPT_HOME)}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.886 3.343a1.543 1.543 0 1 1-3.086 0 1.543 1.543 0 0 1 3.086 0zm11.314 0a1.543 1.543 0 1 1-3.085 0 1.543 1.543 0 0 1 3.085 0zM4.886 14.657a1.543 1.543 0 1 1-3.087 0 1.543 1.543 0 0 1 3.087 0zm11.314 0a1.543 1.543 0 1 1-3.086 0 1.543 1.543 0 0 1 3.086 0zM5.914 3.6a.771.771 0 0 1 .772-.771h4.628a.772.772 0 0 1 0 1.542H6.686a.771.771 0 0 1-.772-.771zm0 10.8a.771.771 0 0 1 .772-.771h4.628a.771.771 0 0 1 0 1.543H6.686a.771.771 0 0 1-.772-.772zM2.828 6.686a.771.771 0 1 1 1.543 0v4.628a.772.772 0 0 1-1.543 0V6.686zm10.8 0a.772.772 0 0 1 1.543 0v4.628a.771.771 0 1 1-1.543 0V6.686z"
                  fill="#6D6C75"
                />
              </svg>
              <p>프롬프트 빌더</p>
            </div>
            <div className="menu flexRowStart fill" onClick={() => moveToPage(PATH.MY_MARKET_HOME)}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.667 6.213a.654.654 0 0 0 0-.126l-1.333-3.334a.667.667 0 0 0-.667-.42H4.333a.667.667 0 0 0-.62.42L2.38 6.087a.667.667 0 0 0 0 .126.4.4 0 0 0-.046.12A2.667 2.667 0 0 0 3.667 8.6V15a.667.667 0 0 0 .667.667h9.333a.667.667 0 0 0 .667-.667V8.627a2.667 2.667 0 0 0 1.333-2.294.396.396 0 0 0 0-.12zm-6 8.12H8.334v-2.666h1.333v2.666zm3.333 0h-2V11a.667.667 0 0 0-.666-.667H7.667A.667.667 0 0 0 7 11v3.333H5V9a2.667 2.667 0 0 0 2-.92 2.667 2.667 0 0 0 4 0A2.666 2.666 0 0 0 13 9v5.333zm0-6.666a1.333 1.333 0 0 1-1.333-1.334.667.667 0 1 0-1.333 0 1.333 1.333 0 0 1-2.667 0 .667.667 0 0 0-1.333 0 1.334 1.334 0 0 1-2.667.1l1.12-2.766h8.427l1.12 2.766A1.333 1.333 0 0 1 13 7.667z"
                  fill="#6D6C75"
                />
              </svg>
              <p>내 마켓플레이스</p>
            </div>
            <div className="menu flexRowStart" onClick={() => moveToPage(PATH.INQUIRY_HOME)}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.043 15.187a.45.45 0 0 1-.193-.149.368.368 0 0 1-.072-.216V7.551c0-.316.14-.62.392-.847a1.5 1.5 0 0 1 .958-.37h7.919c.37 0 .726.128.988.356.262.228.41.538.41.86v4.87c0 .323-.148.632-.41.86a1.509 1.509 0 0 1-.988.357H5.735L3.51 15.143m-.466.044.141-.365h-.407l.372-.001h.036l.014-.037.024-.063a.03.03 0 0 0 .017 0l.038-.026m-.235.492a.528.528 0 0 0 .466-.044m-.466.044.152-.365h.083v-.021l.231.342m-.231-.448V7.551c0-.164.072-.336.227-.476a.999.999 0 0 1 .631-.242m-.858 7.862 2.177-1.472.127-.086h6.465c.258 0 .495-.09.66-.233a.645.645 0 0 0 .237-.484V7.55a.645.645 0 0 0-.237-.483 1.01 1.01 0 0 0-.66-.234h-7.91m-.86 7.862v.088l.232.36m.627-8.31h-.008v-.5l.015.5h-.007zm-.858 7.987-.002-.022-.032-.047-.007-.01-.005-.008-.017.04-.02.048h.083zm-.024-.076a.085.085 0 0 0-.008-.01l.007.011.014.022a.116.116 0 0 0-.013-.023z"
                  stroke="#6D6C75"
                />
                <path
                  d="M15.178 12.964V5a1.333 1.333 0 0 0-1.29-1.333m1.29 9.297h-.425l.034-.043-.11-.085m.5.128a.445.445 0 0 1-.253.4m.254-.4h-.426l-.003.005.003.006.172.39m-.246-.529V5a.834.834 0 0 0-.798-.833m.798 8.67-2.016-1.565-.135-.105H6.333a.833.833 0 0 1-.833-.834V5a.833.833 0 0 1 .833-.833h7.547m.798 8.67v.127h.056l.002.005-.256.347m-.6-9.15h.009v-.5m-.01.5a.61.61 0 0 0-.007 0l.017-.5m0 0H6.333A1.333 1.333 0 0 0 5 5v5.333a1.333 1.333 0 0 0 1.333 1.334h6.023l2.124 1.649m0 0 .261-.337v.002l.183.383m-.444-.048a.444.444 0 0 0 .444.048m-.147-.45-.033.044-.022-.05a.056.056 0 0 1 .055.006zm-.068 0 .024.05h-.055a.055.055 0 0 1 .031-.05z"
                  fill="#fff"
                  stroke="#6D6C75"
                />
                <path d="M10.334 5.889v3.555" stroke="#6D6C75" stroke-width="1.5" stroke-linecap="round" />
              </svg>
              <p>1 : 1 문의</p>
            </div>
            {isAdmin(userProfile?.mem_auth) ? (
            <div id="logoutBtn" onClick={() => moveToPage(PATH.ADMIN_USER_LIST)}>
                <img src={logoutIcon} />
                    <p>관리자 페이지</p>
                </div>
                ) : null
            }
          </div>

          {/* <div className="asidedataBar">
            <div className="text">
              <span>남은 용량 {userProfile?.avail_data_size}</span>
              <span>
                {userProfile?.used_data_size} / {userProfile?.total_data_size} (MB)
              </span>
            </div>
            <div className="data">
              <div className="uses" style={{ width: `${(userProfile?.avail_data_size * 100) / userProfile?.total_data_size}%` }}></div>
            </div>
          </div> */}
          <div id="logoutBtn" onClick={logout}>
            <img src={logoutIcon} />
            <p>로그아웃</p>
          </div>
        </>
      ) : (
        <div>
          {/* <div id="logoutBtn" onClick={() => moveToPage(PATH.SIGNUP)}>
            <p>회원가입</p>
          </div> */}
          <div id="logoutBtn" onClick={() => moveToPage(PATH.LOGIN)}>
            <p>로그인</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MySimpleMenu;
