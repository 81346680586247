import React, { useRef } from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import thumbnail from '@assets/images/profile/thumb.png';
import userThumb from '@assets/images/profile/user_thumb.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '@assets/css/populerPromptSlider.css';
import { useNavigate } from 'react-router-dom';
import { PROMPT_DETAIL } from '@routes/pathName';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrowRight flexColCenter" onClick={onClick}>
      <p>
        <RightOutlined />
      </p>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <div className="arrowLeft flexColCenter" onClick={onClick}>
      <p>
        <LeftOutlined />
      </p>
    </div>
  );
};

const PromptSlider = ({ title, titleImg, prompts, loop }) => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 6,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          centerPadding: '150px',
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: '0',
          centerMode: false,
        },
      },
    ],
  };

  if (!Array.isArray(prompts)) {
    return null;
  }

  const renderStars = (score) => {
    const totalStars = 3; // 별의 총 개수를 3으로 설정
    const validScore = typeof score === 'number' && score >= 0 && score <= 3 ? score : 0; // 유효한 점수 체크
    const filledStars = Math.floor(validScore); // 채워진 별의 개수
    const halfStar = validScore % 1 !== 0; // 반별 체크

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {[...Array(filledStars)].map((_, index) => (
          <span key={`filled-${index}`} style={{ color: '#ffa514', fontSize: '16px' }}>
            ★
          </span>
        ))}
        {halfStar && <span style={{ color: '#ffa514', fontSize: '16px', clipPath: 'inset(0 50% 0 0)' }}>★</span>}
        {[...Array(totalStars - filledStars - (halfStar ? 1 : 0))].map((_, index) => (
          <span key={`empty-${index}`} style={{ color: 'gray', fontSize: '16px' }}>
            ☆
          </span>
        ))}
      </div>
    );
  };

  const moveToPromptDetail = (promptId) => {
    navigate(PROMPT_DETAIL, { state: { prpt_id: promptId } });
  };

  return (
    <>
      <div className="profileTitle">
        {titleImg && (
          <div className=" ">
            <img src={titleImg} />
            <p>{title}</p>
            <button>
              <RightOutlined />
            </button>
          </div>
        )}
      </div>
      {title !== '인기 프롬프트' ? (
        <div className="flexRowStart box profileList">
          {!Array.isArray(prompts) || prompts.length === 0 ? (
            <div
              className="noResult"
              // style={{
              //   display: 'flex',
              //   justifyContent: 'center',
              //   alignItems: 'center',
              //   height: '250px',
              //   fontSize: '30px',
              //   color: '#555',
              //   textAlign: 'center',
              //   width: '100%',
              // }}
            >
              <p>등록된 프롬프트가 없습니다.</p>
            </div>
          ) : (
            <>
              {prompts.map((prompt) => (
                <Card
                  onClick={() => moveToPromptDetail(prompt?.prpt_id)}
                  style={{
                    border: `none`,
                    width: `16%`,
                    height: `100%`,
                  }}
                  bodyStyle={{
                    padding: `0`,
                    boxShadow: `none`,
                    transition: `0s`,
                  }}
                >
                  <div className="list flexColCenter">
                    <div className="flexColCenter">
                      <img src={prompt?.icon_path || thumbnail} alt={prompt?.title} />
                      <div className="flexColBetween absoluteBox">
                        <div className="flexRowBetween user">
                          <div className="flexRowCenter">
                            <img src={prompt?.mem_img_path || userThumb} className="w-6 h-6 rounded-full" />
                            <p>{prompt?.mem_nick}</p>
                          </div>
                        </div>
                        <div className="flexColStart title">
                          <p>{prompt?.prpt_title}</p>
                          <div className="greyBox flexRowBetween">
                            <img src={prompt?.mem_img_path || userThumb} />
                            <div className="flexColStart">
                              <p>{prompt?.mem_nick}</p>
                              <div className="flexRowBetween">
                                <div className="flexRowStart imgBox">
                                  {/* <img src={prompt?.mem_img_path || userThumb} /> */}
                                  {renderStars(prompt?.score_avg)}
                                </div>
                                <p>₩{prompt?.sale_amt}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </>
          )}
        </div>
      ) : (
        <div className="slider-container" id="populerPromptSlider">
          {!Array.isArray(prompts) || prompts.length === 0 ? (
            <div class="noResult">
              <p>등록된 프롬프트가 없습니다.</p>
            </div>
          ) : (
            <Slider ref={sliderRef} {...settings} style={{ marginBottom: '20px' }}>
              {prompts.map((prompt) => (
                <Card
                  key={prompt?.prpt_id}
                  onClick={() => moveToPromptDetail(prompt?.prpt_id)}
                  style={{
                    border: `none`,
                    width: `100%`,
                    height: `100%`,
                  }}
                  bodyStyle={{
                    padding: `0`,
                    boxShadow: `none`,
                    transition: `0s`,
                  }}
                >
                  <div className="list flexColCenter">
                    <div className="flexColCenter">
                      <img src={prompt?.icon_path || thumbnail} alt={prompt?.title} />
                      <div className="flexColBetween absoluteBox">
                        <div className="flexRowBetween user">
                          <div className="flexRowCenter">
                            <img src={prompt?.mem_img_path || userThumb} className="w-6 h-6 rounded-full" />
                            <p>{prompt?.mem_nick}</p>
                          </div>
                        </div>
                        <div className="flexColStart title">
                          <p>{prompt?.prpt_title}</p>
                          <div className="greyBox flexRowBetween">
                            <img src={prompt?.mem_img_path || userThumb} />
                            <div className="flexColStart">
                              <p>{prompt?.mem_nick}</p>
                              <div className="flexRowBetween">
                                <div className="flexRowStart imgBox">
                                  <img src={prompt?.mem_img_path || userThumb} />
                                  {renderStars(prompt?.score_avg)}
                                </div>
                                <p>₩{prompt?.sale_amt}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </Slider>
          )}
        </div>
      )}
    </>
  );
};

export default PromptSlider;
