import React from 'react';

const ButtonGroupSubMain = ({ isShareButton = false, onShare, isListenButton = false, onListen, isKeepButton = false, onKeep, isSaleButton = false, onSale, isCartButton = false, onCart }) => {
  return (
    <div className="flexRowCenter buttonGroup">
      {isShareButton && (
        <button id="pinkBtn" className="icon sale" onClick={onShare}>
          <p>내음악등록</p>
        </button>
      )}

      {isListenButton && (
        <button id="whiteBtn" className="icon listen" onClick={onListen}>
          <p>듣기</p>
        </button>
      )}

      {isKeepButton && (
        <button id="whiteBtn" className="icon keep" onClick={onKeep}>
          <p>보관함</p>
        </button>
      )}
      {isSaleButton && (
        <button id="pinkBtn" className="icon sale" onClick={onSale}>
          <p>판매하기</p>
        </button>
      )}

      {isCartButton && (
        <button id="whiteBtn" className="icon put" onClick={onCart}>
          <p>담기</p>
        </button>
      )}
    </div>
  );
};

export default ButtonGroupSubMain;
