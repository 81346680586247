import React from 'react';
// icon
import playIcon2 from '@assets/images/common/play_icon2.png';
// lib
import { koKRFormat, getDefaultImg } from '@utils/lib';
// component
import Rate from '@components/Rate';

const CardSquareImage3 = ({ data, moveToPromptDetailPage, openPlayer, moveToUserDetailPage }) => {
  // 카테고리 이름 가져오기
  const getCateNm = { CATE001: 'image', CATE002: 'video', CATE004: 'text' }[data?.cate_cd] || 'image';

  return (
    <div className="staffBox " key={data?.prpt_id} id={data?.cate_cd === 'CATE002' ? 'playerBg' : ''}>
      <div className="absoluteBox " onClick={moveToPromptDetailPage}>
        <div>
          <div className="thumbnail" style={{ backgroundImage: `url(${getDefaultImg(getCateNm, data)})` }}>
            <div className="user">{data?.ai_model_nm && <p>{data?.ai_model_nm}</p>}</div>

            <h2 className="">{data?.prpt_title}</h2>
          </div>
        </div>

        {data?.cate_cd === 'CATE002' && (
          <>
            <div className="hover-bg"></div>
            <div className="play flexColCenter">
              <img
                src={playIcon2}
                alt="playIcon"
                onClick={(e) => {
                  e.stopPropagation();
                  openPlayer(data);
                }}
              />
            </div>
          </>
        )}

        <div className="title">
          <div
            className="greyBox flexRowStart"
            onClick={(e) => {
              e.stopPropagation();
              moveToUserDetailPage();
            }}
          >
            <img src={data?.mem_img_path} />

            <div className="flexColStart">
              <div className="flexRowBetween">
                <div className="imgBox flexRowCenter">
                  <h1>{data?.mem_nick}</h1>
                </div>

                <p>{`₩ ${koKRFormat(data?.sale_amt)}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img
        src={lib.getDefaultImg('music', data)}
        // onError={addDefaultImg}
      /> */}
    </div>
  );
};

export default CardSquareImage3;
