import React, { useState, useCallback, useEffect } from 'react';
import { message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
// icon
import fireIcon from '@assets/images/common/fire_icon.png';
// component
import NewSlider from '@components/slider/NewSlider';
import CardSquareImage1 from '@pages/main/CardSquareImage1';
import DetailImage from '@pages/main/DetailImage';
// lib
import { useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const ListPopularImage = ({ headerTitle, title, cateCd, filterCd }) => {
  const navigate = useNavigate();
  const [promptList, setPromptList] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 더보기 클릭시
  const moveToSubMainPage = () => {
    if (cateCd === 'CATE001') moveToPage(PATH.IMAGE_HOME, { filter_sort: 'popular' });
    if (cateCd === 'CATE002') moveToPage(PATH.VIDEO_HOME, { filter_sort: 'popular' });
    if (cateCd === 'CATE004') moveToPage(PATH.TEXT_HOME, { filter_sort: 'popular' });
  };

  // 프롬프트 리스트 가져오기
  const listPrompt = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: cateCd,
        keyword_tab: filterCd || '',
        filter_stat: '30',
        offset: 0,
        limit: 10,
      });
      if (!data) return;
      setPromptList(data.items);
      setSelectedPrompt(data.items[0]);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => listPrompt());
  }, []);

  return (
    <>
      <h1>{headerTitle}</h1>
      <div className="rsWrapper flexColCenter">
        <div className="titleBox flexRowBetween">
          <div className="flexRowCenter title">
            <img src={fireIcon} alt="fire_icon" />
            <p>{title}</p>
          </div>

          <div id="whiteBtn" className="web" onClick={() => moveToSubMainPage()}>
            더보기
          </div>
        </div>
      </div>

      <div className="contentsSlide flexColCenter">
        <div className="flexRowBetween whiteBox">
          <div className="white"></div>
          <div className="white"></div>
        </div>

        <div className="rsWrapper flexColCenter">
          <div className="slider-container" id="popularSlider">
            {isLoading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <NewSlider
                config={{
                  centerPadding: '200px',
                  slidesToShow: 3,
                  focusOnSelect: true,
                  dots: true,
                  responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 3,
                        centerPadding: '150px',
                      },
                    },
                    {
                      breakpoint: 1280,
                      settings: {
                        slidesToShow: 3,
                        centerPadding: '50px',
                      },
                    },
                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 2,
                        centerPadding: '0',
                        centerMode: false,
                      },
                    },
                  ],
                  afterChange: (index) => {
                    setSelectedPrompt(promptList[index]);
                  },
                }}
                Content={promptList?.map((prompt) => (
                  <CardSquareImage1 data={prompt} />
                ))}
              />
            )}
          </div>
        </div>
      </div>
      <DetailImage selectedPrompt={selectedPrompt} />
    </>
  );
};

export default ListPopularImage;
