import React from 'react'
import { Modal } from 'antd';

const FooterTerms = (props) => {

  const { visible, onClose, code} = props;

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <h1>푸터 약관</h1>
      <h1>{code}</h1>
    </Modal>
  )
}

export default FooterTerms