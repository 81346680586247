import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '@assets/images/common/logo.png';

// icon
import musicIcon from '@assets/images/common/music_icon.png';
import imageIcon from '@assets/images/common/image_icon.png';
import videoIcon from '@assets/images/common/video_icon.png';
import textIcon from '@assets/images/common/text_icon.png';
import promptIcon from '@assets/images/common/prompt_icon.png';
import communityIcon from '@assets/images/common/community_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import messageIcon from '@assets/images/common/message_icon.png';
import ringIcon from '@assets/images/common/ring_icon.png';
import userIcon from '@assets/images/common/user_icon.png';
import activeMusicIcon from '@assets/images/common/active_music_icon.png';
import activeImageIcon from '@assets/images/common/active_image_icon.png';
import activeVideoIcon from '@assets/images/common/active_video_icon.png';
import activeTextIcon from '@assets/images/common/active_text_icon.png';
import activePromptIcon from '@assets/images/common/active_prompt_icon.png';
import activeCommunityIcon from '@assets/images/common/active_community_icon.png';
import menuIcon from '@assets/images/common/menu_icon.png';
// path
import * as PATH from '@routes/pathName';
// lib
import {validateSession, isAdmin, getLocalStorage, handleImageError, useCheckIcons} from '@utils/lib';
// const
import { AUTH_LOGIN, AUTH_BEGINNER } from '@utils/constants';
// component
import CartSimpleList from '@components/CartSimpleList';
import MySimpleMenu from '@components/MySimpleMenu';
import MainMenuMobile from '@components/MainMenuMobile';
// hook
import useWidth from '@hooks/useWidth';
import {triggerIconRefresh} from "@stores/icon";

const Header = (state) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedPath, setSelectedPath] = useState('');
  const [cartFlag, setCartFlag] = useState(false);
  const [menuFlag, setMenuFlag] = useState(false);
  const [userFlag, setUserFlag] = useState(false);
  const dropMenuRef = useRef(null);
  const dropMenuMobileRef = useRef(null);
  const paymentRef = useRef(null);
  const auth = useSelector((s) => s.auth, shallowEqual);
  const width = useWidth();
  const isMobile = width < 900;
  const memImgPath = getLocalStorage('prptbk-token', 'mem_img_path') || '';
  const defaultProfileSVG = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='40' fill='%23ccc'/%3E%3Ccircle cx='50' cy='40' r='15' fill='%23fff'/%3E%3Cpath d='M30 75c5-15 35-15 40 0' stroke='%23fff' stroke-width='4' fill='none'/%3E%3C/svg%3E";
  const { checkMarket, checkMsg, checkAlert, iconCount } = useCheckIcons();
  const shouldRefresh = useSelector((s) => s.shouldRefresh, shallowEqual);

  const cartToggle = useCallback(() => {
    setMenuFlag(false);
    setUserFlag(false);
    setCartFlag(!cartFlag);
  }, [cartFlag]);

  const menuToggle = useCallback(() => {
    setCartFlag(false);
    setUserFlag(false);
    setMenuFlag(!menuFlag);
  }, [menuFlag]);

  const userToggle = useCallback(() => {
    setCartFlag(false);
    setMenuFlag(false);
    setUserFlag(!userFlag);
  }, [userFlag]);

  // 선택한 메뉴로 이동
  const moveToPage = async (path, tabId = null) => {
    function nextMove() {
      setCartFlag(false);
      setMenuFlag(false);
      setUserFlag(false);

      if (tabId) {
        localStorage.setItem('selectedChatTab', tabId);
      }

      if (PATH.PROMPT_HOME == path) {
        if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true }) != true) return false;
      }
      navigate(path, { state: { selectedTab: tabId } });
    }

    if (pathname === '/prompt/create') {
      let useYn = localStorage.getItem('prptUsed');
      if (useYn == 'Y') {
        if (await window.confirm('현재 작업중인 빌더가 있을 수 있습니다.\n이동하시겠습니까?')) {
            localStorage.removeItem('prptUsed');
            nextMove();
        }
      } else {
            nextMove();
      }

    } else {
      nextMove();
    }
  };

  // 현재 path가 일치하는지 확인
  const isMatchedPath = useCallback(
    (path) => {
      const requestPath = path.split('/').filter(Boolean)[0];
      return selectedPath === requestPath ? 'selected' : '';
    },
    [selectedPath],
  );

  // 현재 path 설정
  useEffect(() => {
    const currentPath = pathname.split('/').filter(Boolean)[0];
    setSelectedPath(currentPath);
    dispatch(triggerIconRefresh(true));   // 페이지 이동시 icon refresh store
  }, [pathname]);

  // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
  useEffect(() => {
    const handleOutsideClose = (e) => {
      if ((cartFlag || userFlag || menuFlag) && !dropMenuRef.current.contains(e.target) && !dropMenuMobileRef.current.contains(e.target)) {
        if (paymentRef.current) {
          if (!paymentRef.current.contains(e.target)) {
            setCartFlag(false);
            setUserFlag(false);
            setMenuFlag(false);
          }
        } else {
          setCartFlag(false);
          setUserFlag(false);
          setMenuFlag(false);
        }
      }
    };
    document.addEventListener('click', handleOutsideClose);
    return () => document.removeEventListener('click', handleOutsideClose);
  }, [cartFlag, userFlag, menuFlag]);

  useEffect(() => {
    const memKey = auth?.mem_key;
    iconCount(memKey);
    dispatch(triggerIconRefresh(false));
  }, [shouldRefresh]);

  useEffect(() => {
    const memKey = auth?.mem_key;
    if (memKey) {
      iconCount(memKey);
      const interval = setInterval(() => iconCount(memKey), 1800000);
      return () => clearInterval(interval);
    }
  }, [auth?.mem_key]);


  return (
    <header id="header" className="flexColCenter">
      {/* 여기서부터 PC */}
      <article className="rsWrapper flexRowBetween" ref={dropMenuRef}>
        <img src={menuIcon} alt="menu_icon" className="menuIcon" onClick={menuToggle} />

        <button>
          <img src={logo} alt="logo" className="logo" onClick={() => moveToPage(PATH.MAIN)} />
        </button>

        <div className="menuWrapper flexRowCenter">
          <div className={`menu flexRowCenter ${isMatchedPath(PATH.MUSIC_HOME)}`} onClick={() => moveToPage(PATH.MUSIC_HOME)}>
            <img src={activeMusicIcon} alt="icon" className="hoverIcon" />
            <img src={musicIcon} alt="icon" />
            <p>Music</p>
          </div>
          <div className={`menu flexRowCenter ${isMatchedPath(PATH.IMAGE_HOME)}`} onClick={() => moveToPage(PATH.IMAGE_HOME)}>
            <img src={activeImageIcon} alt="icon" className="hoverIcon" />
            <img src={imageIcon} alt="icon" />
            <p>Image</p>
          </div>
          <div className={`menu flexRowCenter ${isMatchedPath(PATH.VIDEO_HOME)}`} onClick={() => moveToPage(PATH.VIDEO_HOME)}>
            <img src={activeVideoIcon} alt="icon" className="hoverIcon" />
            <img src={videoIcon} alt="icon" />
            <p>Video</p>
          </div>
          <div className={`menu flexRowCenter ${isMatchedPath(PATH.TEXT_HOME)}`} onClick={() => moveToPage(PATH.TEXT_HOME)}>
            <img src={activeTextIcon} alt="icon" className="hoverIcon" />
            <img src={textIcon} alt="icon" />
            <p>Text</p>
          </div>
          <div className={`menu flexRowCenter ${isMatchedPath(PATH.PROMPT_HOME)}`} onClick={() => moveToPage(PATH.PROMPT_HOME)}>
            <img src={activePromptIcon} alt="icon" className="hoverIcon" />
            <img src={promptIcon} alt="icon" />
            <p>Prompt Builder</p>
          </div>
          <div className={`menu flexRowCenter ${isMatchedPath(PATH.COMMUNITY_HOME)}`} onClick={() => moveToPage(PATH.COMMUNITY_HOME)}>
            <img src={activeCommunityIcon} alt="icon" className="hoverIcon" />
            <img src={communityIcon} alt="icon" />
            <p>Community</p>
          </div>
        </div>

        <div className="iconBox ">
          {validateSession({ authType: AUTH_LOGIN }) ? (
            <>
              {!isMobile && (
                <>
                  <div onClick={cartToggle}>
                    {checkMarket ? (<div className="round"></div>) : null}
                    <img src={cartIcon} alt="cart_icon" />
                  </div>
                  <div onClick={() => moveToPage(PATH.CHAT_HOME, 'userChat')}>
                    {checkMsg ? (<div className="round"></div>) : null}
                    <img src={messageIcon} alt="message_icon" />
                  </div>
                  <div onClick={() => moveToPage(PATH.CHAT_HOME, 'systemChat')}>
                    {checkAlert ? (<div className="round"></div>) : null}
                    <img src={ringIcon} alt="ring_icon" />
                  </div>
                </>
              )}
              <div onClick={userToggle}>
                <img src={memImgPath || defaultProfileSVG} alt="user_icon"
                     onError={(e) => handleImageError(e, null, defaultProfileSVG)}
                />
              </div>
            </>
          ) : (
            <>
              {isMobile ? (
                <div onClick={userToggle}>
                  <img src={userIcon} alt="user_icon" />
                </div>
              ) : (
                <>
                  <div onClick={() => moveToPage(PATH.LOGIN)} className="pre">
                    <img src={userIcon} alt="user_icon" /> 로그인
                  </div>
                  <div onClick={() => moveToPage(PATH.SIGNUP)} className="pre">
                    <p>회원가입</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {cartFlag && <CartSimpleList cartToggle={cartToggle} paymentRef={paymentRef} />}

        {userFlag && <MySimpleMenu userFlag={userFlag} userToggle={userToggle} cartOpen={() => setCartFlag(true)} />}
      </article>
      {/* 여기까지 PC */}

      {/* 여기서부터 Mobile */}
      <article className="mobileMenuBg" id={menuFlag || userFlag ? 'menuBgVisible' : 'nothing'}></article>

      <div ref={dropMenuMobileRef}>
        <article className="mobileMenuWrapper flexColStart" id={menuFlag ? 'menuVisible' : 'nothing'}>
          <MainMenuMobile cartToggle={cartToggle} menuToggle={menuToggle} />
        </article>

        <article className="mobileCart" id={cartFlag ? 'mobileCartActive' : 'nothing'}>
          {cartFlag && <CartSimpleList isMobile={true} cartToggle={cartToggle} />}
        </article>
      </div>
      {/* 여기까지 Mobile */}
    </header>
  );
};

export default Header;
