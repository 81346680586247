import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Flex, Form, Input, InputNumber, message, Radio, Select, Tooltip, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import * as api from '@api/index';
import { DeleteOutlined, InboxOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { PROFILE_PAGE, PROMPT_MANAGEMENT } from '@routes/pathName';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import '@assets/css/form.css';
import useWidth from '@hooks/useWidth';
import randumIcon from '../../assets/images/common/random_icon.png';
import closeIcon from '@assets/images/common/close_icon.png';
import AiModelCertModal from '@components/AiModelCertModal';
import SellerRegist from '@components/SellerRegist';
import { getDefaultImg, getDefaultThum } from '@utils/lib';
import SalesTerms from '@components/SalesTerms';

// 프롬프트 활용 가이드
const remarkPlaceholder =
  '프롬프트를 다양하게 활용할 수 있도록 팁을 제공해주세요.\n\n' +
  '1. 변수 위치 표시\n' +
  '   예시:  [동물] is running on the street in the [시간].\n\n' +
  '2. 변수에 사용할 수 있는 추천 단어 리스트\n' +
  '   예시: [동물] : Cat, Dog, Lion [시간] : Morning, Afternoon, Evening\n\n' +
  '3. 프롬프트 작성 또는 활용 아이디어\n' +
  '   예시: 이 프롬프트는 16:9 비율로 만드는 것이 좋습니다.';

const uploadThumbnailExtension = process.env.REACT_APP_UPLOAD_THUMBNAIL;
const uploadImageExtension = process.env.REACT_APP_UPLOAD_IMAGE;
const uploadMusicExtension = process.env.REACT_APP_UPLOAD_MUSIC;
const uploadVideoExtension = process.env.REACT_APP_UPLOAD_VIDEO;
const uploadTextExtension = process.env.REACT_APP_UPLOAD_TEXT;
const uploadSize = process.env.REACT_APP_UPLOAD_SIZE;
const uploadTotSize = process.env.REACT_APP_UPLOAD_TOT_SIZE;

const SalesPrompt = (props) => {
  const auth = useSelector((s) => s.auth, shallowEqual);
  const navigate = useNavigate();
  const width = useWidth();

  const { state } = useLocation();
  const prpt_id = state?.prpt_id || null;

  const promptInitValue = {
    cate_cd: 'CATE003', // 음악
    sale_div: 'FREE', // 무료
    sale_amt: 0, // 0원
  };

  const [selectedCategory, setSelectedCategory] = useState(promptInitValue.cate_cd); // 선택 카테고리 (초기값 :음악)
  const [selectedAiModel, setSelectedAiModel] = useState(null); // 선택 모델
  const [selectedGenre, setSelectedGenre] = useState(null); // 선택 장르
  const [selectedSubGenre, setSelectedSubGenre] = useState(null); // 선택 서브 장르

  const [certYn, setCertYn] = useState(false); // ai인증여부
  const [memCertYn, setMemCertYn] = useState(false); // 유저 ai 인증
  const [selectedPay, setSelectedPay] = useState(promptInitValue.sale_div); // 유무료 구분

  const [tags, setTags] = useState([]);

  const [inputValue, setInputValue] = useState('');
  const [aiInfoFlag, setAiInfoFlag] = useState(false); // AI 모델 정보 추가 flag
  const [isAiModalVisible, setAiIsModalVisible] = useState(false); // AI 모델 인증 모달 show
  const [isSellerModalVisible, setIsSellerModalVisible] = useState(false); // 판매자인증 모달 show

  const [fileGrpSeq, setFileGrpSeq] = useState(null); // 파일 그룹 번호
  const [thumFile, setThumFile] = useState([]); // 썸네일 파일
  const [attachFile, setAttachFile] = useState([]); // 첨부파일
  const [delFileCaches, setDelFileCaches] = useState([]); // 삭제 첨부 파일

  const [saveType, setSaveType] = useState('01');
  const [form] = Form.useForm();

  const [aiModelList, setAiModelList] = useState([])
  const [infoPlaceholder, setInfoPlaceholder] = useState('')

  const [salesTermsVisible, setSalesTermsVisible] = useState(false);

  // 공통코드
  const [commonCode, setCommonCode] = useState({
    categoryList: [], // 카테고리
    aiModelList: [], // AI모델
    genreList: [], // 장르
    subGenreList: [], // 서브 장르
    samplerList: [], // 샘플러
    saleDivList: [], // 판매구분
  });
  const [filterAiModelList, setFilterAiModelList] = useState([]); // 필터링된 AI모델코드
  const [filterGenreList, setFilterGenreList] = useState([]); // 필터링된 장르코드
  const [filterSubGenreList, setFilterSubGenreList] = useState([]); // 필터링된 서브장르코드

  // 초기화
  const handleInit = () => {
    if (state) {
      // 수정일 경우 뒤로가기
      window.history.back();
      return;
    }

    form.resetFields(); // form 초기화

    setSelectedCategory(promptInitValue.cate_cd);
    setSelectedPay(promptInitValue.sale_div);

    setAiInfoFlag(false);

    setCertYn(false);
    setMemCertYn(false);

    setThumFile([]);
    setAttachFile([]);
    setDelFileCaches([]);
    setFilterSubGenreList([]);
  };

  // 조회
  const getSalesPrompt = useCallback(async (query) => {
    try {
      const { data } = await api.getSalesPrompt(query);

      // 카테고리
      if (data.item.cate_cd) {
        form.setFieldsValue({ cate_cd: data.item.cate_cd });
        handleCategoryChange({ target: { value: data.item.cate_cd } });
      }
      // ai 모델
      if (data.item.ai_model_cd) {
        form.setFieldsValue({ ai_model_cd: data.item.ai_model_cd });
        handleAiModelChange({ target: { value: data.item.ai_model_cd } });
      }
      // 장르코드
      if (data.item.genre_cd) {
        form.setFieldsValue({ genre_cd: data.item.genre_cd });
        handleGenreChange({ target: { value: data.item.genre_cd } });
      }
      // 서브장르코드
      if (data.item.sub_genre_cd) {
        form.setFieldsValue({ sub_genre_cd: data.item.sub_genre_cd });
        handleSubGenreChange({ target: { value: data.item.sub_genre_cd } });
      }
      // form 정보
      form.setFieldsValue(data.item);

      // 태그
      if (data.item.tag) {
        setTags(data.item.tag.split(','));
      }

      // 썸네일
      if (data.item.thum_path) {
        const blob = await (await fetch(data.item.thum_path)).blob();
        const file = new File([blob], 'thumbnail', { type: blob.type });
        file.url = data.item.thum_path;
        file.status = 'done';
        setThumFile([file]);
      }

      // 첨부파일
      if (data.fileList.length > 0) {
        const files = [];

        for (const file of data.fileList) {
          const blob = await (await fetch(file.file_dwn_path)).blob();
          const newFile = new File([blob], file.file_org_nm, { type: blob.type });

          newFile.uid = file.file_seq || file.uid || `${file.file_nm}-${file.file_seq}`;
          newFile.status = 'done';
          newFile.url = file.file_dwn_path;

          files.push(newFile);
        }
        setAttachFile(files);
      }

      // 첨부파일
      setFileGrpSeq(data.item.file_grp_seq); // 파일 그룹 번호
    } catch (error) {
      console.error('조회 error', error);
    }
  }, []);

  // 카테고리 선택 시 상태 업데이트
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedAiModel(null);
    setCertYn(false);
    setMemCertYn(false);
    setTags([]); // 태그

    // 기존 첨부파일 삭제
    const newDelFile = attachFile?.filter((file) => file.status == 'done').map((file) => file.url);
    setDelFileCaches(newDelFile);
    setAttachFile([]);

    form.setFieldsValue({
      ai_model_cd: null, // 모델
      genre_cd: null, // 장르
      sub_genre_cd: null, // 서브장르
    });

    if (e.target.value == 'CATE003') {
      form.setFieldValue('prpt_remark', '');
      form.setFieldValue('test_prpt', '');
      form.setFieldValue('text_prpt', '');
    } else {
      form.setFieldValue('music_org_link', '');
    }
  };

  // ai 모델 변경시
  const handleAiModelChange = (e) => {
    const cert_yn = e.target.certyn == 'Y' ? true : false;
    setCertYn(cert_yn);

    // 인증여부가 Y이고, 유료이면 유저 인증 체크
    if (cert_yn && selectedPay != 'FREE') aiModelCertChk(e.target.value, selectedCategory);
    setSelectedAiModel(e.target.value);
  };

  // 장르 변경시
  const handleGenreChange = (e) => {
    setSelectedGenre(e.target.value);
  };

  // 장르 변경시
  const handleSubGenreChange = (e) => {
    setSelectedSubGenre(e.target.value);
  };

  // 판매구분
  const handleSaleDivChange = (newValue) => {
    setSelectedPay(newValue);
    if (newValue == 'PAID') {
      // 유료인 경우 판매금액은 1000 으로 set
      form.setFieldValue('sale_amt', '1000');
      // 인증여부가 Y이고, 유료이면 유저 인증 체크
      if (certYn) aiModelCertChk(selectedAiModel, selectedCategory);
    } else {
      // 유료인 경우 판매금액은 1000 으로 set
      form.setFieldValue('sale_amt', '0');
    }
  };

  // 유저 인증 체크
  const aiModelCertChk = async (ai_model_cd, cate_cd) => {
    try {
      const { data } = await api.aiModelCertChk({
        ai_model_cd: ai_model_cd,
        mem_key: auth?.mem_key,
      });

      if (data.returnStatus === 'SUCCESS') {
        setMemCertYn(true); // 유저 인증 완료
      } else {
        message.error(data.returnMessage);
        setAiIsModalVisible(true); // 팝업창 오픈
      }
    } catch (error) {
      console.error('error');
    }
  };

  const handleAiInfoFlag = () => {
    setAiInfoFlag(!aiInfoFlag);
    if (!!aiInfoFlag) {
      // aiInfoFlag가 false면 입력값 제거
      form.setFieldsValue({
        prpt_sample: null,
        prpt_lyrics: null,
        prpt_step: null,
        prpt_seed: null,
        clip_gdc: null,
      });
    }
  };

  // 태그
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addTag(inputValue);
    }
  };

  const addTag = (tag) => {
    if (tag && tags.length < 10 && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setInputValue('');
    }
  };

  const handleDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  // 파일 업로드
  const handleFileChange = (newFile, flag, index) => {
    if (newFile.file.size > uploadSize * 1024 * 1024) {
      message.error('각 파일의 크기는 ' + uploadSize + 'MB를 초과할 수 없습니다.');
      return;
    }

    if (flag == 'T') {
      setThumFile([newFile.file]);
    } else {
      setAttachFile((prev) => [...prev, newFile.file]);
    }
  };

  // 파일 삭제 컨트롤
  const handleFileRemove = (e, file, flag) => {
    e.stopPropagation();
    e.preventDefault();

    if (flag == 'T') {
      setThumFile([]);
    } else {
      setAttachFile((prev) => prev.filter((item) => item.uid !== file.uid));
    }

    if (file.status == 'done') setDelFileCaches([...delFileCaches, file.url]);
  };

  // 밸리데이션
  const onFinishFailed = ({ errorFields }) => {
    // 첫 번째 오류 필드로 포커스 이동
    if (errorFields.length > 0) {
      form.scrollToField(errorFields[0].name, {
        behavior: 'smooth', // 스크롤 애니메이션 옵션
        block: 'center',
      });
    }
  };

  // 저장
  const onFinish = async (saveData) => {
    
    // saveData가 undefined 인경우 null로 변경
    const newSaveData = {};
    for (const key in saveData) {
      newSaveData[key] = saveData[key] ?? null;
    }

    // validation
    if (newSaveData.prpt_stat === '10') {
      // 저장시
      // 유료이고 사업자등록번호 확인이 안되었을 때
      if (selectedPay !== 'FREE' && !(await businessNoChk())) {
        setIsSellerModalVisible(true);
        message.error('사업자등록번호를 등록하셔야 판매가 가능합니다.');
        return;
      }
      // 유료이고 인증여부가 true인데 유저가 ai 인증을 완료하지 못했을 때
      if (selectedPay !== 'FREE' && certYn && !memCertYn) {
        message.error('AI모델 인증이 완료되어야 판매가 가능합니다.');
        return;
      }
      // 음악/이미지 첨부필수 (1이상)
      if ((newSaveData.cate_cd === 'CATE001' || newSaveData.cate_cd === 'CATE003') && !attachFile.length > 0) {
        message.error('첨부파일을 하나 이상 업로드 해주세요.');
        return;
      }
      // 음악/이미지/동영상 필수
      if ((newSaveData.cate_cd === 'CATE001' || newSaveData.cate_cd === 'CATE002') && !thumFile.length > 0) {
        message.error('썸네일은 필수값입니다.');
        return;
      }
      if(newSaveData.cate_cd === 'CATE002' && !newSaveData.music_org_link){
        message.error('썸네일 하단의 링크를 입력해주세요.')
        return false;
      }
    }

    if (attachFile.length > 0) {
      // if (!(await dataChk())) {
      //   if (await window.confirm('사용 가능 데이터가 부족합니다. 데이터를 추가하시겠습니까?')) {
      //     navigate(PROFILE_PAGE, {
      //       state: {
      //         mem_email: auth.mem_email,
      //         mem_key: auth.mem_key,
      //       },
      //     });
      //   }
      //   return;
      // }

      const totalSize = attachFile.reduce((acc, file) => acc + file.size, 0);
      if (totalSize > uploadSize * 1024 * 1024) {
        message.error('업로드 파일의 크기는 총' + uploadSize + 'MB를 초과할 수 없습니다.');
      }
    }

    const formData = new FormData();

    // 썸네일
    let savedThum = '';
    if (thumFile.length > 0) {
      const saveThumFile = thumFile?.filter((file) => file.status !== 'done')[0]; // 새로운 썸네일
      formData.append(`thumFile`, saveThumFile);
      savedThum = thumFile?.filter((file) => file.status == 'done')[0]?.url; // 기존썸네일
    } else {
      // 썸네일이 존재하지 않으면 기본 이미지 생성
      savedThum = getDefaultThum(newSaveData.cate_cd);
    }

    // 첨부파일
    attachFile
      ?.filter((file) => file.status !== 'done')
      .forEach((file, index) => {
        formData.append(`attachFiles`, file.originFileObj || file);
      });

    // 삭제 파일
    delFileCaches.forEach((delFile) => {
      console.log(delFile);
      formData.append('delFiles', delFile);
    });

    // 저장데이터
    const saveFormData = {
      ...newSaveData,
      prpt_id: prpt_id,
      sale_agree: newSaveData?.sale_agree ? 'Y' : 'N',
      biz_confirm: newSaveData?.biz_confirm ? 'Y' : 'N',
      tag: tags.join(','),
      mem_key: auth?.mem_key,
      thum_path: savedThum,
      file_grp_seq: fileGrpSeq,
      ...(selectedCategory === 'CATE003' && {
        sale_div: 'FREE',
        sale_amt: 0
      })
    };
    
    formData.append('saveData', new Blob([JSON.stringify(saveFormData)], { type: 'application/json' }));

    try {
      const response = await api.salesPrompt(formData);
      if (response.data.returnStatus == 'success') {
        alert(response.data.returnMessage);
        navigate(PROMPT_MANAGEMENT); // 프롬프트 관리로 이동
      } else if (response.data.returnStatus == 'error') {
        alert('저장에 실패했습니다.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 사업자등록번호 확인
  const businessNoChk = async () => {
    // 사업자등록번호 확인
    const response = await api.businessNoChk({
      mem_key: auth.mem_key,
    });

    return response.data;
  };

  // 데이터 체크 ( 첨부파일만 해당 / 썸네일 제외 )
  const dataChk = async () => {
    const { data } = await api.getMemUsedData({ mem_key: auth.mem_key });

    const total_data_size = auth?.total_data_size; // 총 데이터
    const avl_data = (total_data_size - data) * 1024 * 1024; // 사용가능 데이터
    const save_data = attachFile.reduce((acc, curr) => {
      // 업로드한 데이터
      return acc + curr.size;
    }, 0);

    // 사용가능 데이터 - 업로드 데이터가 0보다 크면 true
    return avl_data - save_data > 0;
  };

  const getCommonCode = async () => {
    try {
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' }); // 카테고리
      const response3 = await api.fetchEqualCommonGroupCode({ grp_cd: 'SAMPLER' }); // 샘플러
      const response4 = await api.fetchEqualCommonGroupCode({ grp_cd: 'SALE_DIV' }); // 판매구분 (무료/유료)

      setCommonCode((prev) => ({
        ...prev,
        categoryList: response2.data,
        samplerList: response3.data,
        saleDivList: response4.data,
      }));
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };
  // 카테고리별 AI모델 조회
  const getCommonCodeByCate = async () => {
    try {
      // AI모델
      const { data } = await api.getAiModelByCate();

      console.log(data);
      setCommonCode((prev) => ({
        ...prev,
        aiModelList: data,
      }));
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };
  const getGenreCode = async () => {
    try {
      const response5 = await api.fetchEqualCommonGroupCode({ grp_cd: 'GENRE' }); // 판매구분 (무료/유료)
      const response6 = await api.fetchEqualCommonGroupCode({ grp_cd: 'SUB_GENRE' }); // 판매구분 (무료/유료)
      setCommonCode((prev) => ({
        ...prev,
        genreList: response5.data,
        subGenreList: response6.data,
      }));
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };

  const getAiModelInfo = async () => {
    try{
      const response = await api.getModelInfo({});
      setAiModelList(response.data)
    }catch(error){
      console.log(error);
    }
  }

  const onSalesTermsClose = () => {
    setSalesTermsVisible(!salesTermsVisible);
  }

  useEffect(() => {
    getCommonCode(); // 공통코드 조회
    getCommonCodeByCate(); // 카테고리별 공통코드 조회
    getGenreCode();
    getAiModelInfo();

    // 수정일 경우 작성항목 조회
    if (state) {
      getSalesPrompt({
        mem_key: state?.mem_key || auth?.mem_key,
        prpt_id: state?.prpt_id,
      });
    }
  }, []);

  useEffect(() => {
    // AI 모델 디폴트 설정
    const defaultFilterAiModelList = commonCode.aiModelList.filter((item) => item.cate_cd == selectedCategory);
    setFilterAiModelList(defaultFilterAiModelList);
    setSelectedAiModel(defaultFilterAiModelList[0]?.ai_model_cd);
    form.setFieldsValue({ ai_model_cd: defaultFilterAiModelList[0]?.ai_model_cd });
    // 장르 디폴트 설정
    const defaultFilterGenreList = commonCode.genreList.filter((item) => item.parent_cd == selectedCategory);
    setFilterGenreList(defaultFilterGenreList);
    setSelectedGenre(defaultFilterGenreList[0]?.cd);
    form.setFieldsValue({ genre_cd: defaultFilterGenreList[0]?.cd });
  }, [commonCode, selectedCategory]);

  useEffect(() => {
    // 서브 장르 디폴트 설정
    const defaultFilterSubGenreList = commonCode.subGenreList.filter((item) => item.parent_cd == selectedGenre);
    setFilterSubGenreList(defaultFilterSubGenreList);
    setSelectedSubGenre(defaultFilterSubGenreList[0]?.cd);
    form.setFieldsValue({ sub_genre_cd: defaultFilterSubGenreList[0]?.cd });
  }, [commonCode, selectedGenre]);

  // AI 결과물 등록 컨포넌트
  const ResultComponent = ({ cateType }) => {
    switch (cateType) {
      case 'CATE001': //이미지
        return (
          <div className="flexColStart box image">
            <div className="flexRowStart info">
              <Upload.Dragger
                accept={uploadThumbnailExtension}
                fileList={thumFile}
                className="flexColCenter plusBox"
                onChange={(file) => handleFileChange(file, 'T', 0)}
                beforeUpload={() => false} // 실제 업로드 방지
                showUploadList={false} // 기본 리스트 표시 비활성화
              >
                {thumFile.length === 0 ? (
                  <div className="flexColCenter">
                    <div className="plus flexColCenter">
                      <PlusOutlined />
                    </div>

                    {width > 900 && (
                      <p>
                        썸네일을 등록하거나 <br /> 드래그앤 드롭해 주세요.
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="flexColCenter">
                    {thumFile.map((file, index) => (
                      <div className="flexColCenter" key={index}>
                        <img src={URL.createObjectURL(file)} className="uploadthum" />
                        <DeleteOutlined onClick={(e) => handleFileRemove(e, file, 'T')} style={{ color: 'red', cursor: 'pointer' }} />
                      </div>
                    ))}
                  </div>
                )}
              </Upload.Dragger>
              {attachFile.map((file, index) => (
                <div className="flexColCenter plusBox" key={index}>
                  <div className="flexColCenter">
                    <img src={URL.createObjectURL(file)} className="uploadimg" />
                    <DeleteOutlined onClick={(e) => handleFileRemove(e, file, 'A')} style={{ color: 'red', cursor: 'pointer' }} />
                  </div>
                </div>
              ))}
              {attachFile.length >= 4 ? null : (
                <Dragger
                  accept={uploadImageExtension}
                  multiple={true}
                  maxCount={4}
                  fileList={attachFile}
                  className="flexColCenter plusBox"
                  onChange={(file) => handleFileChange(file, 'A', 0)}
                  beforeUpload={() => false} // 실제 업로드 방지
                  showUploadList={false} // 기본 리스트 표시 비활성화
                >
                  <div className="flexColCenter">
                    <div className="plus flexColCenter">
                      <PlusOutlined />
                    </div>
                    {width > 900 && (
                      <p>
                        이미지를 등록하거나
                        <br />
                        드래그앤 드롭해 주세요.
                      </p>
                    )}
                  </div>
                </Dragger>
              )}
            </div>
          </div>
        );
        break;
        case 'CATE004':
          return (
            <div className="flexRowStart info">
              <Form.Item name="text_prpt" className="w-100" rules={[{ required: true, message: '텍스트 프롬프트 아웃풋을 입력하세요.' }]}>
                <TextArea placeholder="텍스트 프롬프트 아웃풋을 입력하세요." autoSize={{ minRows: 5, maxRows: 15 }} />
              </Form.Item>
            </div>
          );
      default:
        const acceptExtension = cateType == 'CATE003' ? uploadMusicExtension : 'CATE004' ? uploadTextExtension : uploadVideoExtension;
        return (
          <div className="flexColStart box image">
            <div className="flexRowStart info">
              {(cateType == 'CATE003' || cateType == 'CATE004') && (
                <div className="flexColStart info ">
                  <h1>첨부파일</h1>

                  {attachFile.length === 0 ? (
                    <Upload.Dragger
                      accept={acceptExtension}
                      fileList={attachFile}
                      className="flexColCenter plusBox"
                      onChange={(file) => handleFileChange(file, 'A', 0)}
                      beforeUpload={() => false} // 실제 업로드 방지
                      showUploadList={false} // 기본 리스트 표시 비활성화
                      maxCount={1}
                    >
                      <div className="flexColCenter">
                        <div className="plus flexColCenter">
                          <PlusOutlined />
                        </div>
                        {width > 900 && (
                          <p>
                            파일을 등록하거나 <br /> 드래그앤 드롭해 주세요.
                          </p>
                        )}
                      </div>
                    </Upload.Dragger>
                  ) : (
                    <div className="flexColCenter plusBox">
                      {attachFile.map((file, index) => (
                        <div className="flexColCenter" key={file.uid}>
                          <div className="flexColCenter">
                            <p>
                              {file.name}
                              <DeleteOutlined onClick={(e) => handleFileRemove(e, file, 'A')} style={{ color: 'red', cursor: 'pointer' }} />
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {(cateType == 'CATE002' || cateType == 'CATE003') && (
                <div className="flexColStart info">
                  <h1>썸네일 등록</h1>
                  <Upload.Dragger
                    accept={uploadThumbnailExtension}
                    fileList={thumFile}
                    className="flexColCenter plusBox"
                    onChange={(file) => handleFileChange(file, 'T', 0)}
                    beforeUpload={() => false} // 실제 업로드 방지
                    showUploadList={false} // 기본 리스트 표시 비활성화
                  >
                    {thumFile.length === 0 ? (
                      <div className="flexColCenter">
                        <div className="plus flexColCenter">
                          <PlusOutlined />
                        </div>

                        {width > 900 && (
                          <p>
                            썸네일을 등록하거나 <br /> 드래그앤 드롭해 주세요.
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="flexColCenter">
                        {thumFile.map((file, index) => (
                          <div key={index}>
                            <img src={URL.createObjectURL(file)} className="uploadthum" />
                            <DeleteOutlined onClick={(e) => handleFileRemove(e, file, 'T')} style={{ color: 'red', cursor: 'pointer' }} />
                          </div>
                        ))}
                      </div>
                    )}
                  </Upload.Dragger>
                </div>
              )}
            </div>

            <div className="flexRowStart info">
              {cateType === 'CATE002' || cateType === 'CATE003' ? (
                <Form.Item name="music_org_link" className="w-100" rules={cateType !== 'CATE003' ? [] : [{ required: true, message: '링크를 입력하세요' }]}>
                  <Input placeholder="http://" />
                </Form.Item>
              ) : (
                <Form.Item name="text_prpt" className="w-100">
                  <TextArea placeholder="텍스트 프롬프트 아웃풋을 입력하세요." autoSize={{ minRows: 5, maxRows: 15 }} />
                </Form.Item>
              )}
            </div>
          </div>
        );
        break;
    }
  };

  return (
    <>
      <article className="flexColCenter form" id="promptSales">
        <div className="rsWrapper flexColCenter">
          <Form form={form} initialValues={promptInitValue} onFinish={(values) => onFinish(values, 'formSubmit')} onFinishFailed={onFinishFailed}>
            <Form.Item
              name="sale_agree"
              className="flexRowCenter essential"
              valuePropName="checked"
              rules={
                saveType == '01'
                  ? []
                  : [
                      {
                        validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('미동의시 접수가 불가능합니다.'))),
                      },
                    ]
              }
            >
              <Checkbox>
                (필수) 마켓플레이스 판매자 이용약관 동의
                <button type='button' onClick={() => setSalesTermsVisible(true)}>
                  <span className='blueSpan'>
                    (보기)
                  </span>
                </button>
              </Checkbox>
            </Form.Item>

            <div className="flexRowCenter titleBox">
              <div className="line"></div>
              <div className="flexRowCenter title">
                <img src={randumIcon} />
                <h1>프롬프트 정보</h1>
              </div>
              <div className="line"></div>
            </div>
            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={randumIcon} />
                <h1>구분</h1>
              </div>

              <div className="flexColCenter content">
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <Form.Item name="cate_cd" rules={[{ required: true, message: '카테고리를 선택하세요' }]}>
                      <Radio.Group onChange={handleCategoryChange}>
                        {(commonCode.categoryList || []).map((option, index) => (
                          <Radio key={index} value={option.cd}>
                            {option.cd_nm}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1>AI 모델</h1>
                    <div className="flexRowStart">
                      <Form.Item name="ai_model_cd" normalize={(value) => value ?? null} rules={saveType == '01' ? [] : [{ required: true, message: 'AI모델을 선택하세요' }]}>
                        <Radio.Group onChange={handleAiModelChange}>
                          {filterAiModelList.map((option, index) => (
                            <Radio
                              key={index}
                              value={option.ai_model_cd}
                              certyn={option.cert_yn} // 인증여부
                            >
                              {option.ai_model_nm}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                      {selectedAiModel == 'ETC' && (
                        <Form.Item name="etc_nm" normalize={(value) => value ?? null} rules={saveType == '01' ? [] : [{ required: true, message: '기타 AI모델명을 입력하세요' }]}>
                          <Input placeholder="기타 AI명" style={{ width: '150px' }} disabled={selectedAiModel != 'ETC'} />
                        </Form.Item>
                      )}
                    </div>
                    {certYn && selectedPay != 'FREE' && (
                      // ai 인증을 해야하고, 유료일 때 상업적 이용 확인
                      <>
                        <AiModelCertModal visible={isAiModalVisible} onClose={() => setAiIsModalVisible(false)} />
                        <Form.Item
                          name="biz_confirm"
                          valuePropName="checked"
                          rules={
                            saveType == '01'
                              ? []
                              : [
                                  {
                                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('미동의시 접수가 불가능합니다.'))),
                                  },
                                ]
                          }
                        >
                          <Checkbox
                            style={{
                              fontSize: `14px`,
                            }}
                          >
                            <p>상업적 이용 확인</p>
                          </Checkbox>
                        </Form.Item>
                      </>
                    )}
                  </div>
                </div>
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1>카테고리</h1> {/*장르*/}
                    <div className="flexRowStart">
                      <Form.Item name="genre_cd" rules={saveType == '01' ? [] : [{ required: true, message: '카테고리를 선택하세요.' }]}>
                        <Radio.Group onChange={handleGenreChange}>
                          {filterGenreList.map((option, index) => (
                            <Radio key={index} value={option.cd}>
                              {option.cd_nm}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {filterSubGenreList.length > 0 && (
                  <div className="flexColCenter box">
                    <div className="flexColStart info">
                      <h1>서브 카테고리</h1> {/* 서브 장르*/}
                      <div className="flexRowStart">
                        <Form.Item name="sub_genre_cd" rules={saveType == '01' ? [] : [{ required: true, message: '서브 카테고리를 선택하세요.' }]}>
                          <Radio.Group onChange={handleSubGenreChange}>
                            {filterSubGenreList.map((option, index) => (
                              <Radio key={index} value={option.cd}>
                                {option.cd_nm}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={randumIcon} />
                <h1>AI모델 정보 및 설정</h1>
                <div className="flexColStart">
                  <Flex>
                    <Checkbox onChange={handleAiInfoFlag}/><h4>&nbsp;&nbsp;&nbsp;정보 항목 추가</h4>
                  </Flex>
                  {/* <button id="darkGrey" onClick={handleAiInfoFlag} type="button">
                    <PlusOutlined />
                    정보 항목 추가
                  </button> */}
                </div>
              </div>

              <div className="flexColStart content">
                <div className="flexColCenter box third">
                  <div className="flexColStart info">
                    <h1>버전</h1>
                    <div className="flexRowStart">
                      <Form.Item name="prpt_ver" className="w-100">
                        <Input placeholder="Version" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {aiInfoFlag && (
                  <>
                    <div className="flexRowBetween">
                      <div className="flexColCenter box">
                        <div className="flexColStart info">
                          <h1>정보 항목</h1>
                          <Form.Item name="prpt_sample" className="w-100">
                            <TextArea
                              placeholder={
                                selectedCategory && selectedAiModel ? 
                                aiModelList.find((item => item.ai_model_cd === selectedAiModel && item.cate_cd === selectedCategory))?.info_placeholder : ''
                              }
                              autoSize={{ minRows: 5, maxRows: 15 }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={randumIcon} />
                <h1>기본 정보</h1>
              </div>

              <div className="flexColStart content">
                <div className="flexColCenter box third">
                  {selectedCategory !== 'CATE003' ? (
                    <div className="flexColStart info">
                      <h1>
                        판매 가격
                        <Tooltip
                          title={
                            <div>
                              <div>무료 플랜은 판매가격을 설정할 수 없으며, 무료 공유만 가능합니다. 무료로 공유하더라도 랭킹 점수는 획득할 수 있습니다.</div>
                              <Button style={{ marginTop: '8px' }} onClick={() => navigate(PROFILE_PAGE, { state: { mem_email: auth.mem_email, mem_key: auth.mem_key } })}>
                                플랜정보 확인하기
                              </Button>
                            </div>
                          }
                          placement="right"
                        >
                          <span style={{ cursor: 'pointer' }}>
                            <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      </h1>
                      <div className="flexRowBetween half">
                        <Form.Item name="sale_div" className="w-100">
                          <Select options={commonCode.saleDivList} fieldNames={{ label: 'cd_nm', value: 'cd' }} onChange={handleSaleDivChange} />
                        </Form.Item>
                        <Form.Item name="sale_amt">
                          <InputNumber
                            className="w-100"
                            disabled={selectedPay == 'FREE'}
                            min={selectedPay == 'FREE' ? 0 : 1000}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            controls={false}
                            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1>제목</h1>
                    <Form.Item name="prpt_title" className="w-100" rules={[{ required: true, message: '제목을 입력하세요' }]}>
                      <Input id="prpt_title" placeholder="제목을 입력하세요." />
                    </Form.Item>
                  </div>
                </div>
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1>소개</h1>
                    <Form.Item
                      name="prpt_desc"
                      className="w-100"
                      rules={selectedCategory !== 'CATE003' ? [{ required: true, message: '소개글을 입력하세요'}] : []}
                    >
                      <TextArea
                        placeholder={'잠재 구매자에게 어필할 수 있는 프롬프트 소개 및 홍보글을 작성하세요.'}
                        autoSize={{ minRows: 5, maxRows: 15 }}
                      />
                    </Form.Item>
                    {selectedCategory === 'CATE003' ? (
                      <>
                        <h1>가사</h1>
                        <Form.Item
                          name="prpt_lyrics"
                          className="w-100"
                        >
                          <TextArea
                            placeholder={'가사를 입력하세요.'}
                            autoSize={{ minRows: 5, maxRows: 15 }}
                          />
                        </Form.Item>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {selectedCategory !== 'CATE003' ? (
              <div className="flexRowBetween info-area">
                <div className="title flexRowStart">
                  <img src={randumIcon} />
                  <h1>세부 정보</h1>
                </div>

                <div className="flexColCenter content">
                  <div className="flexColCenter box">
                    <div className="flexColStart info">
                      <h1>프롬프트 활용 가이드</h1>
                      <Form.Item name="prpt_remark" className="w-100">
                        <TextArea placeholder={remarkPlaceholder} autoSize={{ minRows: 5, maxRows: 15 }} />
                      </Form.Item>
                    </div>
                  </div>
                  {selectedCategory !== 'CATE002' ? (
                    <div className="flexColCenter box">
                      <div className="flexColStart info">
                        <h1>프롬프트</h1>
                        <Form.Item name="test_prpt" className="w-100" rules={saveType == '01' ? [] : [{ required: true, message: '프롬프트를 입력하세요' }]}>
                          <TextArea placeholder="프롬프트를 입력하세요." autoSize={{ minRows: 5, maxRows: 15 }} />
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={randumIcon} />
                <h1>태그</h1>
              </div>

              <div className="flexColCenter content">
                <div className="flexColCenter box">
                  <div className="flexColStart info tagBox">
                    <h1>
                      <span className="blueSpan">
                        * 태그는 최대 10개까지 입력할 수 있으며, 등록한 태그는 마켓플레이스에서 검색 시 사용됩니다. (원하는 단어를 입력한 뒤에 쉼표 혹은 엔터키를 입력하시면 태그가 등록됩니다.)
                      </span>
                    </h1>
                    <div className="flexRowStart ">
                      <Input value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown} />
                      {tags.map((tag, index) => (
                        <div className="tag flexRowCenter" key={index}>
                          <p>#{tag}</p>
                          <img src={closeIcon} onClick={() => handleDelete(tag)} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={randumIcon} />
                <h1>AI 결과물 등록</h1>
              </div>

              <div className="flexColCenter content">
                <div className="flexColStart box image">
                  <ResultComponent cateType={selectedCategory} />
                </div>
              </div>
            </div>
            <div className="flexRowBetween secession">
              <div className="title flexRowCenter">
                <span className="redSpan">* 판매 승인 후에는 일부 항목은 수정이 불가능하니, 반드시 꼼꼼하게 확인하여 등록하시길 바랍니다.</span>
              </div>
            </div>
            {/* 저장 타입 전달용 ('01' 임시저장 / '10' 판매요청)  */}
            <Form.Item name="prpt_stat" hidden>
              <Input />
            </Form.Item>
            <div className="flexRowCenter btnBox" id="nothing">
              <button id="logoutBtn" type="button" onClick={handleInit}>
                취소
              </button>
              <button
                id="darkGrey"
                type='button'
                onClick={() => {
                  form.setFieldsValue({ prpt_stat: '01' });
                  setSaveType('01');
                  form.submit();
                }}
              >
                임시저장
              </button>
              <button
                id="purpleBtn"
                type='button'
                onClick={() => {
                  selectedCategory === 'CATE003' ? form.setFieldsValue({ prpt_stat: '30' }) : form.setFieldsValue({ prpt_stat: '10' })
                  setSaveType('10');
                  form.submit();
                }}
              >
                저장
              </button>
            </div>

            {/*판매자 등록 Modal*/}
            <SellerRegist visible={isSellerModalVisible} onClose={() => setIsSellerModalVisible(false)} />
          </Form>
        </div>
      </article>
      <SalesTerms visible={salesTermsVisible} onClose={onSalesTermsClose}/>
    </>
  );
};

export default SalesPrompt;
