import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Input, Layout, List, message, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from '@api/index';
import Table from '@components/Table';
import Loading from '@components/Loading';
import Banner from '@components/slider/Banner';
import { getLocalStorage, useDebounce } from '@utils/lib';
import { EyeOutlined, HeartFilled, HeartOutlined, MoreOutlined, CommentOutlined } from '@ant-design/icons';
import { boardSearch } from '@utils/constants';
import { BOARD_DETAIL, BOARD_EDIT } from '@routes/pathName';
import { setQuery, setBoardList, setIsLoading, setActiveSegKey, setBoardCheck, setThumbnail, toggleLikeStatus, setYScrollPosition, INIT_STATE_BOARD_LIST, setLikedStatus } from '@stores/board';
import qs from 'qs';
import useWidth from '@hooks/useWidth';

const BoardList = () => {
  const width = useWidth();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mobileSearch, setMobileSearch] = useState(false);
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  const boardListState = useSelector((s) => s.board, shallowEqual);
  const auth = useSelector((state) => state.auth);
  const memKey = getLocalStorage('prptbk-token', 'mem_key');
  const planCode = getLocalStorage('prptbk-token', 'plan_cd') || 'PLAN001';

  const boardGridRef = useRef();
  const boardGridProps = useMemo(
    () => ({
      editable: false,
      showRowCheckColumn: false,
      showRowNumColumn: false,
      showStateColumn: false,
      height: window.innerHeight - 300,
    }),
    [],
  );

  const getIntegratedBoardList = useCallback(
    async (queryParams) => {
      try {
        dispatch(setIsLoading(true));
        const { data } = await api.getIntegratedBoardList(queryParams);

        if (data?.items) {
          const userPlanLevel = parseInt(planCode.slice(-3));
          const filteredItems = data.items.filter((item) => parseInt(item.use_lv.slice(-3)) <= userPlanLevel);
          dispatch(setBoardCheck(filteredItems));
        } else {
          message.warning('게시판을 불러오는 데 실패했습니다.');
        }
      } catch (error) {
        message.warning(error.message);
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [dispatch],
  );

  const getBoardList = useCallback(
    async (query) => {
      try {
        dispatch(setIsLoading(true));
        const mergedQuery = { ...boardListState.query, ...query };

        // 현재 스크롤 위치 저장
        let currentScrollPosition;
        if (boardListState.activeSegKey === 'List') {
          currentScrollPosition = boardGridRef.current?.getRowPosition();
        } else {
          currentScrollPosition = cardContainerRef.current?.scrollTop;
        }

        if (JSON.stringify(boardListState.query) !== JSON.stringify(mergedQuery)) {
          dispatch(setQuery(mergedQuery));
        }
        const { data } = await api.getBoardList(mergedQuery);
        if (!data) return;

        // 첫번째 요청일때 (offset 0)
        if (!mergedQuery.offset || mergedQuery.offset === 0) {
          dispatch(
            setBoardList({
              items: data.items,
              totalCount: data.total,
              currentCount: data.items.length,
            }),
          );
          if (boardListState.activeSegKey === 'List' && boardGridRef.current) {
            boardGridRef.current.setGridData(data.items);
          }
        }
        // 추가 로드일 때
        else {
          let combinedItems;
          if (boardListState.activeSegKey === 'List' && boardGridRef.current) {
            const existingData = boardGridRef.current.getGridData() || [];
            const newItems = data.items.filter((newItem) => !existingData.some((existItem) => existItem.doc_no === newItem.doc_no));
            combinedItems = [...existingData, ...newItems];
            boardGridRef.current.setGridData(combinedItems);
          } else {
            const existingData = boardListState.items || [];
            const newItems = data.items.filter((newItem) => !existingData.some((existItem) => existItem.doc_no === newItem.doc_no));
            combinedItems = [...existingData, ...newItems];
          }

          dispatch(
            setBoardList({
              items: combinedItems,
              totalCount: data.total,
              currentCount: combinedItems.length,
            }),
          );

          // 스크롤 위치 복원
          setTimeout(() => {
            if (boardListState.activeSegKey === 'List' && boardGridRef.current && currentScrollPosition) {
              boardGridRef.current.setRowPosition(currentScrollPosition);
            } else if (cardContainerRef.current && currentScrollPosition) {
              cardContainerRef.current.scrollTop = currentScrollPosition;
            }
          }, 100);
        }

        // 썸네일 처리
        const thumbnails = data.items
          ?.filter((boardDetail) => boardDetail.thumnail)
          .map((boardDetail) => ({
            thumnail: boardDetail.thumnail,
            doc_no: boardDetail.doc_no,
          }));

        dispatch(setThumbnail([...boardListState.thumbnail, ...thumbnails.filter((newThumb) => !boardListState.thumbnail.some((existingThumb) => existingThumb.doc_no === newThumb.doc_no))]));

        if (boardListState.activeSegKey === 'List' && boardGridRef.current) {
          setupGridEvents({
            keyword_type: boardListState.query.keyword_type,
            keyword_text: query.keyword_text,
            offset: boardListState.query.offset,
            limit: query.limit,
            totalCount: data.total,
            currentCount: boardGridRef.current.getGridData().length,
          });
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching board list:', error);
          message.warning(error.message);
        }
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [dispatch, boardListState.query, boardListState.activeSegKey],
  );

  // 검색 조건 저장/로드 함수
  const saveSearchCondition = useCallback(
    (condition) => {
      if (!boardListState.query.board_no) return;
      const key = `boardSearchCondition_${boardListState.query.board_no}`;
      localStorage.setItem(key, JSON.stringify(condition));
    },
    [boardListState.query.board_no],
  );

  const loadSearchCondition = useCallback(() => {
    if (!boardListState.query.board_no) return { keyword_type: boardSearch[0].value };

    const key = `boardSearchCondition_${boardListState.query.board_no}`;
    const savedCondition = localStorage.getItem(key);

    if (savedCondition) {
      return JSON.parse(savedCondition);
    }

    return { keyword_type: boardSearch[0].value };
  }, [boardListState.query.board_no]);

  const handleRowClick = useCallback(
    (event) => {
      const item = event.item;
      if (item) {
        navigate(`${BOARD_DETAIL}`, {
          state: {
            board_no: item.board_no,
            doc_no: item.doc_no,
            board_title: item.title,
            write_lv: item.write_lv,
          },
        });
      }
    },
    [navigate],
  );

  const handleSearchBoard = useCallback(() => {
    form.validateFields().then((values) => {
      dispatch(setYScrollPosition(0));

      const newQuery = {
        ...boardListState.query,
        keyword_type: values.keyword_type,
        keyword_text: values.keyword_text || '',
        selector_2: '',
        offset: 0,
        limit: 20,
      };
      handleDebounce(() => getBoardList(newQuery));
    });
  }, [form, boardListState.query, getBoardList, handleDebounce, dispatch]);

  const getLikes = useCallback(async () => {
    try {
      if (memKey != null) {
        const like_mem_key = memKey;
        const { data } = await api.likeList({
          params: {
            target_id: Number('0'),
            like_mem_key,
          },
        });

        setLikedStatus(
          data.reduce((acc, like) => {
            acc[like.target_id] = true;
            return acc;
          }, {}),
        );
      }
    } catch (error) {
      message.error('좋아요를 불러오는데 실패했습니다.');
    }
  }, []);

  const handleLikeToggle = useCallback(
    async (docNo, lstId) => {
      try {
        await api.increaseLikeCount({
          target_id: docNo,
          like_mem_key: memKey,
          own_mem_key: lstId,
          like_div: 'B',
        });
        dispatch(toggleLikeStatus(docNo));
        getBoardList(boardListState.query);
      } catch (error) {
        message.error('좋아요 처리 중 오류 발생');
      }
    },
    [dispatch, memKey],
  );

  const handleScroll = ({ keyword_type, keyword_text, limit, totalCount }) => {
    // 현재 스크롤 위치 저장
    const scrollPosition = boardGridRef.current.getRowPosition();
    // 현재 데이터의 길이를 기준으로 다음 offset 계산
    const currentDataLength = boardGridRef.current.getGridData().length;

    const newQuery = {
      ...boardListState.query,
      keyword_type,
      keyword_text,
      offset: currentDataLength,
      limit: limit,
    };

    // 더 불러올 데이터가 있을 때만 요청
    if (currentDataLength < totalCount) {
      handleDebounce(() => {
        getBoardList(newQuery).then(() => {
          // 데이터 로드 후 스크롤 위치 복원
          setTimeout(() => {
            boardGridRef.current.setRowPosition(scrollPosition);
          }, 0);
        });
      });
    }
  };

  // 그리드 이벤트 설정
  const setupGridEvents = ({ keyword_type, keyword_text, limit, totalCount, currentCount }) => {
    boardGridRef.current.bind('vScrollChange', (event) => {
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤이 마지막에 도달하고 더 불러올 데이터가 있을 때
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ keyword_type, keyword_text, limit, totalCount });
        }
      }
    });
  };

  const boardColumnLayout = useMemo(
    () => [
      { dataField: 'board_no', headerText: '게시판 번호', width: '8%', visible: false },
      { dataField: 'doc_no', headerText: '글 번호', width: '8%' },
      {
        dataField: 'title',
        headerText: '제목',
        width: boardListState?.board_chk[0]?.write_lv !== "PLAN999" ? '48%' : '58%',
        editable: false,
        renderer: {
          type: 'TemplateRenderer',
        },
        labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
          const commentCount = item.comment_cnt || 0;
          const commentIconSvg = `
                 <svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="icon">
                    <path id="Vector" d="M5.5 5.5H12.5M5.5 9H10.75M14.25 1.125C14.9462 1.125 15.6139 1.40156 16.1062 1.89384C16.5984 2.38613 16.875 3.05381 16.875 3.75V10.75C16.875 11.4462 16.5984 12.1139 16.1062 12.6062C15.6139 13.0984 14.9462 13.375 14.25 13.375H9.875L5.5 16V13.375H3.75C3.05381 13.375 2.38613 13.0984 1.89384 12.6062C1.40156 12.1139 1.125 11.4462 1.125 10.75V3.75C1.125 3.05381 1.40156 2.38613 1.89384 1.89384C2.38613 1.40156 3.05381 1.125 3.75 1.125H14.25Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                 </svg> `;
          return `
                    <div class="group">
                        ${item.title}
                        ${
                          commentCount > 0
                            ? `
                            <div class="comment">
                                <span>
                                    ${commentIconSvg}
                                </span>
                                <span>${commentCount}</span>
                            </div>
                        `
                            : ''
                        }
                    </div>
                `;
        },
      },
      { dataField: 'mem_nick', headerText: '작성자', width: '10%', visible: boardListState?.board_chk[0]?.write_lv !== "PLAN999" },
      { dataField: 'lst_dt', headerText: '작성일', dataType: 'date', formatString: 'yyyy.mm.dd hh:mm:ss', width: '18%' },
      { dataField: 'like_cnt', headerText: '좋아요', width: '8%', dataType: 'numeric' },
      { dataField: 'view_cnt', headerText: '조회수', width: '8%', dataType: 'numeric' },
    ],
    [],
  );

  // List 컴포넌트
  const ListComponent = useCallback(
    () => <Table ref={boardGridRef} columnLayout={boardColumnLayout} customGridProps={boardGridProps} onRowClick={handleRowClick} />,
    [boardColumnLayout, boardGridProps, handleRowClick],
  );

  const cardContainerRef = useRef(null);

  // Card 컴포넌트
  const CardComponent = useCallback(() => {
    const handleCardScroll = () => {
      if (!cardContainerRef.current) return;

      const { scrollTop, clientHeight, scrollHeight } = cardContainerRef.current;
      // 스크롤이 하단에서 50px 이내로 왔을 때 추가 데이터 로드
      if (scrollHeight - scrollTop - clientHeight < 50) {
        const currentDataLength = boardListState.items.length;

        if (currentDataLength < boardListState.totalCount) {
          const newQuery = {
            ...boardListState.query,
            keyword_type: boardListState.query.keyword_type,
            keyword_text: boardListState.query.keyword_text || '',
            offset: currentDataLength,
            limit: 20,
          };

          handleDebounce(() => {
            dispatch(setIsLoading(true));
            getBoardList(newQuery).finally(() => {
              dispatch(setIsLoading(false));
            });
          });
        }
      }
    };

    useEffect(() => {
      const elements = document.querySelectorAll('.ant-card-meta-detail');
      elements.forEach((element) => {
        element.style.display = 'flex';
        element.style.justifyContent = 'space-between';
        element.style.alignItems = 'center';
      });
    }, []);

    useEffect(() => {
      const container = cardContainerRef.current;
      if (container) {
        container.addEventListener('scroll', handleCardScroll);
        return () => container.removeEventListener('scroll', handleCardScroll);
      }
    }, [boardListState.items.length, boardListState.totalCount, boardListState.query]);

    const { Meta } = Card;

    return (
      <div
        ref={cardContainerRef}
        style={{
          height: 'calc(100vh - 300px)',
          overflowY: 'auto',
          paddingTop: '20px',
        }}
      >
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={boardListState.items}
          renderItem={(item) => (
            <List.Item key={item.doc_no}>
              <Card
                onClick={() => {
                  window.$agRendererTemplate.handleTitleClick(item.board_no, item.doc_no, item.title);
                }}
                cover={
                  boardListState.thumbnail.find((th) => th.doc_no === item.doc_no) ? (
                    <img
                      src={boardListState.thumbnail.find((th) => th.doc_no === item.doc_no).thumnail}
                      alt="썸네일"
                      style={{
                        width: '100%',
                        height: '100%',
                        marginTop: '2%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <span
                      style={{
                        color: '#999',
                        fontSize: '14px',
                        width: '100%',
                        height: '105px',
                        marginTop: '2%',
                        marginLeft: '3%',
                        objectFit: 'cover',
                      }}
                    >
                      썸네일이 없습니다
                    </span>
                  )
                }
                style={{ height: '250px', cursor: 'pointer', overflow: 'hidden' }}
              >
                <Meta
                  style={{ marginTop: '25%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  title={item.title}
                  description={
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5%' }}>
                      <p>{boardListState?.board_chk[0].write_lv !== "PLAN999" ? item.mem_nick : null}</p>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          memKey != null ? handleLikeToggle(item.doc_no, item.lst_id) : message.warning('로그인 후 좋아요 체크가 가능합니다.');
                        }}
                        icon={boardListState.likedStatus[item.doc_no] ? <HeartFilled style={{ color: 'red' }} /> : <HeartOutlined style={{ color: 'red' }} />}
                        type="link"
                        style={{ marginLeft: '15px', marginRight: '-3px' }}
                      />
                      <p style={{ marginRight: '15px' }}>{item.like_cnt}</p>
                      <EyeOutlined style={{ marginRight: '6px' }} />
                      <p style={{ marginRight: '10px' }}>{item.view_cnt}</p>
                      <CommentOutlined style={{ marginRight: '5px' }} />
                      <p style={{ marginRight: '2px' }}>{item.comment_cnt}</p>
                      {/*<MoreOutlined style={{marginRight: '4px', marginLeft: '4px', cursor: 'pointer'}}/>*/}
                    </div>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      </div>
    );
  }, [boardListState.items, boardListState.thumbnail, boardListState.likedStatus, boardListState.totalCount, handleLikeToggle, getBoardList]);

  // 게시글 제목 클릭 핸들러
  if (!window.$agRendererTemplate) {
    window.$agRendererTemplate = {};
  }

  const handleWriteClick = useCallback(() => {
    const queryString = qs.stringify({
      board_no: boardListState.query.board_no,
      board_title: boardListState.query.board_title,
    });
    navigate(`${BOARD_EDIT}?${queryString}`);
  }, [navigate, boardListState.query.board_no, boardListState.query.board_title]);

  // 조회수 증가 함수 메모이제이션
  const handleViewCountIncrease = useCallback(async (board_no, doc_no) => {
    try {
      await api.increaseViewCount({ board_no, doc_no });
    } catch (error) {
      message.error('조회수 증가 실패: ' + error.message);
    }
  }, []);

  // 그리드 데이터 업데이트
  useEffect(() => {
    if (boardGridRef.current && boardListState.items?.length > 0 && !boardListState.isLoading) {
      boardGridRef.current.setGridData(boardListState.items);
    }
  }, [boardListState.items]);

  useEffect(() => {
    if (location.state) {
      const { board_no, board_title, dsp_meth } = location.state;
      dispatch(
        setQuery({
          board_no,
          board_title,
          dsp_meth,
          keyword_type: boardSearch[0].value,
          keyword_text: '',
          selector_2: '',
          like_mem_key: null,
          offset: 0,
          limit: 20,
        }),
      );
      dispatch(setActiveSegKey(dsp_meth === '1' ? 'List' : 'Card'));
    }
  }, [location.state, dispatch]);

  useEffect(() => {
    if (boardListState.query.board_no !== undefined && boardListState.query.offset !== undefined) {
      getIntegratedBoardList({ keyword_text: boardListState.query.board_title });
      getBoardList(boardListState.query);
    }
  }, [boardListState.query]);

  const handleTitleClick = useCallback(
    async (boardNo, docNo, boardTitle, rowIndex, event) => {
      await handleViewCountIncrease(boardNo, docNo);
      navigate(`${BOARD_DETAIL}`, {
        state: {
          board_no: boardNo,
          doc_no: docNo,
          board_title: boardTitle,
          attach_yn: boardListState.board_chk[0].attach_yn,
          reply_yn: boardListState.board_chk[0].reply_yn,
        },
      });
    },
    [navigate, boardListState.board_chk, handleViewCountIncrease],
  );

  useEffect(() => {
    if (!window.$agRendererTemplate) {
      window.$agRendererTemplate = {};
    }
    window.$agRendererTemplate.handleTitleClick = handleTitleClick;

    if (boardListState.activeSegKey === 'List' && boardGridRef.current) {
      boardGridRef.current.bind('cellClick', (event) => {
        const gridData = boardGridRef.current.getGridDataWithState('state');
        const selectedRowData = gridData[event.rowIndex];
        handleTitleClick(selectedRowData.board_no, selectedRowData.doc_no, selectedRowData.title);
      });
    }
    return () => {
      if (boardGridRef.current) {
        boardGridRef.current.unbind('cellClick');
      }
    };
  }, [boardListState.activeSegKey, handleTitleClick]);

  // 컴포넌트 언마운트 시 검색 조건 저장
  useEffect(() => {
    return () => {
      saveSearchCondition(boardListState.query);
    };
  }, [boardListState.query, saveSearchCondition]);

  // 좋아요 상태는 보드 리스트가 업데이트될 때만 가져오도록 수정
  useEffect(() => {
    getLikes();
  }, [getLikes, boardListState.items]);

  useEffect(() => {
    if (boardListState?.query?.keyword_type) {
      form.setFieldsValue({ keyword_type: boardListState.query.keyword_type });
    }
  }, [boardListState?.query?.keyword_type, form]);

  const renderSearch = () => {
    return (
      <>
        <Form form={form} onFinish={handleSearchBoard}>
          <Form.Item name="keyword_type" className="select" initialValue={boardListState.query.keyword_type}>
            <Select options={boardSearch} />
          </Form.Item>
          <Form.Item name="keyword_text" className="inputText">
            <Input placeholder="게시판명을 입력해주세요." />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            검색
          </Button>
          {width < 700 && (
            <>
              <Button className="icon delete" htmlType="close" onClick={() => setMobileSearch(false)}>
                삭제
              </Button>{' '}
            </>
          )}
          {/*버튼 클릭시, search 영역 display:none*/}
          <Form.Item name="selector_2" style={{ display: 'none' }}>
            <Select options={[{ value: '', label: '전체' }]} />
          </Form.Item>
        </Form>
      </>
    );
  };

  return (
    <>
      <article className="flexColCenter" id="infoWrapper">
        <Banner bannerCd="BANNER001" bannerTp="Top" />
        {/* <Banner bannerCd="BANNER016" bannerTp="Top" /> */}
        <div className="flexRowCenter title">
          <div className="titleBox flexRowBetween">{boardListState.currentBoardTitle || '게시판 제목이 없습니다.'}</div>
          {/* 검색 */}
          {width > 700 && <div className="searchBox">{renderSearch()}</div>}
        </div>
        <div className="boardWrapper rsWrapper flexRowStart">
          <Loading isLoading={boardListState.isLoading} />
          <Layout>
            <div className="searchBox flexRowBetween">
              <span className="result">
                <div className="searchCount">
                  검색결과{' '}
                  <span>
                    <span>총 {boardListState.totalCount}</span> 개
                  </span>
                </div>
              </span>
              <span className="btn">
                <button className="btn-search" onClick={() => setMobileSearch(true)}></button>
                {mobileSearch && (
                  <div className="search" style={{ display: 'block' }}>
                    {width < 700 && renderSearch()}
                  </div>
                )}
                {(boardListState.board_chk && parseInt(boardListState.board_chk[0]?.write_lv.slice(-3)) <= parseInt(planCode?.slice(-3))) || auth?.mem_auth === 'R002' ? (
                  <Button className="btn-dark" onClick={handleWriteClick}>
                    작성
                  </Button>
                ) : null}
              </span>
            </div>
            <Content>{boardListState.activeSegKey === 'List' ? <ListComponent /> : <CardComponent />}</Content>
          </Layout>
        </div>
        <Banner bannerCd="BANNER001" bannerTp="Bottom" />
        {/* <Banner bannerCd="BANNER017" bannerTp="Bottom" /> */}
      </article>
    </>
  );
};

export default React.memo(BoardList);
