import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Button, Form, Input, Layout, message, Select } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { boardMySearch, boardSort, boardTotalSearch } from '@utils/constants';
import { useDebounce } from '@utils/lib';
import qs from 'qs';
import { BOARD_DETAIL } from '@routes/pathName';
import Table from '@components/Table';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorage } from '@utils/lib';
import useWidth from "@hooks/useWidth";

const TotalBoardList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const width = useWidth();
  const { search } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { board_no: queryBoardNo, board_title: queryBoardTitle } = location.state || {};
  const [form] = Form.useForm();
  const boardGridRef = useRef();
  const abortControllerRef = useRef(null);
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );
  const [boardPost, setBoardPost] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);

  const memKey = getLocalStorage('prptbk-token', 'mem_key');
  const planCode = getLocalStorage('prptbk-token', 'plan_cd');

  const initialQuery = useMemo(
    () => ({
      keyword_type: '',
      selector_1: '',
      selector_2: '',
      selector_3: '',
      keyword_text: '',
      board_no: '',
      like_mem_key: memKey,
      offset: 0,
      limit: 20,
    }),
    [],
  );

  const [boardListState, setBoardListState] = useState({
    query: initialQuery,
    items: [],
    totalCount: 0,
    currentCount: 0,
  });

  const boardGridProps = useMemo(
    () => ({
      editable: false,
      showRowCheckColumn: false,
      showRowNumColumn: true,
      showStateColumn: false,
      usePaging: true,
      showPageButtonCount: 5,
      showPageRowSelect: true,
      pageRowCount: 20,
      pageRowSelectValues: [20, 50, 100, 200],
      height: 660,
    }),
    [],
  );

  const handleViewCountIncrease = useCallback(async (board_no, doc_no) => {
    try {
      await api.increaseViewCount({ board_no, doc_no });
    } catch (error) {
      message.error('조회수 증가 실패: ' + error.message);
    }
  }, []);

  const boardColumnLayout = useMemo(
    () => [
      { dataField: 'board_no', headerText: '게시판 번호', width: '8%', visible: false },
      { dataField: 'doc_no', headerText: '글 번호', width: '8%', visible: false },
      { dataField: 'board_cate', headerText: '구분', width: '8%' },
      {
        dataField: 'title',
        headerText: '제목',
        width: '57.8%',
        editable: false,
        renderer: {
          type: 'TemplateRenderer',
        },
        labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
          const commentCount = item.comment_cnt || 0;
          const commentIconSvg = `
                    <svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="icon">
<path id="Vector" d="M5.5 5.5H12.5M5.5 9H10.75M14.25 1.125C14.9462 1.125 15.6139 1.40156 16.1062 1.89384C16.5984 2.38613 16.875 3.05381 16.875 3.75V10.75C16.875 11.4462 16.5984 12.1139 16.1062 12.6062C15.6139 13.0984 14.9462 13.375 14.25 13.375H9.875L5.5 16V13.375H3.75C3.05381 13.375 2.38613 13.0984 1.89384 12.6062C1.40156 12.1139 1.125 11.4462 1.125 10.75V3.75C1.125 3.05381 1.40156 2.38613 1.89384 1.89384C2.38613 1.40156 3.05381 1.125 3.75 1.125H14.25Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
`;
          let template = '<div style="display: flex; align-items: center;">';
          template += `${item.title}`;
          if (commentCount > 0) {
            template += `<span>${commentIconSvg}</span>`;
            template += `<span>${commentCount}</span>`;
          }
          template += '</div>';
          return template;
        },
      },
      { dataField: 'lst_dt', headerText: '작성일', dataType: 'date', formatString: 'yyyy.mm.dd', width: '18%' },
      {
        dataField: 'like_cnt',
        headerText: '좋아요',
        width: '8%',
        dataType: 'numeric',
        renderer: {
          type: 'TemplateRenderer',
        },
        labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
          const isLiked = item.like_yn === 'Y';
          return `
            <div style="display: flex; align-items: center;">
                <button 
                    class="like-button"
                    style="border: none; background: none; cursor: pointer; margin-left: 8px; color: red;"
                    onclick="javascript:(function(event) { 
                        event.stopPropagation(); 
                        $agRendererTemplate.handleLikeClick(${item.doc_no}, '${item.lst_id}', ${rowIndex}, event);
                    })(window.event);"
                >
                    ${
                      isLiked
                        ? '<svg width="16" height="16" viewBox="0 0 24 24" fill="red"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>'
                        : '<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="red"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>'
                    }
                </button>
                <span>${value}</span>
            </div>
        `;
        },
      },
      { dataField: 'view_cnt', headerText: '조회수', width: '8%', dataType: 'numeric' },
    ],
    [],
  );

  const saveSearchCondition = useCallback((condition) => {
    localStorage.setItem('boardSearchCondition', JSON.stringify(condition));
  }, []);

  const loadSearchCondition = useCallback(() => {
    const savedCondition = localStorage.getItem('boardSearchCondition');
    return savedCondition ? JSON.parse(savedCondition) : null;
  }, []);

  const getIntegratedBoardList = useCallback(async () => {
    try {
      const { data } = await api.getIntegratedBoardList();
      if (data?.items) {
        const userPlanLevel = parseInt(planCode.slice(-3));
        const filteredItems = data.items
          .filter((item) => parseInt(item.use_lv.slice(-3)) <= userPlanLevel)
          .map((item) => ({
            label: item.board_title,
            value: item.board_no,
          }));
        const updatedItems = [{ label: '카테고리 전체', value: '' }, ...filteredItems];

        setBoardPost(updatedItems);
        return { success: true, boardPost: updatedItems };
      }
      message.warning('데이터를 불러오는 데 실패했습니다.');
      return { success: false, boardPost: [] };
    } catch (error) {
      message.warning(error.message);
      return { success: false, boardPost: [] };
    }
  }, [planCode]);

  const getBoardList = useCallback(async (query, currentBoardPost) => {
    if (!currentBoardPost || currentBoardPost.length === 0) {
      console.log('Waiting for board post data...');
      return;
    }

    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      setIsLoading(true);
      const { data } = await api.getBoardList(query, {
        signal: abortControllerRef.current.signal,
      });

      if (!data) return;
      const boardNo = currentBoardPost.map((item) => item.value);
      const filteredItems = data.items.filter((item) => boardNo.includes(item.board_no));

      setBoardListState((prev) => ({
        ...prev,
        items: filteredItems,
        totalCount: filteredItems.length,
        currentCount: data.items.length,
      }));
    } catch (error) {
      if (error.name !== 'AbortError') {
        message.warning(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSearchBoard = useCallback(
    ({ keyword_type, selector_1, selector_2, selector_3, keyword_text }) => {
      if (!isInitialized) {
        console.log('Waiting for initialization...');
        return;
      }
      if (selector_2 && selector_2 !== '') {
        form.setFieldsValue({
          selector_1: '',
          keyword_type: '',
          keyword_text: '',
          like_mem_key: memKey,
          selector_3: '1',
        });
        setIsFieldsDisabled(true);
      } else {
        setIsFieldsDisabled(false);
      }

      const newQuery = {
        ...boardListState.query,
        keyword_type: keyword_type || '',
        selector_1: selector_1 || '',
        selector_2: selector_2 || '',
        selector_3: selector_3 || '1',
        keyword_text,
        like_mem_key: memKey,
        offset: 0,
      };

      setBoardListState((prev) => ({ ...prev, query: newQuery }));
      saveSearchCondition(newQuery);
      handleDebounce(() => getBoardList(newQuery, boardPost));
    },
    [boardListState.query, handleDebounce, getBoardList, saveSearchCondition, boardPost, isInitialized, form, memKey],
  );

  if (!window.$agRendererTemplate) {
    window.$agRendererTemplate = {};
  }
  window.$agRendererTemplate.handleTitleClick = async (boardNo, docNo, title, rowIndex, event) => {
    await handleViewCountIncrease(boardNo, docNo);
    navigate(`${BOARD_DETAIL}`, {
      state: {
        board_no: boardNo,
        doc_no: docNo,
        board_title: queryBoardTitle,
      },
    });
  };

  if (!window.$agRendererTemplate) {
    window.$agRendererTemplate = {};
  }
  window.$agRendererTemplate.handleLikeClick = async (docNo, lstId, rowIndex, event) => {
    // event.preventDefault();
    await handleToggleLike(docNo, lstId);
    await getBoardList(boardListState.query);
  };

  useEffect(() => {
    boardGridRef.current.bind('cellClick', (event) => {
      const gridData = boardGridRef.current.getGridDataWithState('state');
      const selectedRowData = gridData[event.rowIndex];
      $agRendererTemplate.handleTitleClick(selectedRowData.board_no, selectedRowData.doc_no, selectedRowData.title);
    });
  }, []);

  const [likedStatus, setLikedStatus] = useState({});

  const handleToggleLike = useCallback(
    async (docNo, lstId) => {
      const like_mem_key = memKey;
      const data = {
        target_id: docNo,
        like_mem_key,
        own_mem_key: lstId,
        like_div: 'B',
      };
      try {
        await api.increaseLikeCount(data);
        setLikedStatus((prev) => ({
          ...prev,
          [docNo]: !prev[docNo],
        }));

        message.success(likedStatus[docNo] ? '좋아요가 취소되었습니다.' : '좋아요를 눌렀습니다.');

        const updatedQuery = { ...boardListState.query };
        await getBoardList(updatedQuery, boardPost);
      } catch (error) {
        message.error('좋아요 처리 중 오류가 발생했습니다.');
      }
    },
    [getBoardList, boardListState.query],
  );

  useEffect(() => {
    let isMounted = true;

    const initializeData = async () => {
      try {
        setIsLoading(true);
        const { success, boardPost: newBoardPost } = await getIntegratedBoardList();

        if (!isMounted) return;
        if (!success) {
          message.error('게시판 목록을 불러오는데 실패했습니다.');
          return;
        }

        const savedSearchCondition = loadSearchCondition();
        const newQuery = {
          ...initialQuery,
          ...savedSearchCondition,
          board_no: '',
          like_mem_key: memKey,
        };

        setBoardListState((prev) => ({ ...prev, query: newQuery }));

        if (newBoardPost && newBoardPost.length > 0) {
          await getBoardList(newQuery, newBoardPost);
        }

        if (!isMounted) return;

        form.setFieldsValue({
          keyword_type: newQuery.keyword_type || '',
          selector_1: newQuery.selector_1 || '',
          selector_2: newQuery.selector_2 || '',
          selector_3: newQuery.selector_3 || '1',
          keyword_text: newQuery.keyword_text,
        });

        if (queryBoardTitle) {
          localStorage.setItem('board_title', queryBoardTitle);
        }

        setIsInitialized(true);
      } catch (error) {
        if (isMounted) {
          console.error('Failed to initialize data:', error);
          message.error('데이터를 불러오는데 실패했습니다.');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    initializeData();
    return () => {
      isMounted = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [queryBoardNo, queryBoardTitle, form, initialQuery, getIntegratedBoardList, getBoardList, memKey, loadSearchCondition]);

  useEffect(() => {
    if (boardGridRef.current) {
      boardGridRef.current.setGridData(boardListState.items);
    }
  }, [boardListState.items]);

  useEffect(() => {
    return () => {
      saveSearchCondition(boardListState.query);
    };
  }, [boardListState.query, saveSearchCondition]);

  const renderSearch = () => {
    return (
        <Form form={form} layout="inline" onFinish={handleSearchBoard}>
          <Form.Item name="selector_2" initialValue={undefined}>
            <Select
                style={{ minWidth: '120px' }}
                options={boardMySearch}
                value={form.getFieldValue('selector_2')}
                onChange={(value) => {
                  if (value) {
                    handleSearchBoard({
                      ...form.getFieldsValue(),
                      selector_2: value,
                      like_mem_key: memKey,
                    });
                  } else {
                    handleSearchBoard({ ...form.getFieldsValue() });
                  }
                }}
            />
          </Form.Item>
          <Form.Item name="selector_1">
            <Select style={{ minWidth: '120px' }} options={boardPost} disabled={isFieldsDisabled} />
          </Form.Item>
          <Form.Item name="keyword_type">
            <Select style={{ minWidth: '120px' }} options={boardTotalSearch} disabled={isFieldsDisabled} />
          </Form.Item>
          <Form.Item name="keyword_text">
            <Input placeholder="검색어를 입력해주세요." style={{ minWidth: '300px' }} disabled={isFieldsDisabled} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            검색
          </Button>
          <Form.Item name="selector_3">
            <Select
                style={{ minWidth: '100px' }}
                options={boardSort}
                value={form.getFieldValue('selector_3')}
                onChange={(value) => {
                  if (value) {
                    handleSearchBoard({
                      ...form.getFieldsValue(),
                      selector_3: value,
                    });
                    setTimeout(() => {
                      form.setFieldsValue({ keyword_text: '' });
                    }, 10);
                  }
                }}
            />
            {width < 700 && (
                <>
                  <Button className="icon delete" htmlType="close" onClick={() => setMobileSearch(false)}>
                    삭제
                  </Button>{' '}
                </>
            )}
            {/*버튼 클릭시, search 영역 display:none*/}
            <Form.Item name="selector_2" style={{ display: 'none' }}>
              <Select options={[{ value: '', label: '전체' }]} />
            </Form.Item>
          </Form.Item>
        </Form>
    )}

  return (
    <>
      <article className="flexColCenter" id="infoWrapper">
        <div className="flexRowCenter title">
          <div className="titleBox flexRowBetween">통합검색</div>
          {/* 검색 */}
          {width > 700 && <div className="searchBox">{renderSearch()}</div>}
        </div>
        <div className="boardWrapper rsWrapper flexRowStart">
          <Loading isLoading={isLoading} />
          <Layout>
            <div className="searchBox flexRowBetween">
                <span className="result">
                  <div className="searchCount">
                    검색결과{' '}
                    <span>
                      <span>총 {boardListState.totalCount}</span> 개
                    </span>
                  </div>
                </span>
                <span className="btn">
                  <button className="btn-search" onClick={() => setMobileSearch(true)}></button>
                  {mobileSearch && (
                      <div className="search" style={{ display: 'block' }}>
                        {width < 700 && renderSearch()}
                      </div>
                  )}
                </span>
            </div>
            <Content style={{ marginTop: '15px' }}>
              <Table ref={boardGridRef} columnLayout={boardColumnLayout} customGridProps={boardGridProps} />
            </Content>
          </Layout>
        </div>
      </article>
    </>
  );
};

export default React.memo(TotalBoardList);
