import React, {useCallback, useEffect, useState} from 'react';
import { Modal, Select, Input, Checkbox, Form, message, Button } from 'antd';
import PasswordCheck from "@components/slider/PasswordCheck";
import * as api from "@api/index";
import {getLocalStorage, makeSelectBoxList} from "@utils/lib";
import {CloseOutlined} from "@ant-design/icons";

const ComplaintModal = ({ isOpen, onClose, params }) => {
    const memKey = getLocalStorage('prptbk-token', 'mem_key');
    const [badRsnList, setBadRsnList] = useState([]); // 신고하기 공통코드
    const [form] = Form.useForm();
    const [reportContent, setReportContent] = useState({
        bad_cd: '', // 신고 코드
        contents: '', // 상세내용
    });

    const fetchCommonCode = useCallback(async () => {
        try {
            const response  = await api.fetchCommonDetailCode({ grp_cd: 'BAD_RSN' });
            setBadRsnList(makeSelectBoxList(response?.data, 'cd_desc', 'cd'));
        } catch (error) {
            message.warning('공통코드 조회 중 오류가 발생했습니다.');
        }
    }, []);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const query = {
                target_id: params.target_id,
                bad_mem_key: memKey,             // 신고자
                own_mem_key: params.own_mem_key, // 신고대상
                bad_div: params.bad_div,
                bad_cd: values.bad_cd,
                contents: values.contents,
            };
            // console.log('최종 제출 데이터:', query);

            await api.addPromptBad(query);
            message.success('해당 회원의 신고 요청이 처리되었습니다.');
            onClose();
            form.resetFields();
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    useEffect(() => {
        fetchCommonCode();
    },[]);

    useEffect(() => {
        form.resetFields(['bad_cd', 'contents']);
    },[isOpen]);


    return (
        <Modal
            title="신고하기"
            open={isOpen}
            onCancel={onClose}
            footer={[
                <div className="flexRowEnd">
                    <Button id="greyBtn" onClick={handleSubmit} style={{ color:"black", border: "1px solid #ccc" }}>
                        신고하기
                    </Button>
                </div>
            ]}
        >
            <aticle id="reviewModal" className="flexColCenter">
                <Form noStyle form={form} name="badRsn" layout="vertical" autoComplete="off" scrollToFirstError style={{ width: '100%' }}>
                    <Form.Item name="bad_cd" label="신고 사유 선택" rules={[{ required: true, message: '신고 사유를 선택해주세요.' }]}>
                        <Select
                            placeholder="사유 선택"
                            options={badRsnList}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item name="contents" label="신고 사유 상세">
                        <Input.TextArea rows={6} showCount maxLength={100} onChange={(e) => setReportContent({ ...reportContent, contents: e.target.value })} />
                    </Form.Item>
                </Form>*
            </aticle>
        </Modal>
    );
};

export default ComplaintModal;