import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import * as api from '@api/index';
import {encryptRSA, getLocalStorage} from "@utils/lib";

const PasswordCheck = ({ isOpen, onClose, onAuthenticate }) => {
    const [password, setPassword] = useState('');
    const memEmail = getLocalStorage('prptbk-token', 'mem_email');

    useEffect(() => {
        setPassword('');
    },[isOpen]);

    const handlePwChk = async () => {
        try {
            const { data } = await api.login({
                mem_email: memEmail,
                mem_pwd: encryptRSA(password)
            });
            if (!data) return;
            else if (data.returnStatus === "fail") message.warning(data.returnMessage);
            else if (['02', '91'].includes(data.mem_stat)) {
                message.success('인증 성공');
                onAuthenticate(true);
            } else {
                message.warning('인증 실패');
            }
        } catch (error) {
            message.error('오류가 발생했습니다.');
        }
    };

    const handleKeyPress  = async (e) => {
        if (e.key === 'Enter') {
            handlePwChk()
        }
    };

    return(
        <Modal
            title="비밀번호 확인"
            open={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    취소
                </Button>,
                <Button key="submit" type="primary" onClick={handlePwChk}>
                    확인
                </Button>,
            ]}
            style={{ marginTop: '15%' }}
        >
            <Input.Password
                placeholder="비밀번호를 입력하세요"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => handleKeyPress(e)}
            />
        </Modal>
    )
};

export default PasswordCheck;