import React, { useState, useCallback, useEffect } from 'react';
import { message, Skeleton } from 'antd';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
// component
import NewSlider from '@components/slider/NewSlider';
import CardCircleMusic from '@pages/main/CardCircleMusic';
import DetailMusic from '@pages/main/DetailMusic';
// lib
import { useDebounce, validateSession, getLocalStorage } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// api
import * as api from '@api/index';
// hook
import useWidth from '@hooks/useWidth';

const ListTop10Music = ({ openMusicPlayer }) => {
  const [dateList, setDateList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMusic, setSelectedMusic] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const width = useWidth();
  const isMobile = width < 700;

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 날짜 필터링 리스트 가져오기
  const makeDateList = async () => {
    try {
      const madeSelectBoxList = [
        { label: '주간', value: 'weekly' },
        { label: '월간', value: 'monthly' },
      ];
      setDateList(madeSelectBoxList);
      setSelectedDate(madeSelectBoxList[0]?.value);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // TOP 10 음악 리스트 가져오기
  const listMusic = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE003',
        keyword_tab: selectedDate,
        filter_stat: '30',
        offset: 0,
        limit: 10,
      });
      if (!data) return;
      setMusicList(data.items);
      setSelectedMusic({ ...data.items[0], rnk: 1 });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedDate]);

  // 음악 플레이어 열기
  const onOpenMusicPlayer = async (musics = []) => {
    try {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        // 플레이리스트에 곡 추가
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
            prpt_title: music?.prpt_title,
            prpt_path: music?.music_org_link,
          };
        });
        await api.addPlaylistMusic(requestMusics);
        openMusicPlayer();
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    if (!selectedDate) handleDebounce(() => makeDateList());
    if (selectedDate) handleDebounce(() => listMusic());
  }, [selectedDate]);

  return (
    <article className="flexColCenter">
      <div className="rsWrapper flexColCenter" id="aiWrapper">
        <h1>
          <span>기술을 넘어, 당신의 감성을 담은 AI 뮤직</span>
        </h1>

        <div className="titleBox flexRowBetween">
          <div className="flexRowCenter title">
            <img src={musicIcon3} alt="music_icon" />
            <p>TOP 10</p>
          </div>

          <div className="flexRowCenter">
            <div className="tabBox flexRowCenter">
              {dateList?.map((date) => (
                <div key={date?.value} className="tab flexColCenter" id={selectedDate === date?.value ? 'active' : ''} onClick={() => setSelectedDate(date?.value)}>
                  {date?.label}
                </div>
              ))}
            </div>
          </div>

          <div id="whiteBtn" className="web noMore"></div>
        </div>

        <div className="flexColCenter slider" id="top10">
          <div className="slider-container" id="newSlider">
            {isLoading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <NewSlider
                config={{
                  slidesToShow: 9,
                  focusOnSelect: true,
                  responsive: [
                    {
                      breakpoint: 1100,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                  ],
                  afterChange: (index) => {
                    setSelectedMusic({ ...musicList[index], rnk: index + 1 });
                  },
                }}
                Content={musicList?.map((music) => (
                  <CardCircleMusic
                    data={music}
                    isActive={music?.prpt_id === selectedMusic?.prpt_id}
                    isActiveTitle={false}
                    isActiveDescription={false}
                    openMusicPlayer={onOpenMusicPlayer}
                    layoutStyle={!isMobile && { transform: 'scale(1.3)' }}
                    activeImgStyle={!isMobile && { width: '42px', height: '42px' }}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
      <DetailMusic selectedMusic={selectedMusic} />
    </article>
  );
};

export default ListTop10Music;
