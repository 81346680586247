import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { message } from 'antd';
import qs from 'qs';
import * as api from '@api/index';
import { setAuth, clearAuth, setFollowingList, setFollowerList } from '@stores/auth';
import * as PATH from '@routes/pathName';
import { isAdmin } from '@utils/lib';

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const INIT_STATE = {
  provider: '',
  code: '',
};

const SocialLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useMemo(() => ({ ...INIT_STATE, ...qs.parse(search?.substring(1) || '') }), [search]);

  useEffect(() => {
    const login = async () => {
      try {
        const { data } = await api.socialLogin(params);
        if (!data) navigate(PATH.LOGIN);
        // '1.가입대기' 상태이므로, 회원가입 화면으로 이동
        else if (data.mem_stat === '01') navigate(`${PATH.SIGNUP}?mem_email=${data.mem_email}&mem_key=${data.mem_key}&verified=${true}`);
        // '2.활성화' 또는 '3.탈퇴신청' 가입이 완료된 상태이므로, 로그인 처리 후, 메인 화면으로 이동
        else if (['02', '91'].includes(data.mem_stat)) {
          // 팔로잉 리스트 가져오기
          const { data: responseListFollowing } = await api.listFollower({ flw_mem_key: data.mem_key });
          const followingList = responseListFollowing.items.map((followingItem) => {
            return {
              mem_key: followingItem.mem_key,
              mem_email: followingItem.mem_email,
            };
          });
          // 팔로우 리스트 가져오기
          const { data: responseListFollower } = await api.listFollower({ mem_key: data.mem_key });
          const followerList = responseListFollower.items.map((followerItem) => {
            return {
              mem_key: followerItem.flw_mem_key,
              mem_email: followerItem.flw_mem_email,
            };
          });
          // 세션 정보 저장
          await Promise.all([
            localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify({ ...data, followingList, followerList })),
            dispatch(setAuth(data)),
            dispatch(setFollowingList(followingList)),
            dispatch(setFollowerList(followerList)),
          ]);
          /* if (isAdmin(data.mem_auth)) navigate(PATH.ADMIN_USER_LIST, { replace: true });
          else */
          navigate(PATH.MAIN, { replace: true });
        }
      } catch (error) {
        message.warning(error.message);
        // 세션 정보 지우기
        localStorage.setItem(TOKEN_STORE_KEY, '{}');
        dispatch(clearAuth());
        navigate(PATH.LOGIN);
      }
    };
    if (params.provider && params.code) login();
  }, [params, params.provider, params.code]);

  return <div>로딩중..</div>;
};

export default SocialLogin;
